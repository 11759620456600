import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvertisersComponent } from './advertisers/advertisers.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { TemporaryAuthGuard } from './auth/guard/temporary-auth.guard';
import { LoginOtpAuthComponent } from './auth/login-otp-auth/login-otp-auth.component';
import { LoginComponent } from './auth/login/login.component';
import { PasswordRegistrationCompletedComponent } from './auth/password-registration-completed/password-registration-completed.component';
import { PasswordRegistrationComponent } from './auth/password-registration/password-registration.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { FlightsComponent } from './flights/flights.component';
import { HomeComponent } from './home/home.component';
import { ReportsEditComponent } from './reports/reports-edit/reports-edit.component';
import { ReportsResultComponent } from './reports/reports-result/reports-result.component';
import { FeatureGroup } from './resource/feature-role';
import { UserListComponent } from './user-setting/user-list/user-list.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'login', component: LoginComponent, data: { noMenu: true } },
  { path: 'login_otp_auth', component: LoginOtpAuthComponent, data: { noMenu: true }  },
  { path: 'password_new', component: PasswordResetComponent, data: { noMenu: true }  },
  { path: 'password_reset', component: PasswordResetComponent, data: { noMenu: true }  },
  { path: 'password_reset_auth', component: LoginOtpAuthComponent, data: { noMenu: true }  },
  { path: 'password_registration', component: PasswordRegistrationComponent, data: { noMenu: true, title: FeatureGroup.Users }, canActivate: [TemporaryAuthGuard] },
  { path: 'password_registration_completed', component: PasswordRegistrationCompletedComponent, data: { noMenu: true, title: FeatureGroup.Users }, canActivate: [TemporaryAuthGuard] },
  { path: 'user_setting/list', component: UserListComponent, data: { title: FeatureGroup.Users }, canActivate: [AuthGuard] },
  { path: 'reports/edit', component: ReportsEditComponent, data: { title: FeatureGroup.Reports }, canActivate: [AuthGuard] },
  { path: 'reports/result', component: ReportsResultComponent, data: { title: FeatureGroup.Reports }, canActivate: [AuthGuard] },
  { path: 'advertisers', component: AdvertisersComponent, data: { title: FeatureGroup.Advertisers }, canActivate: [AuthGuard]  },
  { path: 'campaigns', component: CampaignsComponent, data: { title: FeatureGroup.Campaigns }, canActivate: [AuthGuard]  },
  { path: 'flights', component: FlightsComponent, data: { title: FeatureGroup.Flights }, canActivate: [AuthGuard]  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
