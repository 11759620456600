import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/users/user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserProfileService } from 'src/app/core/user-profile.service';
import {
  AdvertiserObjectType,
  PasswordRemindInputObjectType,
  UserInputObjectType,
  UserRole,
} from 'src/app/models/graphql/types';
import {
  excerptErrorMessage,
  getDecodeId,
  REGEX_EMAIL,
} from 'src/app/resource/utility/common-util';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AdvertiserService } from 'src/app/services/advertisers/advertiser.service';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';

/* Screen transition parameter */
export interface UserEditParam {
  id: string | null;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  /** Title */
  public userEditFormTitle = 'New User';
  public displayUserId: string | undefined;

  /** Advertisers drop down config */
  public advertiserList: Pick<AdvertiserObjectType, 'id' | 'name'>[] = [];
  public selectedAdvertisers: Pick<AdvertiserObjectType, 'id' | 'name'>[] = [];
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };

  /** error messages */
  public errors: string[] = [];

  /** Role info array */
  public roleItems: UserRole[] = [UserRole.Admin, UserRole.Editor, UserRole.Staff];

  /** Status Types */
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /** FormFilterGroup */
  public userEditFormGroup = this._formBuilder.group({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(REGEX_EMAIL),
    ]),
    role: new UntypedFormControl('', [Validators.required]),
    status: new UntypedFormControl(null, [Validators.required]),
    agentLoggedIn: new UntypedFormControl(false),
  });

  public isSubmitting = false;

  /**
   * Constructor
   * @param userEditParam
   * @param userService
   * @param advertiserService
   * @param _formBuilder
   * @param _snackBar
   * @param _dialogRef
   * @param spinnerService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public userEditParam: UserEditParam,
    private userService: UserService,
    private advertiserService: AdvertiserService,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<UserEditComponent>,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService
  ) {
    this.getAdvertisers();
    // New
    if (this.userEditParam.id === null) {
      this.userEditFormTitle = 'New User';
      this.userEditFormGroup.controls['status'].setValue(true);
      this.userEditFormGroup.controls['status'].disable();
      this.spinnerService.hide();
    } else {
      // edit
      this.userEditFormTitle = 'Edit User';
      this.displayUserId = getDecodeId(this.userEditParam.id);
      this.getUser(this.userEditParam.id);
    }
  }

  ngOnInit(): void {
    return;
  }

  /**
   * Get user info.
   * @param userId
   */
  private async getUser(userId: string) {
    this.spinnerService.show();
    await firstValueFrom(this.userService.getUser(userId)).then(
      (res) => {
        const userInfo = res.data.user;
        if (userInfo) {
          this.userEditFormGroup.patchValue({
            name: userInfo.name,
            email: userInfo.email,
            role: userInfo.role,
            status: userInfo.isActive,
            agentLoggedIn: userInfo.agentLoggedIn,
          });
          const adList: Pick<AdvertiserObjectType, 'id' | 'name'>[] = [];
          if (userInfo.advertisers) {
            userInfo.advertisers.edges.forEach((ad) => {
              if (ad && ad.node) {
                adList.push({ id: ad.node.id, name: ad.node.name });
              }
            });
          }
          this.selectedAdvertisers = adList;

          // Can't delete myself
          if (this.userEditParam.id === this.userProfileService.getUserId()) {
            this.status.disable();
          }
          // No Role Change
          this.role.disable();
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * Get Advertisers.
   */
  private async getAdvertisers() {
    await firstValueFrom(this.advertiserService.getAdvertisers()).then(
      (res) => {
        const advertisers: AdvertiserObjectType[] = [];
        res.data.advertisers?.edges.forEach((advertiser) => {
          if (advertiser && advertiser.node) {
            advertisers.push(advertiser.node);
          }
        });
        if (advertisers) {
          this.advertiserList = advertisers as AdvertiserObjectType[];
        }
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
      }
    );
  }

  /**
   * Create User.
   */
  public async onRegistration(): Promise<void> {
    if (
      this.userEditFormGroup.valid &&
      !this.isSubmitting &&
      this.checkAdvertiserSelected(this.role.value)
    ) {
      this.isSubmitting = true;
      this.spinnerService.show();
      // create request parameter
      const request: UserInputObjectType = {
        id: this.userEditParam.id,
        name: this.name.value,
        email: this.email.value,
        role: this.role.value,
        isActive: this.status.value,
        advertisers: [],
      };
      const selectedAdvertiser = this.selectedAdvertisers.map((ad) => {
        return ad.id;
      });
      request.advertisers = selectedAdvertiser;

      try {
        // create user
        const res = await firstValueFrom(this.userService.createUser(request));

        if (res.data?.userCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('User registration failed.');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.showToaster(excerptErrorMessage(error.message));
        }
      } finally {
        this.spinnerService.hide();
        this.isSubmitting = false;
      }
    }
  }

  /**
   * Update User
   */
  public onSave(): void {
    if (
      this.userEditFormGroup.valid &&
      this.userEditParam.id !== null &&
      this.checkAdvertiserSelected(this.role.value)
    ) {
      this.spinnerService.show();
      // create request parameter
      const request: UserInputObjectType = {
        id: this.userEditParam.id,
        name: this.name.value,
        email: this.email.value,
        role: this.role.value,
        isActive: this.status.value,
        advertisers: [],
      };
      const selectedAdvertiser = this.selectedAdvertisers.map((ad) => {
        return ad.id;
      });
      request.advertisers = selectedAdvertiser;

      // update
      const result = firstValueFrom(this.userService.updateUser(request));
      result.then(
        (res) => {
          if (res.data?.userUpdate?.ok) {
            this.showToaster('Editing completed');
            this._dialogRef.close(true);
          } else {
            this.showToaster('User update failed.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.showToaster(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * remind button click
   */
  public onRemind(): void {
    if (this.email.valid && this.userEditParam.id !== null) {
      this.spinnerService.show();
      // create request parameter
      const request: PasswordRemindInputObjectType = {
        email: this.email.value,
      };
      // call remind
      const result = firstValueFrom(this.userService.remindPassword(request));
      result.then(
        (res) => {
          if (res.data?.passwordRemind?.ok) {
            this.showToaster('Sent a re-invitation email.');
            this._dialogRef.close(true);
          } else {
            this.showToaster('Failed to send re-invitation email.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.showToaster(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * cancel
   */
  public onCancel(): void {
    this._dialogRef.close(false);
  }

  /**
   *  Whether one or more advertisers are selected
   * @param role UserRole
   * @returns boolean
   */
  private checkAdvertiserSelected(role: UserRole): boolean {
    let ret = true;
    this.errors.splice(0);
    if (role === UserRole.Editor && this.selectedAdvertisers.length === 0) {
      const error = 'Advertisers need at least one link.';
      this.errors.push(error);
      ret = false;
    }
    return ret;
  }

  /**
   * block AdvertiserRelated
   * @returns boolean
   */
  public isDisbaleAdvertiserRelated(): boolean {
    let ret = true;
    if (this.role.value === UserRole.Editor) {
      ret = false;
    }
    return ret;
  }

  /**
   * Edit block RoleChange
   * @returns boolean
   */
  public isDisbaleRoleChange(): boolean {
    let ret = true;
    if (this.userEditParam.id === null) {
      ret = false;
    }
    return ret;
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * Role ChangeEvent
   */
  public onChangeRoleType(): void {
    this.selectedAdvertisers.splice(0);
    this.selectedAdvertisers = [];
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.userEditFormGroup.get('name') as UntypedFormControl;
  }
  /**
   * email FormControl Getter
   */
  get email(): UntypedFormControl {
    return this.userEditFormGroup.get('email') as UntypedFormControl;
  }
  /**
   * role FormControl Getter
   */
  get role(): UntypedFormControl {
    return this.userEditFormGroup.get('role') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.userEditFormGroup.get('status') as UntypedFormControl;
  }
  /**
   * agentLoggedIn FormControl Getter
   */
  get agentLoggedIn(): UntypedFormControl {
    return this.userEditFormGroup.get('agentLoggedIn') as UntypedFormControl;
  }
}
