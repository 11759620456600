import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Directive({
  selector: '[appYearMonthPickerFormats]',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY/MM',
        },
        display: {
          dateInput: 'YYYY/MM',
          monthLabel: 'MM',
          monthYearLabel: 'YYYY MM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY MM',
        },
      },
    },
  ],
})
export class CustomPickerFormatsDirective {}
