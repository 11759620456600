import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageProfileService {
  public menus: Menu[] = [
    { id: 1, name: 'Users', url: '/user_setting/list', icon: 'manage_accounts' },
    { id: 2, name: 'Reports', url: '/reports/edit', icon: 'insert_drive_file' },
    { id: 3, name: 'Advertisers', url: '/advertisers', icon: 'corporate_fare' },
    { id: 4, name: 'Campaigns', url: '/campaigns', icon: 'event' },
    { id: 5, name: 'Flights', url: '/flights', icon: 'event' },
  ];
  public currentPage: string | null = null;
  public currentPageSubject$ = new Subject<string | null>();
  constructor() {}

  /**
   * Get menu list.
   * @returns Menu[]
   */
  getMenu(): Menu[] {
    return this.menus;
  }

  setTitle(page: string): void {
    this.currentPageSubject$.next(page);
  }

  getTitle(): Observable<string | null> {
    return this.currentPageSubject$.asObservable();
  }
}

/**
 * Interface for menu items.
 */
export interface Menu {
  id: number;
  name: string;
  url: string;
  icon: string;
}
