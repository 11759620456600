import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import {
  AdSizeObjectType,
  CampaignBillingType,
  CampaignDeliveryType,
  CreativeInputObjectType,
  CreativeType,
  QueryCreativeArgs,
  QueryCreativeSignedUrlArgs,
} from 'src/app/models/graphql/types';
import { ClickTypes } from 'src/app/models/state';
import {
  excerptErrorMessage,
  REGEX_CREATIVE_IMAGE_FILE,
  REGEX_URL,
  REGEX_VIDEO_PLAYBACK_ID,
} from 'src/app/resource/utility/common-util';
import { CreativeService } from 'src/app/services/creatives/creative.service';

/** Creative Modal Parameter */
export interface CreativeModalParams {
  flightId: string;
  creativeType: CreativeType;
  deliveryType: CampaignDeliveryType;
  billingType: CampaignBillingType;
  adSize: AdSizeObjectType;
}

@Component({
  selector: 'app-creative-edit',
  templateUrl: './creative-edit.component.html',
  styleUrls: ['./creative-edit.component.scss'],
})
export class CreativeEditComponent implements OnInit {
  /** ImageFile */
  @ViewChild('fileInput') fileInput: ElementRef = new ElementRef('fileInput');
  public file: File | null = null;
  public imageSrc: string | ArrayBuffer | null = null;
  /** Error */
  public fileSizeError: string | null = null;
  /** CreativeID */
  public creativeId: string | null = null;

  /** ClickTypes */
  public clickTypes = ClickTypes;

  /** CreativeFormGroup */
  public creativeFormGroup = this._formBuilder.group({
    clickable: new UntypedFormControl(true),
    clickUrl: new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX_URL)]),
    status: new UntypedFormControl(true, [Validators.required]),
  });

  /**
   * constructor
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param creativeService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public creativeModalParams: CreativeModalParams,
    private _dialogRef: MatDialogRef<CreativeEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private creativeService: CreativeService
  ) {
    // Get Creative
    this.getCreative(this.creativeModalParams.flightId);
  }

  ngOnInit(): void {
    // Status is forced Active
    this.creativeFormGroup.controls['status'].setValue(true);

    // CustomForm. Set FormGroup by creativeType.
    if (this.creativeModalParams.creativeType === CreativeType.Image) {
      this.creativeFormGroup.addControl(
        'imageUrl',
        new UntypedFormControl('', [Validators.required])
      );
      this.creativeFormGroup.addControl('creativeSignedUrl', new UntypedFormControl(''));
      this.creativeFormGroup.addControl('videoPlaybackId', new UntypedFormControl(''));
      this.creativeFormGroup.controls['imageUrl'].disable();
      return;
    }
    if (this.creativeModalParams.creativeType === CreativeType.Video) {
      this.creativeFormGroup.addControl(
        'videoPlaybackId',
        new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(REGEX_VIDEO_PLAYBACK_ID),
        ])
      );
      this.creativeFormGroup.addControl('creativeSignedUrl', new UntypedFormControl(''));
      this.creativeFormGroup.addControl('imageUrl', new UntypedFormControl(''));
      return;
    }
  }

  /**
   * getCreative
   * @param flightId
   */
  private async getCreative(flightId: string) {
    this.spinnerService.show();
    const variable: QueryCreativeArgs = {
      flightId: flightId,
    };
    // call get Creative
    await firstValueFrom(this.creativeService.getCreative(variable)).then(
      (res) => {
        const creative = res.data.creative;
        if (creative) {
          this.creativeId = creative.id;
          this.creativeFormGroup.controls['clickable'].setValue(creative.clickable);
          if (creative.clickable) {
            this.creativeFormGroup.controls['clickUrl'].enable();
            this.creativeFormGroup.controls['clickUrl'].setValue(creative.clickUrl);
          } else {
            this.creativeFormGroup.controls['clickUrl'].disable();
            this.creativeFormGroup.controls['clickUrl'].setValue('');
          }
          this.creativeFormGroup.controls['videoPlaybackId'].setValue(creative.videoId);
          this.creativeFormGroup.controls['status'].setValue(creative.isActive);
          this.creativeFormGroup.controls['imageUrl'].setValue(creative.imageUrl);
          this.imageSrc = creative.imageUrl ? creative.imageUrl : null;
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * Browse Button click
   * Call fileInput.click => onSelectUploadFile
   */
  public onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * onSelectUploadFile
   * Import local image files.
   * @param files FileList
   */
  public onSelectUploadFile(files: FileList | null) {
    const file = files ? files[0] : null;

    if (file) {
      if (!REGEX_CREATIVE_IMAGE_FILE.test(file.name)) {
        const message = 'Invalid file name. Only alphanumeric, hyphen, and underscore are allowed.';
        this._snackBar.open(message, 'close', {
          verticalPosition: 'top',
        });
        return;
      }

      this.file = file;
      // Image Preview
      const reader = new FileReader();
      reader.onload = this.readerOnloadCallback;
      reader.readAsDataURL(this.file);
    }
  }

  /**
   * readerOnloadCallback
   * @param e ProgressEvent
   */
  private readerOnloadCallback = (e: ProgressEvent) => {
    if (e.target) {
      // image src bind
      const readerSrc = (e.target as FileReader).result as string;
      this.loadImage(readerSrc).then((img: HTMLImageElement) => {
        this.imageSrc = readerSrc;
        this.fileSizeError = null;
        this.creativeFormGroup.controls['imageUrl'].setValue('');
        // Image size and AdSize are equal?
        if (
          img.width === this.creativeModalParams.adSize.width &&
          img.height === this.creativeModalParams.adSize.height
        ) {
          if (this.file) {
            // issue SignedURL
            this.getCreativeSignedURL(this.file);
          }
        } else {
          this.fileSizeError = `Not valid size for Adsize. Please import an image size of ${this.creativeModalParams.adSize.width}x${this.creativeModalParams.adSize.height}.`;
        }
      });
    }
  };

  /**
   * loadImage
   * @param src
   * @returns
   */
  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = src;
    });
  }

  /**
   * getCreativeSignedURL
   * Issue Signed URL.
   * @param file File
   */
  private async getCreativeSignedURL(file: File) {
    this.spinnerService.show();
    const variable: QueryCreativeSignedUrlArgs = {
      fileName: file.name,
    };
    // call get CreativeSignedURL
    await firstValueFrom(this.creativeService.getCreativeSignedURL(variable)).then(
      (res) => {
        const creativeSignedUrl = res.data.creativeSignedUrl;
        if (creativeSignedUrl) {
          this.creativeFormGroup.controls['creativeSignedUrl'].setValue(
            creativeSignedUrl.signedUrl
          );
          this.creativeFormGroup.controls['imageUrl'].setValue(creativeSignedUrl.fileUploadPath);
        } else {
          this.showToaster('Failed to get the URL of the file upload destination');
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Regist button click
   * Create creative information.
   */
  public async onRegistration(): Promise<void> {
    if (this.creativeFormGroup.invalid) {
      // Invalid
      return;
    }
    if (this.isCreativeTypeImage()) {
      if (this.file === null) {
        return;
      }
      this.spinnerService.show();
      await this.fileUpload(this.file).then(async () => {
        // CreativeType: image
        // Create Arg
        const variable: CreativeInputObjectType = {
          clickable: this.clickable.value,
          clickUrl: this.clickUrl.value ? this.clickUrl.value : undefined,
          imageUrl: this.imageUrl.value,
          isActive: this.status.value,
          flightId: this.creativeModalParams.flightId,
          billingType: this.creativeModalParams.billingType,
          deliveryType: this.creativeModalParams.deliveryType,
        };
        await this.createCreative(variable);
      });
      return;
    }
    if (this.isCreativeTypeVideo()) {
      // CreativeType: video
      // Create Arg
      const variable: CreativeInputObjectType = {
        clickable: this.clickable.value,
        clickUrl: this.clickUrl.value ? this.clickUrl.value : undefined,
        videoPlaybackId: this.videoPlaybackId.value,
        isActive: this.status.value,
        flightId: this.creativeModalParams.flightId,
        billingType: this.creativeModalParams.billingType,
        deliveryType: this.creativeModalParams.deliveryType,
      };
      await this.createCreative(variable);
      return;
    }
  }

  /**
   * Save button click
   * Update Creative information.
   */
  public async onSave(): Promise<void> {
    if (this.creativeFormGroup.invalid || !this.creativeId) {
      // Invalid
      return;
    }
    if (this.isCreativeTypeImage()) {
      if (this.file) {
        this.spinnerService.show();
        await this.fileUpload(this.file).then(async () => {
          // CreativeType: image
          // Update Arg
          const variable: CreativeInputObjectType = {
            creativeId: this.creativeId,
            clickable: this.clickable.value,
            clickUrl: this.clickUrl.value ? this.clickUrl.value : undefined,
            imageUrl: this.imageUrl.value,
            isActive: this.status.value,
            flightId: this.creativeModalParams.flightId,
            billingType: this.creativeModalParams.billingType,
            deliveryType: this.creativeModalParams.deliveryType,
          };
          await this.updateCreative(variable);
        });
        return;
      } else {
        // Update Arg
        const variable: CreativeInputObjectType = {
          creativeId: this.creativeId,
          clickable: this.clickable.value,
          clickUrl: this.clickUrl.value ? this.clickUrl.value : undefined,
          imageUrl: this.imageUrl.value,
          isActive: this.status.value,
          flightId: this.creativeModalParams.flightId,
          billingType: this.creativeModalParams.billingType,
          deliveryType: this.creativeModalParams.deliveryType,
        };
        await this.updateCreative(variable);
      }
      return;
    }
    if (this.isCreativeTypeVideo()) {
      // CreativeType: video
      // Update Arg
      const variable: CreativeInputObjectType = {
        creativeId: this.creativeId,
        clickable: this.clickable.value,
        clickUrl: this.clickUrl.value ? this.clickUrl.value : undefined,
        videoPlaybackId: this.videoPlaybackId.value,
        isActive: this.status.value,
        flightId: this.creativeModalParams.flightId,
        billingType: this.creativeModalParams.billingType,
        deliveryType: this.creativeModalParams.deliveryType,
      };
      await this.updateCreative(variable);
      return;
    }
  }

  /**
   * imageCreative
   * Upload image files to GCS
   * @param file File
   */
  private async fileUpload(file: File): Promise<void> {
    // FileUpload
    await this.creativeService
      .fileUpload(this.creativeSignedUrl.value, file)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        this.spinnerService.hide();
        this.showToaster(excerptErrorMessage('File upload failed'));
        return Promise.reject();
      });
  }

  /**
   * create Creative
   * @param variable CreativeInputObjectType
   */
  private async createCreative(variable: CreativeInputObjectType) {
    this.spinnerService.show();
    // create Creative
    await firstValueFrom(this.creativeService.createCreative(variable)).then(
      (res) => {
        if (res.data?.creativeCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Creative registration failed.');
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.spinnerService.hide();
        this.showToaster(excerptErrorMessage(error.message));
      }
    );
  }

  /**
   * update Creative
   * @param variable CreativeInputObjectType
   */
  private async updateCreative(variable: CreativeInputObjectType) {
    this.spinnerService.show();
    // update Creative
    await firstValueFrom(this.creativeService.updateCreative(variable)).then(
      (res) => {
        if (res.data?.creativeUpdate?.ok) {
          this.showToaster('Completion of update');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Creative update failed.');
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.spinnerService.hide();
        this.showToaster(excerptErrorMessage(error.message));
      }
    );
  }

  /**
   * Click Cancel button
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * isValidregistration
   * Registration button enabled
   * @returns
   */
  public isValidRegistration(): boolean {
    let ret = false;
    if (this.isCreativeTypeImage()) {
      if (
        this.creativeFormGroup.valid &&
        this.file &&
        this.creativeSignedUrl.value &&
        this.imageUrl.value
      ) {
        ret = true;
      }
    } else if (this.isCreativeTypeVideo()) {
      if (this.creativeFormGroup.valid) {
        ret = true;
      }
    }
    return ret;
  }

  /**
   * isValidSave
   * Save button enabled
   * @returns
   */
  public isValidSave(): boolean {
    let ret = false;
    if (this.isCreativeTypeImage()) {
      if (this.creativeFormGroup.valid && !this.file && this.imageUrl.value) {
        ret = true;
      } else {
        if (
          this.creativeFormGroup.valid &&
          this.file &&
          this.imageUrl.value &&
          this.creativeSignedUrl.value
        )
          ret = true;
      }
    } else if (this.isCreativeTypeVideo()) {
      if (this.creativeFormGroup.valid) {
        ret = true;
      }
    }
    return ret;
  }

  /**
   * CreativeType is Image
   */
  public isCreativeTypeImage(): boolean {
    if (this.creativeModalParams.creativeType === CreativeType.Image) {
      return true;
    }
    return false;
  }

  /**
   * CreativeType is Video
   */
  public isCreativeTypeVideo(): boolean {
    if (this.creativeModalParams.creativeType === CreativeType.Video) {
      return true;
    }
    return false;
  }

  /**
   * onChangeClickType
   * @param evnet MatRadioChange
   */
  public onChangeClickType(evnet: MatRadioChange): void {
    if (evnet.value) {
      this.creativeFormGroup.controls['clickUrl'].enable();
    } else {
      this.creativeFormGroup.controls['clickUrl'].setValue('');
      this.creativeFormGroup.controls['clickUrl'].disable();
    }
  }

  /**
   * CreativeFormGroup.clickable FormControl Getter
   */
  get clickable(): UntypedFormControl {
    return this.creativeFormGroup.get('clickable') as UntypedFormControl;
  }
  /**
   * CreativeFormGroup.signedURL FormControl Getter
   */
  get creativeSignedUrl(): UntypedFormControl {
    return this.creativeFormGroup.get('creativeSignedUrl') as UntypedFormControl;
  }
  /**
   * CreativeFormGroup.clickUrl FormControl Getter
   */
  get clickUrl(): UntypedFormControl {
    return this.creativeFormGroup.get('clickUrl') as UntypedFormControl;
  }
  /**
   * CreativeFormGroup.imageUrl FormControl Getter
   */
  get imageUrl(): UntypedFormControl {
    return this.creativeFormGroup.get('imageUrl') as UntypedFormControl;
  }
  /**
   * CreativeFormGroup.videoPlaybackId FormControl Getter
   */
  get videoPlaybackId(): UntypedFormControl {
    return this.creativeFormGroup.get('videoPlaybackId') as UntypedFormControl;
  }
  /**
   * CreativeFormGroup.status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.creativeFormGroup.get('status') as UntypedFormControl;
  }
}
