import { AdSizeService } from 'src/app/services/ad-sizes/ad-size.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import {
  Flight2DCreativeTypes,
  Flight3DCreativeTypes,
  FlightCreateModalParams,
} from 'src/app/models/flight';
import {
  AdSizeObjectType,
  CampaignAdType,
  CapType,
  CreativeType,
  CustomTargetingConfigInputObjectType,
  CustomTargetingConfigObjectType,
  FlightInputObjectType,
  GoalType,
  Maybe,
  PriorityObjectType,
  QueryAdSizesArgs,
  RateType,
  ZoneObjectType,
  ElementType,
} from 'src/app/models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import {
  convertStringToNumber,
  excerptErrorMessage,
  REGEX_FLIGHT_NAME,
} from 'src/app/resource/utility/common-util';
import { FlightService } from 'src/app/services/flights/flight.service';

@Component({
  selector: 'app-flight-embedded-registration',
  templateUrl: './flight-embedded-registration.component.html',
  styleUrls: ['./flight-embedded-registration.component.scss'],
  providers: [DatePipe],
})
export class FlightEmbeddedRegistrationComponent implements OnInit {
  /** Type PullDowns Enum*/
  public creativeTypes: CreativeType[] = [];
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);
  /** Type PullDown from GraphQL */
  public adSizeTypes: Pick<AdSizeObjectType, 'id' | 'name'>[] = [];

  public addedCustomTargetingFormControllNames: string[] = [];

  public flightCustomTargetings: Maybe<Array<Maybe<CustomTargetingConfigObjectType>>> | undefined =
    [];

  /** FlightGeneralFormGroup */
  public flightGeneralFormGroup = this._formBuilder.group({
    creativeType: new UntypedFormControl('', [Validators.required]),
    adSize: new UntypedFormControl('', [Validators.required]),
  });

  /** FormGroup */
  public flightFormGroup = this._formBuilder.group({
    friendlyName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_FLIGHT_NAME),
    ]),
    flightName: new UntypedFormControl(''),
    priority: new UntypedFormControl('', [Validators.required]),
    zone: new UntypedFormControl('', [Validators.required]),
    rate: new UntypedFormControl(RateType.CpaClick, [Validators.required]),
    price: new UntypedFormControl(0, [Validators.required]),
    goalType: new UntypedFormControl(GoalType.Conversions, [Validators.required]),
    goalAmount: new UntypedFormControl(0, [Validators.required]),
    capType: new UntypedFormControl(CapType.None, [Validators.required]),
    dailyCapAmount: new UntypedFormControl(0, [Validators.required]),
    lifeTimeCapAmount: new UntypedFormControl(0, [Validators.required]),
    status: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  });

  public isSubmitting = false;

  private isCreatedCustomTargetings = false;

  /**
   * Constructor
   * @param modalCreateParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param flightService
   * @param datePipe
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalCreateParam: FlightCreateModalParams,
    private _dialogRef: MatDialogRef<FlightEmbeddedRegistrationComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private adSizeService: AdSizeService,
    private flightService: FlightService,
    private datePipe: DatePipe
  ) {
    // Get SizeTypes
    this.getAdSizeTypes();
  }

  ngOnInit(): void {
    if (this.modalCreateParam.adType === CampaignAdType.Type2D) {
      this.creativeTypes = Flight2DCreativeTypes;
    } else if (this.modalCreateParam.adType === CampaignAdType.Type3D) {
      this.flightGeneralFormGroup.controls['adSize'].disable();
      this.creativeTypes = Flight3DCreativeTypes;
    }
    this.flightFormGroup.controls['flightName'].disable();
    this.flightFormGroup.controls['status'].setValue(false);
    this.flightFormGroup.controls['status'].disable();
  }

  /**
   * StepperSelectionChange
   */
  public selectionChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 1) {
      this.onNext();
    }
  }
  /**
   * Click Next button
   */
  public onNext(): void {
    this.resetFlightForm();
    if (this.flightGeneralFormGroup.valid) {
      this.setFlightEmbeddedTypesAndCustomTargetingForms();
      // FlightName AutoFill
      this.flightFormGroup.controls['flightName'].setValue(this.createFlightName());
    }
  }

  /** Reset FlightForm  */
  private resetFlightForm(): void {
    this.flightFormGroup.patchValue({
      friendlyName: '',
      startDate: '',
      endDate: '',
    });
  }

  /**
   * Click Save button
   */
  public async onRegistration(): Promise<void> {
    if (this.flightFormGroup.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.spinnerService.show();
      // Create Arg
      const variable: FlightInputObjectType = {
        campaignId: this.modalCreateParam.campaignId,
        creativeType: this.creativeType.value,
        adSizeId: this.adSize.value.id,
        name: this.createFlightName(),
        isActive: this.status.value,
        startDate: this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.endDate.value, 'yyyy-MM-dd'),
        priorityId: this.priority.value.id,
        zoneIds: [this.zone.value.id],
        rateType: this.rate.value,
        price: convertStringToNumber(this.price.value),
        goalType: this.goalType.value,
        goalAmount: parseInt(this.goalAmount.value),
        capType: this.capType.value,
        dailyCapAmount: convertStringToNumber(this.dailyCapAmount.value),
        lifetimeCapAmount: convertStringToNumber(this.lifeTimeCapAmount.value),
        customTargetings: this.getCustomTargetingRequestData(),
        deliveryType: this.modalCreateParam.deliveryType,
      };

      try {
        // createFlight
        const res = await firstValueFrom(this.flightService.createFlight(variable));

        if (res.data?.flightCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Flight registration failed.');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.showToaster(excerptErrorMessage(error.message));
        }
      } finally {
        this.spinnerService.hide();
        this.isSubmitting = false;
      }
    }
  }

  /**
   * Click Cancel button
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * get AdSizeTypes
   */
  private async getAdSizeTypes() {
    this.adSizeTypes = [];
    this.spinnerService.show();
    const variable: QueryAdSizesArgs = {
      deliveryType: this.modalCreateParam.deliveryType,
    };
    // call get adSizes
    await firstValueFrom(this.adSizeService.getAdSizes(variable)).then(
      (res) => {
        const adSizes = res.data.adSizes;
        if (adSizes) {
          adSizes.edges.map((edge) => {
            if (edge && edge.node) {
              this.adSizeTypes.push(edge.node);
              this.flightGeneralFormGroup.controls['adSize'].setValue(
                edge.node as Pick<AdSizeObjectType, 'id' | 'name'>
              );
              return;
            }
            return;
          });
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * get FlightEmbeddedTypes
   */
  private async setFlightEmbeddedTypesAndCustomTargetingForms() {
    this.spinnerService.show();

    // call get embeddedTypes data.
    await firstValueFrom(
      this.flightService.getFlightEmbeddedTypes(
        this.modalCreateParam.deliveryType,
        this.adSize.value.id
      )
    ).then(
      (res) => {
        const zones = res.data.granWhaleZones;
        if (zones) {
          zones.edges.map((edge) => {
            if (edge && edge.node) {
              this.flightFormGroup.controls['zone'].setValue(
                edge.node as Pick<ZoneObjectType, 'id' | 'name'>
              );
              return;
            }
            return;
          });
        }
        const priorities = res.data.priorities;
        if (priorities) {
          priorities.edges.map((edge) => {
            if (edge && edge.node) {
              this.flightFormGroup.controls['priority'].setValue(
                edge.node as Pick<PriorityObjectType, 'id' | 'name'>
              );
              return;
            }
            return;
          });
        }

        const customTargetings = res.data.customTargetings;
        if (customTargetings && customTargetings.edges) {
          // 1. Extract only the `node` from the `edges` array.
          const nodes: Maybe<CustomTargetingConfigObjectType>[] = customTargetings.edges.reduce<
            Maybe<CustomTargetingConfigObjectType>[]
          >((acc, edge) => {
            if (edge && edge.node) {
              acc.push(edge.node);
            }
            return acc;
          }, []);

          if (!this.isCreatedCustomTargetings) {
            this.isCreatedCustomTargetings = true;
            // 2. Create formControl to match others.
            this.generateFormFields(nodes);
          }

          // 3. Set the received values directly for the request.
          this.patchCustomTargetingsRegistredValues(nodes);
        }

        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * FlightName autofill
   * @returns
   */
  public createFlightName(): string {
    let ret = '';
    ret += `${this.creativeType.value.toLowerCase()}_`;
    ret += `${this.adSize.value.name}_`;
    ret += this.friendlyName.value;
    return ret;
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  private getCustomTargetingRequestData(): CustomTargetingConfigInputObjectType[] {
    const formControls = this.addedCustomTargetingFormControllNames;
    const requestData: CustomTargetingConfigInputObjectType[] = [];

    formControls.forEach((controlName) => {
      const control = this.flightFormGroup.controls[controlName];
      if (Array.isArray(control.value)) {
        requestData.push({
          key: controlName,
          values: control.value,
        });
      } else {
        requestData.push({
          key: controlName,
          values: control.value != null ? [control.value] : [],
        });
      }
    });
    return requestData;
  }

  /**
   * generate form
   * @param customTargeting
   */
  private generateFormFields(
    customTargetings: Maybe<Array<Maybe<CustomTargetingConfigObjectType>>> | undefined
  ) {
    if (customTargetings) {
      customTargetings.forEach((customTargeting) => {
        if (customTargeting) {
          // add customTargeting form
          if (!this.flightFormGroup.contains(customTargeting.key)) {
            // Retain customTargeting items added for reset purposes
            this.addedCustomTargetingFormControllNames.push(customTargeting.key);

            if (
              customTargeting.elementType === ElementType.CheckBox ||
              customTargeting.elementType === ElementType.RadioButton ||
              customTargeting.elementType === ElementType.TextBox
            ) {
              this.flightFormGroup.addControl(customTargeting.key, new UntypedFormControl([]));
            }
            if (customTargeting.isEditable === false) {
              this.flightFormGroup.controls[customTargeting.key].disable();
            }
          }
        }
      });
    }
  }

  private patchCustomTargetingsRegistredValues(
    customTargetings: Array<Maybe<CustomTargetingConfigObjectType>>
  ): void {
    customTargetings.forEach((customTargeting) => {
      if (customTargeting && customTargeting.key) {
        if (customTargeting.elementType === ElementType.CheckBox) {
          this.flightFormGroup.patchValue({
            [customTargeting.key]: customTargeting.values,
          });
        } else {
          this.flightFormGroup.patchValue({
            [customTargeting.key]: customTargeting.values?.[0],
          });
        }
      }
    });
  }

  /**
   * flightGeneralFormGroup.creativeType FormControl Getter
   */
  get creativeType(): UntypedFormControl {
    return this.flightGeneralFormGroup.get('creativeType') as UntypedFormControl;
  }
  /**
   * flightGeneralFormGroup.adSize FormControl Getter
   */
  get adSize(): UntypedFormControl {
    return this.flightGeneralFormGroup.get('adSize') as UntypedFormControl;
  }

  /**
   * flightFormGroup.friendlyName FormControl Getter
   */
  get friendlyName(): UntypedFormControl {
    return this.flightFormGroup.get('friendlyName') as UntypedFormControl;
  }
  /**
   * flightFormGroup.flightName FormControl Getter
   */
  get flightName(): UntypedFormControl {
    return this.flightFormGroup.get('flightName') as UntypedFormControl;
  }
  /**
   * flightFormGroup.priority FormControl Getter
   */
  get priority(): UntypedFormControl {
    return this.flightFormGroup.get('priority') as UntypedFormControl;
  }
  /**
   * flightFormGroup.zone FormControl Getter
   */
  get zone(): UntypedFormControl {
    return this.flightFormGroup.get('zone') as UntypedFormControl;
  }
  /**
   * flightFormGroup.rate FormControl Getter
   */
  get rate(): UntypedFormControl {
    return this.flightFormGroup.get('rate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.price FormControl Getter
   */
  get price(): UntypedFormControl {
    return this.flightFormGroup.get('price') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalType FormControl Getter
   */
  get goalType(): UntypedFormControl {
    return this.flightFormGroup.get('goalType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalAmount FormControl Getter
   */
  get goalAmount(): UntypedFormControl {
    return this.flightFormGroup.get('goalAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.capType FormControl Getter
   */
  get capType(): UntypedFormControl {
    return this.flightFormGroup.get('capType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.dailyCapAmount FormControl Getter
   */
  get dailyCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('dailyCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.lifeTimeCapAmount FormControl Getter
   */
  get lifeTimeCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('lifeTimeCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.flightFormGroup.get('status') as UntypedFormControl;
  }
  /**
   * flightFormGroup.startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.flightFormGroup.get('startDate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.flightFormGroup.get('endDate') as UntypedFormControl;
  }
}
