import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from '../core/spinner.service';
import { UserProfileService } from '../core/user-profile.service';
import { FlightCreateModalParams, FlightEditModalParams } from '../models/flight';
import {
  CampaignDeliveryType,
  CampaignObjectType,
  FlightObjectType,
  QueryCampaignArgs,
  QueryFlightsArgs,
} from '../models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from '../models/state';
import { Feature } from '../resource/feature-role';
import { excerptErrorMessage, getDecodeId } from '../resource/utility/common-util';
import { CampaignService } from '../services/campaigns/campaign.service';
import { FlightService } from '../services/flights/flight.service';
import {
  CreativeEditComponent,
  CreativeModalParams,
} from './creative-edit/creative-edit.component';
import { FlightAdserverEditComponent } from './flight-adserver-edit/flight-adserver-edit.component';
import { FlightAdserverRegistrationComponent } from './flight-adserver-registration/flight-adserver-registration.component';
import { FlightEmbeddedEditComponent } from './flight-embedded-edit/flight-embedded-edit.component';
import { FlightEmbeddedRegistrationComponent } from './flight-embedded-registration/flight-embedded-registration.component';

@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.scss'],
})
export class FlightsComponent implements OnInit, OnDestroy {
  /** Campaign */
  public campaignId = null;
  public campaign: CampaignObjectType | null = null;
  /** MatTable */
  public displayedColumns: string[] = [
    'id',
    'isActive',
    'name',
    'startDate',
    'endDate',
    'edit',
    'creative',
  ];
  public flightList: MatTableDataSource<FlightObjectType> = new MatTableDataSource();
  /** Pager */
  public pageLength: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 30;
  /** SortKey */
  public sortKey: string | undefined = undefined;

  /** FormFilterGroup */
  public formFilterGroup = this._formBuilder.group({
    name: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
  });
  /** Status */
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /**
   * Constructor
   * @param activeRoute
   * @param _formBuilder
   * @param _snackBar
   * @param _dialog
   * @param spinnerService
   * @param campaignService
   * @param flightService
   * @param userProfileService
   * @param router
   */
  constructor(
    private activeRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private spinnerService: SpinnerService,
    private campaignService: CampaignService,
    private flightService: FlightService,
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      // Set Parameter from URLQueryParameter
      this.campaignId = params['campaign_id'] ? params['campaign_id'] : null;
      this.pageSize = Number(params['size']) >= 0 ? Number(params['size']) : this.pageSize;
      this.pageIndex = Number(params['page']) >= 0 ? Number(params['page']) : this.pageIndex;
      this.sortKey = params['sort'] ? params['sort'] : undefined;
      this.name.setValue(params['name'] ? params['name'] : '');
      this.status.setValue(params['status'] ? params['status'] === 'true' : '');
      // create list
      this.createFlightList();
    });
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this._dialog.closeAll();
  }

  /**
   * createFlightList
   * @returns
   */
  private createFlightList(): void {
    // Campaign Consistency Check
    if (this.campaignId !== null) {
      this.getCampaign(this.campaignId);
    } else {
      // Error: inconsistent access
      this._snackBar.open(`you don't have permission to access`, 'close', {
        verticalPosition: 'top',
      });
      return;
    }
    // Get Flight for Campaign
    this.getFlights();
  }

  /**
   * getCampaign
   * @param campaignId
   */
  private async getCampaign(campaignId: string) {
    const variable: QueryCampaignArgs = {
      pk: campaignId,
    };
    // call get Campaign
    await firstValueFrom(this.campaignService.getCampaign(variable)).then(
      (res) => {
        const campaign = res.data.campaign;
        if (campaign) {
          this.campaign = campaign;
        } else {
          // Error: inconsistent access
          this._snackBar.open(`you don't have permission to access`, 'close', {
            verticalPosition: 'top',
          });
        }
      },
      (error: Error) => {
        // Error
        this._snackBar.open('Failed to get data. Unable to display list.', 'close', {
          verticalPosition: 'top',
        });
      }
    );
  }

  /**
   * Get Flights
   */
  private async getFlights() {
    this.spinnerService.show();
    this.flightList = new MatTableDataSource();
    // create variables
    const variables: QueryFlightsArgs = {
      offset: this.pageIndex * this.pageSize,
      first: this.pageSize,
      orderBy: this.sortKey,
      search: this.name.value,
      isActive: this.status.value === '' ? undefined : this.status.value,
      campaignId: this.campaignId,
    };
    // Get Flights
    await firstValueFrom(this.flightService.getFlights(variables)).then(
      (res) => {
        const flights = res.data.flights;
        const flightList: FlightObjectType[] = [];
        if (flights) {
          flights.edges.forEach((flight) => {
            if (flight && flight.node) {
              flightList.push(flight.node);
            }
          });
          this.flightList = new MatTableDataSource(flightList);
          // set pageInfo
          if (flights.edgeCount === 0) {
            this.pageIndex = 0;
            this.pageLength = 0;
          } else {
            this.pageLength = flights.totalCount;
          }
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * click new a Flight
   */
  public onAddFlight(): void {
    if (this.campaign) {
      const flightCreateModal: FlightCreateModalParams = {
        campaignId: this.campaign.id,
        campaignName: this.campaign.name,
        billingType: this.campaign.billingType,
        adType: this.campaign.adType,
        deliveryType: this.campaign.deliveryType,
      };
      if (this.campaign.deliveryType === CampaignDeliveryType.AdServer) {
        this._dialog
          .open(FlightAdserverRegistrationComponent, {
            data: flightCreateModal,
            width: '70%',
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.onRefresh();
            }
          });
        return;
      }
      if (this.campaign.deliveryType === CampaignDeliveryType.Embedded) {
        this._dialog
          .open(FlightEmbeddedRegistrationComponent, {
            data: flightCreateModal,
            width: '70%',
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.onRefresh();
            }
          });
        return;
      }
    }
  }

  /**
   * Click Edit button
   * @param element
   * @returns
   */
  public onEditFlight(element: FlightObjectType): void {
    if (this.campaign) {
      const flightEditModal: FlightEditModalParams = {
        flightId: element.id,
      };
      if (this.campaign.deliveryType === CampaignDeliveryType.AdServer) {
        this._dialog
          .open(FlightAdserverEditComponent, {
            data: flightEditModal,
            width: '70%',
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.onRefresh();
            }
          });
        return;
      }
      if (this.campaign.deliveryType === CampaignDeliveryType.Embedded) {
        this._dialog
          .open(FlightEmbeddedEditComponent, {
            data: flightEditModal,
            width: '70%',
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.onRefresh();
            }
          });
        return;
      }
    }
  }

  /**
   * Creative Edit Button
   * @param element FlightObjectType
   * @returns
   */
  public onEditCreative(element: FlightObjectType): void {
    const creativeModalParams: CreativeModalParams = {
      flightId: element.id,
      creativeType: element.creativeType,
      billingType: element.campaign.billingType,
      deliveryType: element.campaign.deliveryType,
      adSize: element.adSize,
    };
    this._dialog
      .open(CreativeEditComponent, {
        data: creativeModalParams,
        width: '70%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * PagingHandleEvent
   * @param event
   */
  public handlePageEvent(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.updateURLQueryParams();
  }

  /**
   * ColumsSort
   * @param sort
   */
  public sortByColumns(sort: Sort): void {
    let sortKey = sort.active;
    if (sort.direction === 'desc') {
      sortKey = '-' + sortKey;
    }
    this.sortKey = sortKey;
    this.pageIndex = 0;
    this.updateURLQueryParams();
  }

  /**
   * Click Refresh
   */
  public onRefresh(): void {
    this.getFlights();
  }

  /**
   * Apply Filter
   */
  public applyFilter(): void {
    this.updateURLQueryParams();
  }

  /**
   * Click Reset
   */
  public onFilterReset(): void {
    if (this.name.value === '' && this.status.value === '') {
      // no filer. nop
      return;
    }
    this.formFilterGroup.setValue({
      name: '',
      status: '',
    });
    this.applyFilter();
  }

  /**
   * FlightId decoded
   * @param encodedID
   * @returns
   */
  public getDecodeId(encodedID: string): string | undefined {
    return getDecodeId(encodedID);
  }

  /**
   * Check Embedded
   * Creative button is disabled when Embedded
   * @param campaign CampaignObjectType
   * @returns
   */
  public isEmbedded(campaign: CampaignObjectType): boolean {
    if (campaign?.deliveryType === CampaignDeliveryType.Embedded) {
      return true;
    }
    return false;
  }

  /**
   * registrationButtonEnabled
   * @returns
   */
  public registrationButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.createFlight)) {
      ret = true;
    }
    return ret;
  }

  /**
   * editButtonEnabled
   * @returns
   */
  public editButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.editFlight)) {
      ret = true;
    }
    return ret;
  }

  /**
   * editCreativeButtonEnabled
   * @returns
   */
  public editCreativeButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.editCreative)) {
      ret = true;
    }
    return ret;
  }

  /**
   * Update URL Query Parameters
   */
  private updateURLQueryParams(): void {
    // Set SearchCondition to QueryPramas
    this.router.navigate(['.'], {
      relativeTo: this.activeRoute,
      queryParams: {
        campaign_id: this.campaignId,
        page: this.pageIndex,
        size: this.pageSize,
        sort: this.sortKey ? this.sortKey : undefined,
        name: this.name.value ? this.name.value : undefined,
        status: this.status.value !== '' ? this.status.value : undefined,
      },
    });
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.formFilterGroup.get('name') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.formFilterGroup.get('status') as UntypedFormControl;
  }
}
