import { gql } from 'apollo-angular';

export const QueryGetCampaign = gql`
  query campaign($pk: ID!) {
    campaign(pk: $pk) {
      id
      name
      isActive
      billingType
      adType
      deliveryType
      dailyCostAmount
      lifetimeCostAmount
      advertiser {
        id
        name
        isActive
      }
    }
  }
`;
