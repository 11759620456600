import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { PageProfileService } from 'src/app/core/page-profile.service';
import { UserProfileService } from 'src/app/core/user-profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** PageTitle */
  public pageIcon: string | null = null;
  public pageTitle: string | null = null;
  private subscription: Subscription | null = null;

  /**
   * constructor
   * @param pageProfileService
   * @param userProfileService
   * @param loginService
   */
  constructor(
    private pageProfileService: PageProfileService,
    private userProfileService: UserProfileService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.getTitle();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  /**
   * get Title.
   */
  private getTitle(): void {
    this.subscription = this.pageProfileService.getTitle().subscribe((pageTitle: string | null) => {
      const result = this.pageProfileService.getMenu().filter((page) => page.name === pageTitle);
      if (result.length !== 0) {
        this.pageIcon = result[0].icon;
        this.pageTitle = result[0].name;
      }
    });
  }
  /**
   * Get Login User.
   * @returns
   */
  public getLoginUser(): string | null {
    return this.userProfileService.getUserName();
  }

  /**
   * Log the user out by discarding the token and clearing user profile data.
   */
  public async logout() {
    await firstValueFrom(this.loginService.tokenDiscard()).finally(() => {
      this.userProfileService.logout();
    });
  }
}
