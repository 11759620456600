<h2 mat-dialog-title>{{ formTitle }}</h2>
<mat-dialog-content style="height: 50%">
  <form [formGroup]="campaignFormGroup" class="campaign-edit-form">
    <p *ngIf="modalEditParam.campaignId">ID: {{ displayCampaignId }}</p>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>CampaignName</mat-label>
      <input matInput type="text" formControlName="campaignName" />
    </mat-form-field>
    <div class="flex-gap-20px -flex">
      <div class="flex-50 align-start-start -flex flex-shrink">
        <mat-form-field appearance="fill" *ngIf="campaignType.dailyCostAmount" style="width: 100%">
          <mat-label>DailyCostAmount </mat-label>
          <input matInput type="number" appIntegerOnly formControlName="dailyCostAmount" />
          <mat-hint>{{ dailyCostAmount.value | currency: 'JPY' }}</mat-hint>
          <mat-error
            *ngIf="
              dailyCostAmount.errors?.['pattern'] ||
              dailyCostAmount.errors?.['max'] ||
              dailyCostAmount.errors?.['min']
            "
          >
            Invalid DailyCostAmount format. Valid range is 0 ~ 2147483647
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-50 align-center-center -flex flex-shrink">
        <mat-form-field
          appearance="fill"
          *ngIf="campaignType.lifetimeCostAmount"
          style="width: 100%"
        >
          <mat-label>LifetimeCostAmount </mat-label>
          <input matInput type="number" appIntegerOnly formControlName="lifetimeCostAmount" />
          <mat-hint>{{ lifetimeCostAmount.value | currency: 'JPY' }}</mat-hint>
          <mat-error
            *ngIf="
              lifetimeCostAmount.errors?.['pattern'] ||
              lifetimeCostAmount.errors?.['max'] ||
              lifetimeCostAmount.errors?.['min']
            "
          >
            Invalid LifetimeCostAmount format. Valid range is 0 ~ 2147483647
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-gap-20px -flex">
      <div class="flex-50 align-start-start -flex flex-shrink">
        <mat-form-field class="form-item-field" appearance="fill" style="width: 100%">
          <mat-label>status</mat-label>
          <mat-select formControlName="status">
            <mat-option
              *ngFor="let activeStatusType of activeStatusTypes"
              [value]="activeStatusType.value"
              >{{ activeStatusType.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-50 align-center-center -flex flex-shrink"></div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button class="btn" color="basic" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        *ngIf="modalEditParam.campaignId"
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!campaignFormGroup.valid"
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-actions>
