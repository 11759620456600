import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../core/user-profile.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  /**
   * constructor
   * @param _router
   * @param userProfileService
   */
  constructor(
    private _router: Router,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    if (this.userProfileService.isAuthenticated()) {
      this._router.navigateByUrl('/reports/edit');
    } else {
      this.userProfileService.logout();
    }
    return;
  }
}
