<div class="container">
  <div class="flex-row -flex" style="padding: 5px">
    <mat-card appearance="outlined" class="card">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">New Report</mat-card-title>
      </mat-card-header>
      <mat-divider inset></mat-divider>
      <mat-card-content class="card-content">
        <form [formGroup]="reportForm">
          <div class="flex-row -flex" style="height: 100%; padding: 5px; box-sizing: border-box">
            <div class="align-start-stretch flex-50">
              <fieldset class="fieldset">
                <legend>Report Settings</legend>
                <mat-form-field style="width: 100%">
                  <mat-label>report name</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="reportName"
                    maxlength="64"
                    required
                  />
                  <mat-error *ngIf="reportName.errors?.['required']">
                    ReportName is required.
                  </mat-error>
                </mat-form-field>
                <div class="flex-row flex-gap-20px -flex">
                  <div class="align-center-center flex-50">
                    <mat-form-field style="width: 100%">
                      <mat-label>report type</mat-label>
                      <mat-select
                        formControlName="reportType"
                        (selectionChange)="onChangeReportType(choosedValue.value)"
                        #choosedValue
                      >
                        <mat-option *ngFor="let rType of reportTypes" value="{{ rType }}">{{
                          rType
                        }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="reportType.errors?.['required']">
                        ReportType is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-row flex-gap-20px -flex">
                  <div class="align-center-center flex-50">
                    <mat-form-field style="width: 100%">
                      <mat-label>start date * - end date *</mat-label>
                      <mat-date-range-input
                        [formGroup]="reportForm"
                        [rangePicker]="searchRangePicker"
                        [comparisonStart]="reportForm.value.startDate"
                        [comparisonEnd]="reportForm.value.endDate"
                      >
                        <input
                          matStartDate
                          placeholder="Start date"
                          formControlName="startDate"
                          readonly
                        />
                        <input
                          matEndDate
                          placeholder="End date"
                          formControlName="endDate"
                          readonly
                        />
                      </mat-date-range-input>
                      <mat-hint>YYYY/MM/DD - YYYY/MM/DD</mat-hint>
                      <mat-error *ngIf="startDate.errors?.['required']">
                        StartDate is required.
                      </mat-error>
                      <mat-error *ngIf="endDate.errors?.['required']">
                        EndDate is required.
                      </mat-error>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="searchRangePicker"
                        style="font-size: 20px"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #searchRangePicker></mat-date-range-picker>
                    </mat-form-field>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="align-start-stretch flex-50 condition-panel">
              <fieldset class="fieldset">
                <legend>Report Group</legend>
                <section formArrayName="groups">
                  <ng-container *ngFor="let groupingType of groupingTypes">
                    <mat-checkbox
                      class="checkbox-section-item"
                      value="{{ groupingType }}"
                      (change)="onGroupingItemSelect(groupingType, $event.checked)"
                      [checked]="checkGroupingItem(groupingType)"
                    >
                      {{ groupingType }}
                    </mat-checkbox>
                  </ng-container>
                </section>
              </fieldset>
            </div>
          </div>

          <div class="flex-row -flex" style="height: 100%; padding: 5px; box-sizing: border-box">
            <div class="align-start-stretch flex-50">
              <fieldset class="fieldset" [formGroup]="filterForm">
                <legend>Filter</legend>
                <mat-form-field style="width: 75%">
                  <mat-label>filter</mat-label>
                  <mat-select formControlName="key" (selectionChange)="onChangeFilterKey()">
                    <mat-option *ngFor="let filterKey of reportFilterKeys" value="{{ filterKey }}">
                      {{ filterKey }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 75%">
                  <mat-label>value</mat-label>
                  <mat-select formControlName="value">
                    <mat-option
                      *ngFor="let filterValueItem of filterValueItems"
                      [value]="filterValueItem"
                      >{{ filterValueItem.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div style="display: inline; margin-left: 30px">
                  <button
                    mat-stroked-button
                    color="primary"
                    [disabled]="filterInputCheck()"
                    (click)="filterAddRow()"
                  >
                    Add
                  </button>
                </div>
                <mat-error *ngIf="value.errors?.['pattern']">Already added.</mat-error>
                <table mat-table [dataSource]="filterList" style="width: 100%" #filterTable>
                  <ng-container matColumnDef="filter">
                    <th mat-header-cell *matHeaderCellDef style="width: 45%">Filter</th>
                    <td mat-cell *matCellDef="let element">{{ element.key }}</td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef style="width: 45%">Value</th>
                    <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%"></th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <button mat-icon-button color="accent" (click)="filterDeleteRow(i)">
                        <mat-icon style="font-size: 19px">close</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="filterColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: filterColumns"></tr>
                </table>
              </fieldset>
            </div>
            <div class="align-start-stretch flex-50">
              <fieldset class="fieldset">
                <legend>Indicators*</legend>
                <section formArrayName="indicators">
                  <mat-checkbox
                    *ngIf="indicatorTypes.length !== 0"
                    [checked]="checkIndicatorAll(indicatorTypes)"
                    [indeterminate]="someIndicator()"
                    (change)="onIndicatorItemSelectAll(indicatorTypes, $event.checked)"
                    >All
                  </mat-checkbox>
                  <ng-container *ngFor="let indicatorType of indicatorTypes">
                    <mat-checkbox
                      class="checkbox-section-item"
                      (change)="onIndicatorItemSelect(indicatorType, $event.checked)"
                      [checked]="checkIndicatorItem(indicatorType)"
                    >
                      {{ indicatorType }}
                    </mat-checkbox>
                  </ng-container>
                </section>
              </fieldset>
            </div>
          </div>
          <mat-error *ngFor="let err of errors">{{ err }}</mat-error>
          <div class="btn-form">
            <button
              type="button"
              mat-flat-button
              class="btn"
              color="primary"
              (click)="create()"
              [disabled]="!reportForm.valid"
            >
              Generate Report
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
