import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QueryGetAdSizes } from 'src/app/models/graphql/queries/get-ad-sizes.graphql';

import { Query, QueryAdSizesArgs } from 'src/app/models/graphql/types';

@Injectable({
  providedIn: 'root',
})
export class AdSizeService {
  constructor(private apollo: Apollo) {}

  /**
   * Get ad sizes.
   * @param variables QueryAdSizesArgs
   * @returns
   */
  public getAdSizes(variables?: QueryAdSizesArgs) {
    return this.apollo.query<Query, QueryAdSizesArgs>({
      query: QueryGetAdSizes,
      variables: {
        offset: variables?.offset,
        first: variables?.first,
        after: variables?.after,
        last: variables?.last,
        before: variables?.before,
        width: variables?.width,
        height: variables?.height,
        deliveryType: variables?.deliveryType,
        orderBy: variables?.orderBy,
        name: variables?.name,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
