import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MutateAdvertiserCreate } from 'src/app/models/graphql/mutation/create-advertiser.graphql';
import { MutateAdvertiserUpdate } from 'src/app/models/graphql/mutation/update-advertiser.graphql';
import { QueryGetAdvertiser } from 'src/app/models/graphql/queries/get-advertiser.graphql';
import { QueryGetAdvertisers } from 'src/app/models/graphql/queries/get-advertisers.graphql';
import {
  AdvertiserInputObjectType,
  Mutation,
  MutationAdvertiserCreateArgs,
  MutationAdvertiserUpdateArgs,
  Query,
  QueryAdvertiserArgs,
  QueryAdvertisersArgs,
} from 'src/app/models/graphql/types';

@Injectable({
  providedIn: 'root',
})
export class AdvertiserService {
  constructor(private apollo: Apollo) {}

  /**
   * Get Advertisers
   * @param variables QueryAdvertisersArgs
   * @returns
   */
  public getAdvertisers(variables?: QueryAdvertisersArgs) {
    return this.apollo.query<Query, QueryAdvertisersArgs>({
      query: QueryGetAdvertisers,
      variables: {
        offset: variables?.offset,
        first: variables?.first,
        after: variables?.after,
        last: variables?.last,
        before: variables?.before,
        isActive: variables?.isActive,
        orderBy: variables?.orderBy,
        search: variables?.search,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Get Advertiser
   * @param id string
   * @returns
   */
  public getAdvertiser(id: string) {
    return this.apollo.query<Query, QueryAdvertiserArgs>({
      query: QueryGetAdvertiser,
      variables: {
        pk: id,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Create Advertiser
   * @param variables AdvertiserInputObjectType
   * @returns
   */
  public createAdvertiser(input: AdvertiserInputObjectType) {
    return this.apollo.mutate<Mutation, MutationAdvertiserCreateArgs>({
      mutation: MutateAdvertiserCreate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Update Advertiser
   * @param variables AdvertiserInputObjectType
   * @returns
   */
  public updateAdvertiser(input: AdvertiserInputObjectType) {
    return this.apollo.mutate<Mutation, MutationAdvertiserUpdateArgs>({
      mutation: MutateAdvertiserUpdate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
