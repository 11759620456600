<div class="container">
  <div class="container-wrapper">
    <mat-card appearance="outlined" style="width: 480px">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">Password Registration Completed</mat-card-title>
        <mat-card-subtitle>Password setting is complete.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card-contents">
        <p>We will send the login URL to the registered e-mail address.</p>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <div class="btn-form">
          <button
            type="button"
            mat-raised-button
            class="btn"
            color="primary"
            (click)="goToLoginPage()"
          >
            Go to LoginPage
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
