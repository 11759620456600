import { gql } from 'apollo-angular';

export const QueryGetCreative = gql`
  query creative($flightId: ID!) {
    creative(flightId: $flightId) {
      id
      clickable
      clickUrl
      imageUrl
      videoId
      isActive
      billingType
      deliveryType
    }
  }
`;
