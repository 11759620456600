import {
  CampaignAdType,
  CampaignBillingType,
  CampaignDeliveryType,
  CreativeType,
} from './graphql/types';

/** CreativeType 2D */
export const Flight2DCreativeTypes: CreativeType[] = [CreativeType.Image, CreativeType.Video];

/** CreativeType 3D */
export const Flight3DCreativeTypes: CreativeType[] = [
  CreativeType.Avatar,
  CreativeType.Attraction,
  CreativeType.Showroom,
];

/** Create Modal Parameter */
export interface FlightCreateModalParams {
  campaignId: string;
  campaignName: string;
  billingType: CampaignBillingType;
  adType: CampaignAdType;
  deliveryType: CampaignDeliveryType;
}

/** Edit Modal Parameter */
export interface FlightEditModalParams {
  flightId: string;
}
