import {
  FilterType,
  GroupingType,
  IndicatorType,
  ReportFilterInputObjectType,
  ReportType,
} from './graphql/types';

/**
 * ReportRequest
 */
export interface ReportRequest {
  startDate: string;
  endDate: string;
  reportType: ReportType;
  groups?: GroupingType[];
  indicators: IndicatorType[];
  filters?: ReportFilterInputObjectType[];
}

/** Indicator items of 2DNormal report */
export const IndicatorItem2DNormal: IndicatorType[] = [
  IndicatorType.Show,
  IndicatorType.Imp,
  IndicatorType.ViewImp,
  IndicatorType.ViewAbility,
  IndicatorType.Click,
  IndicatorType.Video5s,
  IndicatorType.Video15s,
  IndicatorType.Video30s,
  IndicatorType.Engagement,
  IndicatorType.EngRate,
  IndicatorType.Action,
  IndicatorType.Cost,
  IndicatorType.Cpm,
  IndicatorType.Cpe,
];

/** Indicator items of 2DPremium report */
export const IndicatorItem2DPremium: IndicatorType[] = [
  IndicatorType.Show,
  IndicatorType.Imp,
  IndicatorType.ViewImp,
  IndicatorType.ViewAbility,
  IndicatorType.Click,
  IndicatorType.Video5s,
  IndicatorType.Video15s,
  IndicatorType.Video30s,
  IndicatorType.Engagement,
  IndicatorType.EngRate,
  IndicatorType.Action,
];

/** Indicator items of 3DPremium report */
export const IndicatorItem3DPremium: IndicatorType[] = [
  IndicatorType.ViewImp,
  IndicatorType.Engagement,
  IndicatorType.Action,
];

/** Report condition interface */
export interface ReportCondition {
  reportId: string;
  reportName: string;
  reportType: ReportType;
  startDate: string;
  endDate: string;
  groups: GroupingType[];
  filters: FilterItems[];
  indicators: IndicatorType[];
}
export interface FilterItems {
  key: FilterType;
  value: string;
  displayName: string;
}
