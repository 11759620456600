import { gql } from 'apollo-angular';

export const MutatePasswordResetRequest = gql`
  mutation passwordResetRequest($input: PasswordResetRequestInputObjectType!) {
    passwordResetRequest(input: $input) {
      ok
    }
  }
`;

export const MutatePasswordUpdate = gql`
  mutation passwordUpdate($input: PasswordUpdateInputObjectType!) {
    passwordUpdate(input: $input) {
      token
      refreshToken
      user {
        id
        name
        email
        isActive
        role
        advertisers {
          edges {
            node {
              id
              name
              isActive
            }
          }
        }
      }
    }
  }
`;
