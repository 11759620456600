import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { LoginService } from 'src/app/services/login/login.service';
import { excerptErrorMessage, REGEX_EMAIL, REGEX_PWD } from 'src/app/resource/utility/common-util';
import { UserProfileService } from 'src/app/core/user-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /** login FormGroup */
  public loginForm = this._formBuilder.group({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(REGEX_EMAIL),
    ]),
    password: new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX_PWD)]),
  });

  /** Password vidible */
  public btnPassVisible: boolean = true;
  /** errorlist */
  public errors: string[] = [];

  /**
   * constructor
   * @param _router
   * @param _formBuilder
   * @param loginService
   * @param spinnerService
   * @param userProfileService
   */
  constructor(
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    // token clear
    this.userProfileService.clearToken();
    return;
  }

  /**
   * Login
   * click signin button
   */
  public async login() {
    if (this.loginForm.valid) {
      this.spinnerService.show();
      this.errors.splice(0);
      // call otpRequest
      await firstValueFrom(this.loginService.otpRequest(this.loginForm.value)).then(
        (res) => {
          const response = res.data?.otpRequest;
          if (response) {
            localStorage.setItem('email', this.email.value);
            this._router.navigateByUrl('/login_otp_auth');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.errors.push(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * Email FormControl Getter
   */
  get email(): UntypedFormControl {
    return this.loginForm.get('email') as UntypedFormControl;
  }
  /**
   * password FormControl Getter
   */
  get password(): UntypedFormControl {
    return this.loginForm.get('password') as UntypedFormControl;
  }
}
