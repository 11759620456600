import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink, ApolloClient } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { HttpClientModule } from '@angular/common/http';
import { AGENT_SERVER_URL } from 'src/environments/environment';
import { onError } from '@apollo/client/link/error';
import { UserProfileService } from './core/user-profile.service';
import { MutateTokenRefresh } from './models/graphql/mutation/auth-login.graphql';
import { TokenRefreshInputObjectType, TokenRefreshMutation } from './models/graphql/types';

const uri = `${AGENT_SERVER_URL}/graphql/`;

export function createApollo(
  httpLink: HttpLink,
  userProfileService: UserProfileService
): ApolloClientOptions<any> {
  /** setContext */
  const authLink = setContext(async (operation) => {
    /** no auth function */
    const noAuth = ['otpRequest', 'otpTokenObtain', 'passwordResetRequest'];
    if (operation.operationName && noAuth.includes(operation.operationName)) {
      return {};
    }
    let token = userProfileService.getToken();
    if (!token) {
      userProfileService.logout();
      return;
    }
    // Old Token at RefreshToken
    if (operation.operationName === 'tokenRefresh') {
      return {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
    }
    if (!userProfileService.getRefreshing()) {
      if (userProfileService.refreshTokenExpired(token)) {
        // Logout if RefreshToken has expired
        userProfileService.logout();
        return;
      }
      // Not getting refreshToken
      const expired = userProfileService.tokenExpired(token);
      if (expired) {
        const newToken = await tokenRefresh();
        if (!newToken) {
          userProfileService.logout();
          return;
        }
        if (!userProfileService.tokenDecoded(newToken)) {
          userProfileService.logout();
          return;
        }
        return {
          headers: {
            Authorization: `JWT ${newToken}`,
          },
        };
      } else {
        return {
          headers: {
            Authorization: `JWT ${token}`,
          },
        };
      }
    } else {
      // Acquiring refreshToken. wait for refreshing
      const refreshFinished = await isRefreshing();
      if (refreshFinished) {
        // New Token reacquisition
        token = userProfileService.getToken();
        if (!token) {
          userProfileService.logout();
          return;
        }
        return {
          headers: {
            Authorization: `JWT ${token}`,
          },
        };
      } else {
        return;
      }
    }
  });

  /**
   * isRefreshing
   * @returns
   */
  async function isRefreshing(): Promise<boolean> {
    return await new Promise((resolve) => {
      const subscrition = userProfileService.getRefreshSubject().subscribe((refreshing) => {
        if (!refreshing) {
          subscrition.unsubscribe();
          resolve(true);
        }
      });
    });
  }

  // Request a refresh token to then stores and returns the accessToken.
  const tokenRefresh = async (): Promise<string | null> => {
    // Lock Refreshing
    userProfileService.setRefreshing(true);
    // Create Request
    const refreshToken = userProfileService.getRefreshToken();
    const request: TokenRefreshInputObjectType = {
      refreshToken: refreshToken || '',
    };
    // RefreshToken Mutate
    const refreshResolverResponse = new ApolloClient({ link, cache })
      .mutate<{
        refreshToken: TokenRefreshMutation;
      }>({
        mutation: MutateTokenRefresh,
        variables: {
          input: request,
        },
      })
      .then((res: any) => {
        if (res.data.tokenRefresh) {
          userProfileService.setToken(
            res.data.tokenRefresh.token,
            res.data?.tokenRefresh.refreshToken
          );
          return res.data.tokenRefresh.token;
        } else {
          return null;
        }
      })
      .catch(() => {
        return null;
      })
      .finally(() => {
        // unLock Refreshing
        userProfileService.setRefreshing(false);
      });
    return refreshResolverResponse;
  };

  // errorLink
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      const noAuth = ['otpRequest', 'otpTokenObtain', 'passwordResetRequest'];
      if (noAuth.includes(operation.operationName)) {
        return;
      }
      if (graphQLErrors[0].extensions && graphQLErrors[0].extensions['error']) {
        const error = graphQLErrors[0].extensions['error'] as {
          statusCode: number;
          message: string;
        };
        if (error.statusCode === 401) {
          userProfileService.logout();
          return;
        }
        return;
      }
    }
    if (networkError) {
      networkError.message = 'Server connection error has occured; please try again later.';
      return;
    }
  });

  /** Create setLink */
  const link = ApolloLink.from([authLink, errorLink, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, UserProfileService],
    },
  ],
})
export class GraphQLModule {}
