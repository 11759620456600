<div class="flex-column -flex" style="width: 100%; height: 100%; border-right: 1px solid #d7dbde">
  <div class="align-start-stretch -flex">
    <mat-toolbar style="height: 48px">
      <img width="160" height="48" src="assets/images/logo.png" />
    </mat-toolbar>
  </div>
  <div class="align-start-stretch flex-grow">
    <mat-drawer-container class="drawer-container">
      <mat-drawer class="drawer" opened>
        <mat-nav-list>
          <mat-list-item
            *ngFor="let menu of menus"
            (click)="onMenuClick(menu.url)"
            style="
              background: #030318;
              margin: 3px;
              display: flex;
              align-items: center;
              height: 56px;
            "
          >
            <mat-icon mat-list-icon style="color: aliceblue; margin: 0 20px 0 4px">{{
              menu.icon
            }}</mat-icon>
            <div mat-line style="color: aliceblue">{{ menu.name }}</div>
          </mat-list-item>
        </mat-nav-list>
      </mat-drawer>
    </mat-drawer-container>
  </div>
</div>
