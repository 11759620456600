import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from '../core/spinner.service';
import { UserProfileService } from '../core/user-profile.service';
import { AdvertiserObjectType, QueryAdvertisersArgs } from '../models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from '../models/state';
import { Feature } from '../resource/feature-role';
import { excerptErrorMessage, getDecodeId } from '../resource/utility/common-util';
import { AdvertiserService } from '../services/advertisers/advertiser.service';
import {
  AdvertiserEditComponent,
  AdvertiserEditParam,
} from './advertiser-edit/advertiser-edit.component';

@Component({
  selector: 'app-advertisers',
  templateUrl: './advertisers.component.html',
  styleUrls: ['./advertisers.component.scss'],
})
export class AdvertisersComponent implements OnInit, OnDestroy {
  /** MatTable */
  public displayedColumns: string[] = ['name', 'id', 'isActive', 'edit'];
  public advertiserList: MatTableDataSource<AdvertiserObjectType> = new MatTableDataSource();
  /** Pager */
  public pageLength: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 30;
  /** SortKey */
  public sortKey: string | undefined = undefined;

  /** FormFilterGroup */
  public formFilterGroup = this._formBuilder.group({
    name: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
  });

  /** Status Types*/
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /**
   * constructor
   * @param _dialog
   * @param _formBuilder
   * @param _snackBar
   * @param advertiserService
   * @param spinnerService
   * @param userProfileService
   * @param activeRoute
   * @param router
   */
  constructor(
    private _dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private advertiserService: AdvertiserService,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      // Parameter Set.
      this.pageSize = Number(params['size']) >= 0 ? Number(params['size']) : this.pageSize;
      this.pageIndex = Number(params['page']) >= 0 ? Number(params['page']) : this.pageIndex;
      this.sortKey = params['sort'] ? params['sort'] : undefined;
      this.name.setValue(params['name'] ? params['name'] : '');
      this.status.setValue(params['status'] ? params['status'] === 'true' : '');
      // GetAdvertisers
      this.getAdvertisers();
    });
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this._dialog.closeAll();
  }

  /**
   * Get Advertiser
   */
  private async getAdvertisers() {
    this.spinnerService.show();
    this.advertiserList = new MatTableDataSource();
    // create variables
    const variables: QueryAdvertisersArgs = {
      offset: this.pageIndex * this.pageSize,
      first: this.pageSize,
      orderBy: this.sortKey,
      search: this.name.value,
      isActive: this.status.value === '' ? undefined : this.status.value,
    };
    // Get advertiser list
    await firstValueFrom(this.advertiserService.getAdvertisers(variables)).then(
      (res) => {
        const advertisers = res.data.advertisers;
        const advertiserList: AdvertiserObjectType[] = [];
        if (advertisers) {
          advertisers.edges.forEach((advertiser) => {
            if (advertiser && advertiser.node) {
              advertiserList.push(advertiser.node);
            }
          });
          this.advertiserList = new MatTableDataSource(advertiserList);
          // set pageInfo
          if (advertisers.edgeCount === 0) {
            this.pageIndex = 0;
            this.pageLength = 0;
          } else {
            this.pageLength = advertisers.totalCount;
          }
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Open New Advertiser
   */
  public onAddAdvertiser(): void {
    const editForm: AdvertiserEditParam = {
      id: null,
    };
    this._dialog
      .open(AdvertiserEditComponent, {
        data: editForm,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * Open Edit Advertiser
   */
  public onEditAdvertiser(element: AdvertiserObjectType): void {
    const editForm: AdvertiserEditParam = {
      id: element.id,
    };
    this._dialog
      .open(AdvertiserEditComponent, {
        data: editForm,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * AdvertiserID Decode
   * @param encodedId
   * @returns
   */
  public getDecodeId(encodedId: string): string | undefined {
    return getDecodeId(encodedId);
  }

  /**
   * ColumsSort
   * @param sort
   */
  public sortByColumns(sort: Sort): void {
    let sortKey = sort.active;
    if (sort.direction === 'desc') {
      sortKey = '-' + sortKey;
    }
    this.sortKey = sortKey;
    this.pageIndex = 0;
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * PagingHandleEvent
   * @param event
   */
  public handlePageEvent(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * Click Refresh
   */
  public onRefresh(): void {
    this.getAdvertisers();
  }

  /**
   * Apply Filter
   */
  public applyFilter(): void {
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * Click Reset
   */
  public onFilterReset(): void {
    if (this.name.value === '' && this.status.value === '') {
      // no filer. nop
      return;
    }
    this.formFilterGroup.setValue({
      name: '',
      status: '',
    });
    this.applyFilter();
  }

  /**
   * Update URL Query Parameters
   */
  private updateURLQueryParams(): void {
    // Set SearchCondition to QueryPramas
    this.router.navigate(['.'], {
      relativeTo: this.activeRoute,
      queryParams: {
        page: this.pageIndex,
        size: this.pageSize,
        sort: this.sortKey ? this.sortKey : undefined,
        name: this.name.value ? this.name.value : undefined,
        status: this.status.value !== '' ? this.status.value : undefined,
      },
    });
  }

  /**
   * registrationButtonEnabled
   * @returns
   */
  public registrationButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.createAdvertiser)) {
      ret = true;
    }
    return ret;
  }

  /**
   * editButtonEnabled
   * @returns
   */
  public editButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.editAdvertiser)) {
      ret = true;
    }
    return ret;
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.formFilterGroup.get('name') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.formFilterGroup.get('status') as UntypedFormControl;
  }
}
