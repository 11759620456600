import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import {
  GroupingType,
  IndicatorType,
  PerformanceReportObjectType,
} from 'src/app/models/graphql/types';

@Component({
  selector: 'app-graph-report',
  templateUrl: './graph-report.component.html',
  styleUrls: ['./graph-report.component.scss'],
})
export class GraphReportComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() public reportGroupType: GroupingType[] | undefined = [];
  @Input() public performanceData: PerformanceReportObjectType[] = [];
  @Input() public reportColumns: IndicatorType[] | undefined = [];
  @Input() public startDate: string | undefined = undefined;
  @Input() public endDate: string | undefined = undefined;

  /** Indicators */
  public indicator: IndicatorType | undefined = undefined;

  /** ChartType */
  public chartTypes: ChartType[] = [];
  public chartType: ChartType = 'line';

  /** ChartData */
  public chartData: ChartConfiguration['data'] = {
    datasets: [],
    labels: [],
  };

  /** Chart Options */
  public chartOptions: ChartOptions = {};

  /**
   * constructor
   */
  constructor() {}

  ngOnInit(): void {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['performanceData'] && changes['performanceData'].currentValue) {
      if (changes['performanceData'].currentValue.length > 0) {
        this.drawUpdate();
      }
    }
    if (changes['reportColumns']) {
      if (
        changes['reportColumns'].currentValue &&
        changes['reportColumns'].currentValue.length > 0
      ) {
        this.indicator = changes['reportColumns'].currentValue[0];
      }
    }
    if (changes['reportGroupType']) {
      this.reportGroupType = changes['reportGroupType'].currentValue;
      if (!this.reportGroupType) {
        return;
      }
      // set Chart Type
      const reportGroupType = this.reportGroupType[0];
      switch (reportGroupType) {
        case GroupingType.Date:
          this.chartType = 'line';
          break;
        default:
          break;
      }
      // setting chart options
      this.setChartOptions();
    }
  }

  /**
   * drawUpdate
   * Aggregate graph data and update the chart
   */
  public drawUpdate(): void {
    this.chartData.datasets = [];
    if (!this.reportGroupType) {
      return;
    }
    // set group
    const firstGroup = this.reportGroupType[0];
    switch (firstGroup) {
      case GroupingType.Date:
        // set child groups
        const childGroups = this.reportGroupType.filter(
          (reportGroup) => reportGroup !== GroupingType.Date
        );
        this.groupingDate(childGroups);
        break;
      // If you need another first group, add case.
      default:
        break;
    }
    // draw
    this.chart?.update();
  }

  /**
   * setChartOptions
   */
  private setChartOptions(): void {
    switch (this.chartType) {
      case 'line':
        this.chartOptions = {
          indexAxis: 'x',
          plugins: {
            legend: {
              display: true,
              labels: {
                boxHeight: 2,
                boxWidth: 2,
                font: {
                  size: 10,
                },
              },
            },
          },
        };
        break;
      // Add graph types according to usage
      default:
        this.chartOptions = {};
        break;
    }
  }

  /**
   * Grouping by Date
   * @param childGroups GroupingType[]
   */
  private groupingDate(childGroups: GroupingType[]): void {
    if (!this.indicator) {
      return;
    }
    // xAxis is Date
    let xAxesLabel: string[] = [];
    if (this.startDate && this.endDate) {
      // Set date range
      const startDate = moment(new Date(this.startDate));
      const endDate = moment(new Date(this.endDate));
      // Generate xAxis from report condition period.
      while (startDate.isSameOrBefore(endDate, 'day')) {
        xAxesLabel.push(startDate.format('YYYY-MM-DD'));
        startDate.add(1, 'days');
      }
    }
    // set indicator key
    const indicatorKey = this.convertIndicatorToObjectkey(this.indicator);
    if (childGroups.length > 0) {
      // Get the final group to aggregate data on the final group
      const latChildGroups = childGroups.slice(-1)[0];

      /** Date + Advertiser */
      if (latChildGroups === GroupingType.Advertiser) {
        // get advertiserIds
        const advertiserIds = Array.from(new Set(this.performanceData.map((p) => p.advertiserId)));
        advertiserIds.forEach((advertiserId) => {
          // dataSet
          const graphData: number[] = [];
          let label = '';
          // Dataset by date
          xAxesLabel.forEach((date) => {
            const rec = this.performanceData.filter((p) => {
              return advertiserId === p.advertiserId && date === p.date;
            });
            if (rec.length > 0 && advertiserId === rec[0].advertiserId) {
              graphData.push(rec[0][indicatorKey] ? (rec[0][indicatorKey] as number) : 0);
              label = rec[0].advertiserName as string;
            } else {
              graphData.push(0);
            }
          });
          // Add DataSets
          const dataSet: ChartDataset = {
            data: graphData,
            label: label,
          };
          this.chartData.datasets.push(dataSet);
        });
      }
      /** Date + Campaign */
      if (latChildGroups === GroupingType.Campaign) {
        // get campaignIds
        const campaignIds = Array.from(new Set(this.performanceData.map((p) => p.campaignId)));
        campaignIds.forEach((campaignId) => {
          // dataSet
          const graphData: number[] = [];
          let label = '';
          // Dataset by date
          xAxesLabel.forEach((date) => {
            const rec = this.performanceData.filter((p) => {
              return campaignId === p.campaignId && date === p.date;
            });
            if (rec.length > 0 && campaignId === rec[0].campaignId) {
              graphData.push(rec[0][indicatorKey] ? (rec[0][indicatorKey] as number) : 0);
              label = rec[0].campaignName as string;
            } else {
              graphData.push(0);
            }
          });
          // Add DataSets
          const dataSet: ChartDataset = {
            data: graphData,
            label: label,
          };
          this.chartData.datasets.push(dataSet);
        });
      }
      /** Date + Flight */
      if (latChildGroups === GroupingType.Flight) {
        // get flightIds
        const flightIds = Array.from(new Set(this.performanceData.map((p) => p.flightId)));
        flightIds.forEach((flightId) => {
          // dataSet
          const graphData: number[] = [];
          let label = '';
          // Dataset by date
          xAxesLabel.forEach((date) => {
            const rec = this.performanceData.filter((p) => {
              return flightId === p.flightId && date === p.date;
            });
            if (rec.length > 0) {
              graphData.push(rec[0][indicatorKey] ? (rec[0][indicatorKey] as number) : 0);
              label = rec[0].flightName as string;
            } else {
              graphData.push(0);
            }
          });
          // Add DataSets
          const dataSet: ChartDataset = {
            data: graphData,
            label: label,
          };
          this.chartData.datasets.push(dataSet);
        });
      }
    } else {
      // Group is Date Only.
      // Dataset by date
      const graphData: number[] = [];
      // Dataset by date
      xAxesLabel.forEach((date) => {
        const result = this.performanceData.filter((value) => {
          return date === value.date;
        });
        if (result.length > 0) {
          graphData.push(result[0][indicatorKey] ? (result[0][indicatorKey] as number) : 0);
        } else {
          graphData.push(0);
        }
      });
      // Add DataSets
      const dataSet: ChartDataset = {
        data: graphData,
        label: this.indicator,
      };
      this.chartData.datasets.push(dataSet);
    }
    // Add Labels
    this.chartData.labels = xAxesLabel;
  }

  /**
   * convert Indicator To Objectkey
   * @param indicator
   * @returns
   */
  private convertIndicatorToObjectkey(indicator: IndicatorType): keyof PerformanceReportObjectType {
    const isUpperCase = (str: string) => {
      const arr = str.match(/^[A-Z]*$/);
      return arr ? true : false;
    };
    if (isUpperCase(indicator)) {
      return indicator.toLowerCase() as keyof PerformanceReportObjectType;
    } else {
      return (indicator.charAt(0).toLowerCase() +
        indicator.slice(1)) as keyof PerformanceReportObjectType;
    }
  }
}
