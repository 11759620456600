import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserEditComponent, UserEditParam } from '../user-edit/user-edit.component';
import { UserService } from 'src/app/services/users/user.service';
import { UserProfileService } from 'src/app/core/user-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AdvertiserObjectType,
  AdvertiserObjectTypeConnection,
  QueryUsersArgs,
  UserObjectType,
  UserRole,
} from 'src/app/models/graphql/types';
import { excerptErrorMessage, getDecodeId } from 'src/app/resource/utility/common-util';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AdvertiserService } from 'src/app/services/advertisers/advertiser.service';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import { Feature } from 'src/app/resource/feature-role';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  /** MatTable */
  public displayedColumns: string[] = [
    'id',
    'isActive',
    'name',
    'email',
    'role',
    'advertiser',
    'edit',
  ];
  public userList: MatTableDataSource<UserObjectType> = new MatTableDataSource();

  /** Pager */
  public pageLength: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 30;
  /** SortKey */
  public sortKey: string | undefined = undefined;

  /** FilterFunction Enabled */
  public isFilterFunctionEnabled = false;
  /** FormFilterGroup */
  public formFilterGroup = this._formBuilder.group({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    role: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    advertiser: new UntypedFormControl(''),
  });

  /** Advertisers info */
  public advertiserList: Pick<AdvertiserObjectType, 'id' | 'name'>[] = [];

  /** Role info */
  public roleItems: UserRole[] = [UserRole.Admin, UserRole.Editor, UserRole.Staff];
  /** Status Types */
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /**
   * constructor
   * @param _dialog
   * @param _formBuilder
   * @param _snackBar
   * @param userProfileService
   * @param userService
   * @param advertiserService
   * @param spinnerService
   * @param activeRoute
   * @param router
   */
  constructor(
    private _dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private userProfileService: UserProfileService,
    private userService: UserService,
    private advertiserService: AdvertiserService,
    private spinnerService: SpinnerService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      // Parameter Set.
      this.pageSize = Number(params['size']) >= 0 ? Number(params['size']) : this.pageSize;
      this.pageIndex = Number(params['page']) >= 0 ? Number(params['page']) : this.pageIndex;
      this.sortKey = params['sort'] ? params['sort'] : undefined;
      this.name.setValue(params['name'] ? params['name'] : '');
      this.email.setValue(params['email'] ? params['email'] : '');
      this.role.setValue(params['role'] ? params['role'] : '');
      this.advertiser.setValue(params['advertiser'] ? params['advertiser'] : '');
      this.status.setValue(params['status'] ? params['status'] === 'true' : '');

      // create UserList
      this.createUserList();
      // });
    });
  }

  ngOnInit(): void {
    // get advertisers
    this.getAdvertisers();
    return;
  }

  ngOnDestroy(): void {
    this._dialog.closeAll();
  }

  /**
   * Get Advertisers
   */
  private async getAdvertisers() {
    await firstValueFrom(this.advertiserService.getAdvertisers()).then(
      (res) => {
        const advertisers: AdvertiserObjectType[] = [];
        res.data.advertisers?.edges.forEach((advertiser) => {
          if (advertiser && advertiser.node) {
            advertisers.push(advertiser.node);
          }
        });
        if (advertisers) {
          this.advertiserList = advertisers as AdvertiserObjectType[];
        }
      },
      (error: Error) => {
        // nop
      }
    );
  }

  /**
   * create UserList
   */
  private createUserList(): void {
    if (this.userProfileService.isAvailableFeature(Feature.getUser)) {
      // Get Users.
      this.getUsers();
    }
  }

  /**
   * Get Users
   */
  private getUsers(): void {
    this.spinnerService.show();
    this.userList = new MatTableDataSource();
    // create variables
    const variables: QueryUsersArgs = {
      offset: this.pageIndex * this.pageSize,
      first: this.pageSize,
      name: this.name.value,
      email: this.email.value,
      role: this.role.value === '' ? undefined : this.role.value,
      isActive: this.status.value === '' ? undefined : this.status.value,
      advertiserName: this.advertiser.value,
      orderBy: this.sortKey,
    };
    // Get Users
    firstValueFrom(this.userService.getUsers(variables)).then(
      (res) => {
        const users = res.data.users;
        const userList: UserObjectType[] = [];
        if (users) {
          users.edges.forEach((user) => {
            if (user && user.node) {
              userList.push(user.node);
            }
          });
          this.userList = new MatTableDataSource(userList);
          // set pageInfo
          if (users.edgeCount === 0) {
            this.pageIndex = 0;
            this.pageLength = 0;
          } else {
            this.pageLength = users.totalCount;
          }
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Click New
   */
  public onAddUser(): void {
    const editForm: UserEditParam = {
      id: null,
    };
    this._dialog
      .open(UserEditComponent, {
        data: editForm,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * Click Edit
   * @param element UserObjectType
   */
  public onEditUser(element: UserObjectType): void {
    const editForm: UserEditParam = {
      id: element.id,
    };
    this._dialog
      .open(UserEditComponent, {
        data: editForm,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * Click Refresh
   */
  public onRefresh(): void {
    this.createUserList();
  }
  /**
   * Apply Filter
   */
  public applyFilter(): void {
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }
  /**
   * Click Reset
   */
  public onFilterReset(): void {
    if (
      this.name.value === '' &&
      this.email.value === '' &&
      this.role.value === '' &&
      this.status.value === '' &&
      this.advertiser.value === ''
    ) {
      // no filer. nop
      return;
    }
    this.formFilterGroup.setValue({
      name: '',
      email: '',
      status: '',
      role: '',
      advertiser: '',
    });
    this.applyFilter();
  }

  /**
   * ColumsSort
   * @param sort
   */
  public sortByColumns(sort: Sort): void {
    let sortKey = sort.active;
    if (sort.direction === 'desc') {
      sortKey = '-' + sortKey;
    }
    this.sortKey = sortKey;
    this.pageIndex = 0;
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * PagingHandleEvent
   * @param event
   */
  public handlePageEvent(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * Update URL Query Parameters
   */
  private updateURLQueryParams(): void {
    // Set SearchCondition to QueryPramas
    this.router.navigate(['.'], {
      relativeTo: this.activeRoute,
      queryParams: {
        page: this.pageIndex,
        size: this.pageSize,
        sort: this.sortKey ? this.sortKey : undefined,
        name: this.name.value ? this.name.value : undefined,
        email: this.email.value ? this.email.value : undefined,
        role: this.role.value ? this.role.value : undefined,
        advertiser: this.advertiser.value ? this.advertiser.value : undefined,
        status: this.status.value !== '' ? this.status.value : undefined,
      },
    });
  }

  /**
   * Get AdvertiserName
   * @param advertisers
   * @returns advertisers.name
   */
  public getAdvertiserName(advertisers: AdvertiserObjectTypeConnection): (string | undefined)[] {
    const result = advertisers.edges.map((ad) => ad?.node?.name);
    return result;
  }

  /**
   * Get DecodeId
   * @param encoded
   * @returns
   */
  public getDecodeId(encoded: string): string | undefined {
    return getDecodeId(encoded);
  }

  /**
   * Get UserId
   * @returns
   */
  public getUserId(): string | null {
    return this.userProfileService.getUserId();
  }

  /**
   * listEnabled
   * @returns
   */
  public listEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.getUser)) {
      ret = true;
    }
    return ret;
  }

  /**
   * registrationButtonEnabled
   * @returns
   */
  public registrationButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.createUser)) {
      ret = true;
    }
    return ret;
  }

  /**
   * editButtonEnabled
   * @returns
   */
  public editButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.editUser)) {
      ret = true;
    }
    return ret;
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.formFilterGroup.get('name') as UntypedFormControl;
  }
  /**
   * email FormControl Getter
   */
  get email(): UntypedFormControl {
    return this.formFilterGroup.get('email') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.formFilterGroup.get('status') as UntypedFormControl;
  }
  /**
   * role FormControl Getter
   */
  get role(): UntypedFormControl {
    return this.formFilterGroup.get('role') as UntypedFormControl;
  }
  /**
   * advertiser FormControl Getter
   */
  get advertiser(): UntypedFormControl {
    return this.formFilterGroup.get('advertiser') as UntypedFormControl;
  }
}
