<div class="container">
  <div class="flex-row -flex" style="height: 100%; padding: 5px; box-sizing: border-box">
    <div class="condition-panel align-start-stretch flex-20" [hidden]="!listEnabled()">
      <mat-card appearance="outlined">
        <mat-card-header class="card-header">
          <mat-card-title class="card-title">Filter</mat-card-title>
        </mat-card-header>
        <mat-divider inset></mat-divider>
        <mat-card-content>
          <form style="padding: 10px 0" [formGroup]="formFilterGroup">
            <mat-form-field class="mat-form" style="width: 100%">
              <mat-label>Name</mat-label>
              <input matInput (keyup.enter)="applyFilter()" formControlName="name" maxlength="64" />
            </mat-form-field>
            <mat-form-field style="width: 100%">
              <mat-label>Email</mat-label>
              <input
                matInput
                (keyup.enter)="applyFilter()"
                formControlName="email"
                maxlength="256"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select (selectionChange)="applyFilter()" formControlName="status">
                <mat-option [value]="''">All</mat-option>
                <mat-option
                  *ngFor="let activeStatusType of activeStatusTypes"
                  [value]="activeStatusType.value"
                  >{{ activeStatusType.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Role</mat-label>
              <mat-select (selectionChange)="applyFilter()" formControlName="role">
                <mat-option [value]="''">All</mat-option>
                <mat-option *ngFor="let role of roleItems" [value]="role">{{ role }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%">
              <mat-label>Advertiser</mat-label>
              <mat-select (selectionChange)="applyFilter()" formControlName="advertiser">
                <mat-option [value]="''"></mat-option>
                <mat-option *ngFor="let ad of advertiserList" [value]="ad.name">{{
                  ad.name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="basic" style="width: 80px" (click)="onFilterReset()">
            Reset
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="contents-panel align-start-stretch">
      <mat-card class="-block" appearance="outlined">
        <mat-card-header class="card-header flex-row">
          <div class="align-start-center flex-row flex-1-0-auto -flex">
            <mat-card-title>Users</mat-card-title>
          </div>
          <div class="align-start-center flex-row flex-0-1-auto -flex">
            <button
              mat-flat-button
              color="primary"
              *ngIf="registrationButtonEnabled()"
              (click)="onAddUser()"
            >
              <mat-icon>add</mat-icon>New User
            </button>
            <div style="padding-right: 30px"></div>
            <button mat-stroked-button color="basic" (click)="onRefresh()">
              <mat-icon>refresh</mat-icon>Refresh
            </button>
          </div>
        </mat-card-header>
        <mat-card-content style="width: 100%; height: 100%; display: inline">
          <mat-paginator
            (page)="handlePageEvent($event)"
            [length]="pageLength"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[10, 30, 50, 100]"
            showFirstLastButtons="true"
            aria-label="Select page"
          >
          </mat-paginator>
          <div class="table-container">
            <table
              mat-table
              [dataSource]="userList"
              matSort
              (matSortChange)="sortByColumns($event)"
              style="width: 100%; table-layout: fixed"
            >
              <ng-container matColumnDef="isActive">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                  style="width: 8%"
                >
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.isActive">
                    <span class="user-status-active">Active</span>
                  </ng-container>
                  <ng-container *ngIf="!element.isActive">
                    <span class="user-status-inactive">Inactive</span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="id">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                  style="width: 7%"
                >
                  ID
                </th>
                <td mat-cell *matCellDef="let element">{{ getDecodeId(element.id) }}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                  style="width: 20%"
                >
                  Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                  style="width: 25%"
                >
                  Email
                </th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  disableClear
                  style="width: 10%"
                >
                  Role
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.role }}
                </td>
              </ng-container>
              <ng-container matColumnDef="advertiser">
                <th mat-header-cell *matHeaderCellDef style="width: 25%">Advertiser</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let ad of getAdvertiserName(element.advertisers)">
                    <span style="padding: 5px">{{ ad }}</span>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef style="width: 5%"></th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="editButtonEnabled()">
                    <button mat-icon-button color="primary" (click)="onEditUser(element)">
                      <mat-icon style="font-size: 19px">edit</mat-icon>
                    </button>
                  </ng-container>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
                style="background: #f6f8fa"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
