import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { MaterialModule } from './material.module';
import { AuthModule } from './auth/auth.module';
import { CommonLayoutModule } from './common-layout/common-layout.module';
import { ReportsModule } from './reports/reports.module';
import { UserSettingModule } from './user-setting/user-setting.module';
import { DirectivesModule } from './directives/directives.module';

import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { AdvertisersModule } from './advertisers/advertisers.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { FlightsModule } from './flights/flights.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    CommonLayoutModule,
    MaterialModule,
    AuthModule,
    UserSettingModule,
    ReportsModule,
    GraphQLModule,
    AdvertisersModule,
    CampaignsModule,
    FlightsModule,
    DirectivesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
