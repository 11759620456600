import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import {
  CampaignTypesForm,
  CpTypeNormal2D,
  CpTypePremiumAdserver,
  CpTypePremiumEmbedded,
} from 'src/app/models/campaign';
import {
  CampaignAdType,
  CampaignBillingType,
  CampaignDeliveryType,
  CampaignInputObjectType,
} from 'src/app/models/graphql/types';
import {
  convertStringToNumber,
  excerptErrorMessage,
  REGEX_CAMPAIGN_NAME,
} from 'src/app/resource/utility/common-util';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';

/** Modal Parameter */
export interface ModalCreateParam {
  advertiserId: string;
}

@Component({
  selector: 'app-campaign-registration',
  templateUrl: './campaign-registration.component.html',
  styleUrls: ['./campaign-registration.component.scss'],
})
export class CampaignRegistrationComponent implements OnInit {
  /** Title */
  public formTitle = 'New Campaign';

  /** CampaignType */
  public campaignType: CampaignTypesForm = {
    dailyCostAmount: false,
    lifetimeCostAmount: false,
    isActive: false,
  };

  /** billingTypes */
  public billingTypes: CampaignBillingType[] = [
    CampaignBillingType.Normal,
    CampaignBillingType.Premium,
  ];
  /** adTypes */
  public adTypes: string[] = [];
  /** deliveryTypes */
  public deliveryTypes: string[] = [];
  /** Status Types*/
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /** CampaignGeneralFormGroup */
  public campaignGeneralFormGroup = this._formBuilder.group({
    billingType: new UntypedFormControl('', [Validators.required]),
    adType: new UntypedFormControl('', [Validators.required]),
    deliveryType: new UntypedFormControl('', [Validators.required]),
  });
  /** CampaignFormGroup */
  public campaignFormGroup = this._formBuilder.group({
    friendlyName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_CAMPAIGN_NAME),
    ]),
    campaignName: new UntypedFormControl(''),
    dailyCostAmount: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    lifetimeCostAmount: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    status: new UntypedFormControl(true, [Validators.required]),
  });

  public isSubmitting = false;

  /**
   * Contructor
   * @param createModalParam
   * @param _formBuilder
   * @param _dialogRef
   * @param _snackBar
   * @param spinnerService
   * @param campaignService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalCreateParam: ModalCreateParam,
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<CampaignRegistrationComponent>,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.campaignFormGroup.controls['campaignName'].disable();
    this.campaignFormGroup.controls['status'].disable();
    this.campaignFormGroup.controls['status'].setValue(true);
  }

  /**
   * StepperSelectionChange
   */
  public selectionChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 1) {
      this.onNext();
    }
  }

  /**
   * Click Next
   * @returns
   */
  public onNext(): void {
    if (this.campaignGeneralFormGroup.valid) {
      // CampaignForm Reset
      this.resetCampaignForm();
      // CampaignName AutoFill
      this.campaignFormGroup.controls['campaignName'].setValue(this.createCampaignName());
      // Create CampaignForm by CampaignType
      if (
        this.billingType.value === CampaignBillingType.Normal &&
        this.adType.value === CampaignAdType.Type2D &&
        this.deliveryType.value === CampaignDeliveryType.AdServer
      ) {
        this.campaignType = CpTypeNormal2D;
        return;
      }
      if (
        this.billingType.value === CampaignBillingType.Premium &&
        this.deliveryType.value === CampaignDeliveryType.Embedded
      ) {
        this.campaignType = CpTypePremiumEmbedded;
        return;
      }
      if (
        this.billingType.value === CampaignBillingType.Premium &&
        this.deliveryType.value === CampaignDeliveryType.AdServer
      ) {
        this.campaignType = CpTypePremiumAdserver;
        return;
      }
    }
  }

  /**
   * click Registration
   */
  public async onRegistration(): Promise<void> {
    if (this.campaignFormGroup.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.spinnerService.show();

      // Create Variable
      const variable: CampaignInputObjectType = {
        name: this.createCampaignName(),
        billingType: this.billingType.value,
        adType: this.adType.value,
        deliveryType: this.deliveryType.value,
        dailyCostAmount: convertStringToNumber(this.dailyCostAmount.value),
        lifetimeCostAmount: convertStringToNumber(this.lifetimeCostAmount.value),
        advertiserId: this.modalCreateParam.advertiserId,
        isActive: this.status.value,
      };

      try {
        // createCampaign
        const res = await firstValueFrom(this.campaignService.createCampaign(variable));

        if (res.data?.campaignCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Campaign registration failed.');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.showToaster(excerptErrorMessage(error.message));
        }
      } finally {
        this.spinnerService.hide();
        this.isSubmitting = false;
      }
    }
  }

  /**
   * click Cancel
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * BillingType Change Event
   */
  public onChangeBillingType(): void {
    switch (this.billingType.value) {
      case CampaignBillingType.Normal:
        this.adTypes = [CampaignAdType.Type2D];
        this.adType.setValue(CampaignAdType.Type2D);
        this.deliveryTypes = [CampaignDeliveryType.AdServer];
        this.deliveryType.setValue(CampaignDeliveryType.AdServer);
        break;
      case CampaignBillingType.Premium:
        this.adTypes = [CampaignAdType.Type2D, CampaignAdType.Type3D];
        this.adType.setValue('');
        this.deliveryTypes = [CampaignDeliveryType.AdServer, CampaignDeliveryType.Embedded];
        this.deliveryType.setValue('');
        break;
    }
  }

  /**
   * adType ChangeEvent
   */
  public onChangeAdType(): void {
    // Normal 2D Embedded only
    if (this.billingType.value === CampaignBillingType.Normal) {
      this.deliveryTypes = [CampaignDeliveryType.AdServer];
      this.deliveryType.setValue(CampaignDeliveryType.AdServer);
      return;
    } else if (this.billingType.value === CampaignBillingType.Premium) {
      // Premium 2D or 3D
      switch (this.adType.value) {
        case CampaignAdType.Type2D:
          this.deliveryType.setValue('');
          this.deliveryTypes = [CampaignDeliveryType.AdServer, CampaignDeliveryType.Embedded];
          break;
        case CampaignAdType.Type3D:
          this.deliveryType.setValue('');
          this.deliveryTypes = [CampaignDeliveryType.Embedded];
          break;
      }
    }
  }

  /** Rest CampaignForm  */
  private resetCampaignForm(): void {
    this.campaignFormGroup.patchValue({
      friendlyName: '',
      dailyCostAmount: '',
      lifetimeCostAmount: '',
    });
  }

  /**
   * show message
   * @param message
   * @param button
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * convertToAdTypeLabel
   * @param adType CampaignAdType
   * @returns '2D' | '3D'
   */
  public convertToAdTypeLabel(adType: CampaignAdType): string {
    let ret = '';
    if (CampaignAdType.Type2D === adType) {
      ret = '2D';
    } else if (CampaignAdType.Type3D === adType) {
      ret = '3D';
    }
    return ret;
  }

  /**
   * toLowerCase
   * @param value
   * @returns
   */
  public toLowerCase(value: string): string {
    return value.toLowerCase();
  }

  /**
   * campaignName autofill
   * @returns
   */
  public createCampaignName(): string {
    let ret = '';
    ret += `${this.convertToAdTypeLabel(this.adType.value)}_`;
    ret += `${this.toLowerCase(this.billingType.value)}_`;
    ret += `${this.toLowerCase(this.deliveryType.value)}_`;
    ret += this.friendlyName.value;
    return ret;
  }

  /**
   * billingType FormControl Getter
   */
  get billingType(): UntypedFormControl {
    return this.campaignGeneralFormGroup.get('billingType') as UntypedFormControl;
  }
  /**
   * adType FormControl Getter
   */
  get adType(): UntypedFormControl {
    return this.campaignGeneralFormGroup.get('adType') as UntypedFormControl;
  }
  /**
   * deliveryType FormControl Getter
   */
  get deliveryType(): UntypedFormControl {
    return this.campaignGeneralFormGroup.get('deliveryType') as UntypedFormControl;
  }
  /**
   * friendlyName FormControl Getter
   */
  get friendlyName(): UntypedFormControl {
    return this.campaignFormGroup.get('friendlyName') as UntypedFormControl;
  }
  /**
   * dailyCostAmount FormControl Getter
   */
  get dailyCostAmount(): UntypedFormControl {
    return this.campaignFormGroup.get('dailyCostAmount') as UntypedFormControl;
  }
  /**
   * lifetimeCostAmount FormControl Getter
   */
  get lifetimeCostAmount(): UntypedFormControl {
    return this.campaignFormGroup.get('lifetimeCostAmount') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.campaignFormGroup.get('status') as UntypedFormControl;
  }
}
