import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { UserProfileService } from 'src/app/core/user-profile.service';
import { TokenRefreshInputObjectType } from 'src/app/models/graphql/types';
import { LoginService } from 'src/app/services/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * constructor
   * @param loginService
   * @param userProfileService
   * @param spinnerService
   */
  constructor(
    private userProfileService: UserProfileService,
    private loginService: LoginService,
    private spinnerService: SpinnerService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.userProfileService.getToken();
    if (!token) {
      // logout, if Token is Null.
      this.userProfileService.logout();
      return false;
    }
    if (this.userProfileService.refreshTokenExpired(token)) {
      // Logout if RefreshToken has expired
      this.userProfileService.logout();
      return false;
    }
    if (this.userProfileService.tokenExpired(token)) {
      /** Refresh, if the token has expired. */
      this.getRefreshToken().then((newToken) => {
        if (!newToken) {
          this.userProfileService.logout();
          return false;
        }
        if (!this.userProfileService.tokenDecoded(newToken)) {
          this.userProfileService.logout();
          return false;
        }
        // AuthorityAccess check
        if (!this.isAuthorityAccessChecked(next)) {
          // Access block. permissions and page conflict
          this.userProfileService.logout();
          return false;
        }
        return true;
      });
    }

    if (!this.userProfileService.isAuthenticated()) {
      // logout, if token decode failure.
      this.userProfileService.logout();
      return false;
    }
    // AuthorityAccess check
    if (!this.isAuthorityAccessChecked(next)) {
      // Access block. permissions and page conflict
      this.userProfileService.logout();
      return false;
    }
    return true;
  }

  /**
   * isAuthorityAccessChecked
   * @param route
   * @returns
   */
  private isAuthorityAccessChecked(route: ActivatedRouteSnapshot): boolean {
    const menu = this.userProfileService.getFeatureGroup().filter((fg) => {
      return fg === route.data['title'];
    });
    if (menu.length == 0) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * getRefreshToken
   */
  private async getRefreshToken(): Promise<string | null> {
    // Lock Refreshing
    this.spinnerService.show();
    this.userProfileService.setRefreshing(true);
    // Create Request
    const refreshToken = this.userProfileService.getRefreshToken();
    const request: TokenRefreshInputObjectType = {
      refreshToken: refreshToken || '',
    };
    // tokenRefresh
    const refreshResolverResponse = await firstValueFrom(this.loginService.tokenRefresh(request))
      .then((res) => {
        if (res.data && res.data.tokenRefresh) {
          this.userProfileService.setToken(
            res.data.tokenRefresh.token,
            res.data.tokenRefresh.refreshToken
          );
          return res.data.tokenRefresh.token;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return null;
      })
      .finally(() => {
        // unLock Refreshing
        this.userProfileService.setRefreshing(false);
        this.spinnerService.hide();
      });
    return refreshResolverResponse;
  }
}
