import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public isLoading = new Subject<boolean>();

  constructor() {}

  /**
   * Show spinner
   *
   * @memberof SpinnerService
   */
  public show(): void {
    this.isLoading.next(true);
  }

  /**
   * Hide spinner
   *
   * @memberof SpinnerService
   */
  public hide(): void {
    this.isLoading.next(false);
  }
}
