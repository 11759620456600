import { gql } from 'apollo-angular';

export const QueryGetUser = gql`
  query user($id: ID!) {
    user(pk: $id) {
      id
      name
      email
      role
      isActive
      advertisers {
        edges {
          node {
            id
            name
            isActive
          }
        }
      }
      agentLoggedIn
    }
  }
`;
