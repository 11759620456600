<div class="container">
  <div class="container-wrapper">
    <mat-card appearance="outlined" style="width: 480px; padding: 16px">
      <ng-container *ngIf="!isInitialRegistration; else creation">
        <mat-card-header class="card-header">
          <mat-card-title class="card-title">Password Reset</mat-card-title>
          <mat-card-subtitle>
            Please enter your registered email address below. A password reset email will be sent to
            the registered email address, so please register your password again.
          </mat-card-subtitle>
        </mat-card-header>
      </ng-container>
      <ng-template #creation>
        <mat-card-header class="card-header">
          <mat-card-title class="card-title">Password Creation</mat-card-title>
          <mat-card-subtitle>
            Please enter your registered email address below. A password creation email will be sent
            to the registered email address, so please register your password again.
          </mat-card-subtitle>
        </mat-card-header>
      </ng-template>
      <mat-card-content class="card-contents">
        <form class="reset-form" [formGroup]="passwordResetForm" autocomplete="off">
          <mat-form-field class="form-item-feild">
            <mat-label for="email">Email</mat-label>
            <input matInput type="text" formControlName="email" maxlength="256" required />
            <mat-error *ngIf="email.errors?.['required']">Email is required.</mat-error>
            <mat-error *ngIf="email.errors?.['pattern'] || email.errors?.['email']"
              >Invalid Email format.</mat-error
            >
          </mat-form-field>
        </form>
        <mat-error *ngFor="let err of errors">{{ err }}</mat-error>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <div class="btn-form">
          <button
            type="button"
            mat-raised-button
            class="btn"
            color="primary"
            [disabled]="passwordResetForm.invalid"
            (click)="send()"
          >
            Send
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
