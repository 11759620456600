import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, PageProfileService } from 'src/app/core/page-profile.service';
import { UserProfileService } from 'src/app/core/user-profile.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  /** Menu */
  public menus: Menu[] = [];

  /**
   * constructor
   * @param userProfileService
   * @param pageProfileService
   * @param router
   */
  constructor(
    private userProfileService: UserProfileService,
    private pageProfileService: PageProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userProfileService.getFeatureMenu().subscribe((featureGroup) => {
      const menu = this.pageProfileService.getMenu();
      const enabledMenus = menu.filter((menu) => {
        if ((featureGroup as string[]).indexOf(menu.name) >= 0) {
          return menu;
        }
        return;
      });
      this.menus = enabledMenus;
    });
  }

  /** SelectMenu */
  public onMenuClick(page: string): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([page]));
  }
}
