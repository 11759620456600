import { UserRole } from '../models/graphql/types';

/**
 * FeatureRole Class
 */
export class FeatureRole {
  /**
   * Get Feature
   * Return individual Features available by user role
   * @param userRole
   * @returns
   */
  static getFeatures(userRole: string | undefined): Feature[] {
    const featureRole = featureRoles.filter((f) => {
      return f.userRole === userRole;
    });
    if (featureRole.length <= 0) {
      return [];
    }
    return featureRole[0].features;
  }
  /**
   * Get FeatureGroups
   * Return FeatureGroups available by user role
   * @param userRole
   * @returns
   */
  static getFeatureGroups(userRole: string | undefined): FeatureGroup[] {
    const featureRole = featureRoles.filter((f) => {
      return f.userRole === userRole;
    });
    if (featureRole.length <= 0) {
      return [FeatureGroup.Users, FeatureGroup.Reports];
    }
    return featureRole[0].featureGroups;
  }
  /**
   * Get getDisplayMenus
   * Return FeatureMenus available by user role
   * @param userRole
   * @returns
   */
  static getDisplayMenus(userRole: string | undefined): FeatureGroup[] {
    const featureRole = featureRoles.filter((f) => {
      return f.userRole === userRole;
    });
    if (featureRole.length <= 0) {
      return [FeatureGroup.Users, FeatureGroup.Reports];
    }
    return featureRole[0].displayMenus;
  }
}

/**
 * FeatureRole interface
 *  userRole: userRole for AgentSubsystem
 *  displayMenus: Features displayed in the menu
 *  featureGroups: Features to allow access
 *  features: Available features
 */
export interface FeatureRole {
  userRole: UserRole;
  displayMenus: FeatureGroup[];
  featureGroups: FeatureGroup[];
  features: Feature[];
}

/**
 * FeatureGroup type
 */
export enum FeatureGroup {
  Users = 'Users',
  Advertisers = 'Advertisers',
  Reports = 'Reports',
  Campaigns = 'Campaigns',
  Flights = 'Flights',
}

/**
 * Feature type
 */
export enum Feature {
  /** create */
  createCampaign = 'createCampaign',
  createFlight = 'createFlight',
  createCreative = 'createCreative',
  createUser = 'createUser',
  createAdvertiser = 'createAdvertiser',
  /** edit */
  editCampaign = 'editCampaign',
  editFlight = 'editFlight',
  editCreative = 'editCreative',
  editUser = 'editUser',
  editAdvertiser = 'editAdvertiser',
  /** get */
  getCampaign = 'getCampaign',
  getFlight = 'getFlight',
  getCreative = 'getCreative',
  getUser = 'getUser',
  getAdvertiser = 'getAdvertiser',
}

/** featureRoles */
const featureRoles: FeatureRole[] = [
  {
    userRole: UserRole.Admin,
    displayMenus: [FeatureGroup.Users, FeatureGroup.Advertisers, FeatureGroup.Reports],
    featureGroups: [
      FeatureGroup.Users,
      FeatureGroup.Advertisers,
      FeatureGroup.Reports,
      FeatureGroup.Campaigns,
      FeatureGroup.Flights,
    ],
    features: [
      /** create */
      Feature.createAdvertiser,
      Feature.createCampaign,
      Feature.createFlight,
      Feature.createCreative,
      Feature.createUser,
      /** edit */
      Feature.editAdvertiser,
      Feature.editCampaign,
      Feature.editFlight,
      Feature.editCreative,
      Feature.editUser,
      /** get */
      Feature.getAdvertiser,
      Feature.getCampaign,
      Feature.getFlight,
      Feature.getCreative,
      Feature.getUser,
    ],
  },
  {
    userRole: UserRole.Staff,
    displayMenus: [FeatureGroup.Users, FeatureGroup.Advertisers, FeatureGroup.Reports],
    featureGroups: [
      FeatureGroup.Users,
      FeatureGroup.Advertisers,
      FeatureGroup.Reports,
      FeatureGroup.Campaigns,
      FeatureGroup.Flights,
    ],
    features: [
      /** create */
      Feature.createAdvertiser,
      Feature.createCampaign,
      Feature.createFlight,
      Feature.createCreative,
      /** edit */
      Feature.editAdvertiser,
      Feature.editCampaign,
      Feature.editFlight,
      Feature.editCreative,
      /** get */
      Feature.getAdvertiser,
      Feature.getCampaign,
      Feature.getFlight,
      Feature.getCreative,
      Feature.getUser,
    ],
  },
  {
    userRole: UserRole.Editor,
    displayMenus: [FeatureGroup.Users, FeatureGroup.Reports],
    featureGroups: [FeatureGroup.Users, FeatureGroup.Reports],
    features: [
      /** get */
      Feature.getUser,
    ],
  },
];
