import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MaterialModule } from '../material.module';
import { PasswordRegistrationComponent } from './password-registration/password-registration.component';
import { PasswordRegistrationCompletedComponent } from './password-registration-completed/password-registration-completed.component';
import { LoginOtpAuthComponent } from './login-otp-auth/login-otp-auth.component';
import { LoginService } from 'src/app/services/login/login.service';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    PasswordRegistrationComponent,
    PasswordRegistrationCompletedComponent,
    LoginOtpAuthComponent,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [LoginComponent, PasswordResetComponent],
  providers: [LoginService],
})
export class AuthModule {}
