import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { PasswordUpdateInputObjectType } from 'src/app/models/graphql/types';
import { excerptErrorMessage, REGEX_PWD } from 'src/app/resource/utility/common-util';
import { PasswordChangeService } from 'src/app/services/password-change/password-change.service';

@Component({
  selector: 'app-password-registration',
  templateUrl: './password-registration.component.html',
  styleUrls: ['./password-registration.component.scss'],
})
export class PasswordRegistrationComponent implements OnInit {
  /** Password FormGroup */
  public passwordForm = this._formBuilder.group(
    {
      password: new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX_PWD)]),
      passwordConfirm: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(REGEX_PWD),
      ]),
    },
    {
      validators: confirmedValidator('password', 'passwordConfirm'),
    }
  );

  /** Visible ON/OFF */
  public btnPassVisible: boolean = true;
  public btnConfirmPassVisible: boolean = true;

  /**
   * Constructor
   * @param _formBuilder
   * @param _router
   * @param passwordChangeService
   * @param spinnerService
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private passwordChangeService: PasswordChangeService,
    private spinnerService: SpinnerService
  ) {}
  ngOnInit(): void {
    return;
  }

  /**
   * Password Update
   */
  public async passwordUpdate() {
    if (!this.passwordForm.invalid) {
      this.spinnerService.show();
      // Input
      const input: PasswordUpdateInputObjectType = {
        password: this.password.value,
      };
      // call passwordUpdate
      await firstValueFrom(this.passwordChangeService.passwordUpdate(input)).then(
        (res) => {
          const response = res.data?.passwordUpdate;
          if (response) {
            // go to compoleted screen
            this._router.navigateByUrl('/password_registration_completed');
          } else {
            this._snackBar.open('Password change failed.', 'close', {
              verticalPosition: 'top',
            });
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this._snackBar.open(excerptErrorMessage(error.message), 'close', {
            verticalPosition: 'top',
          });
        }
      );
    }
  }

  /**
   * password FormControl Getter
   */
  get password(): UntypedFormControl {
    return this.passwordForm.get('password') as UntypedFormControl;
  }
  /**
   * passwordConfirm FormControl Getter
   */
  get passwordConfirm(): UntypedFormControl {
    return this.passwordForm.get('passwordConfirm') as UntypedFormControl;
  }
}

/**
 * confirmedValidator
 * @param controlName
 * @param matchingControlName
 * @returns
 */
export function confirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
