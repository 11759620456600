<div class="container">
  <div class="container-wrapper">
    <mat-card appearance="outlined" style="padding: 16px">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">Sign In</mat-card-title>
        <mat-card-subtitle
          >Please sign in use Email address and password registered</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content class="card-content">
        <form class="login-form" [formGroup]="loginForm" autocomplete="off">
          <mat-form-field class="form-item-feild">
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" maxlength="256" required />
            <mat-error *ngIf="email.errors?.['required']">Email is required.</mat-error>
            <mat-error *ngIf="email.errors?.['pattern'] || email.errors?.['email']"
              >Invalid Email format.</mat-error
            >
          </mat-form-field>
          <mat-form-field class="form-item-feild">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              [type]="btnPassVisible ? 'password' : 'text'"
              minlength="8"
              maxlength="64"
              formControlName="password"
              required
            />
            <mat-icon class="pointer" matSuffix (click)="btnPassVisible = !btnPassVisible">{{
              btnPassVisible ? 'visibility' : 'visibility_off'
            }}</mat-icon>
            <mat-error *ngIf="password.errors?.['required']">Password is required.</mat-error>
            <mat-error *ngIf="password.errors?.['pattern']">Invalid Password format.</mat-error>
          </mat-form-field>
          <mat-error *ngFor="let err of errors">{{ err }}</mat-error>
          <div class="btn-form">
            <button
              type="button"
              mat-raised-button
              class="btn"
              color="primary"
              [disabled]="loginForm.invalid"
              (click)="login()"
            >
              Sign In
            </button>
          </div>
        </form>
      </mat-card-content>
      <mat-card-content class="link">
        <a href="/password_reset">Forgot Password?</a>
      </mat-card-content>
    </mat-card>
  </div>
</div>
