import { gql } from 'apollo-angular';

export const QueryGetFlights = gql`
  query flights(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $isActive: Boolean
    $search: String
    $orderBy: String
    $campaignId: String
  ) {
    flights(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      isActive: $isActive
      search: $search
      orderBy: $orderBy
      campaignId: $campaignId
    ) {
      edges {
        node {
          id
          name
          isActive
          price
          startDate
          endDate
          dailyCapAmount
          goalAmount
          creativeType
          goalType
          rateType
          campaign {
            id
            name
            billingType
            adType
            deliveryType
            isActive
          }
          adSize {
            id
            name
            width
            height
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;
