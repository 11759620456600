<div class="mat-elevation-z2">
  <table mat-table id="report-table" [dataSource]="summaryReportTables.data" style="width: 100%">
    <ng-container matColumnDef="Advertiser">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Advertiser</th>
      <td mat-cell *matCellDef="let element" class="cell-left">{{ element.advertiserName }}</td>
    </ng-container>
    <ng-container matColumnDef="Campaign">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Campaign</th>
      <td mat-cell *matCellDef="let element" class="cell-left">{{ element.campaignName }}</td>
    </ng-container>
    <ng-container matColumnDef="StartDate">
      <th mat-header-cell *matHeaderCellDef style="width: 12%">StartDate</th>
      <td mat-cell *matCellDef="let element" class="cell-left">{{ element.startDate }}</td>
    </ng-container>
    <ng-container matColumnDef="EndDate">
      <th mat-header-cell *matHeaderCellDef style="width: 12%">EndDate</th>
      <td mat-cell *matCellDef="let element" class="cell-left">{{ element.endDate }}</td>
    </ng-container>
    <ng-container matColumnDef="BillingMonth">
      <th mat-header-cell *matHeaderCellDef style="width: 12%">BillingMonth</th>
      <td mat-cell *matCellDef="let element" class="cell-left">{{ element.billingMonth }}</td>
    </ng-container>
    <ng-container matColumnDef="Costs">
      <th mat-header-cell *matHeaderCellDef style="width: 14%">Costs</th>
      <td mat-cell *matCellDef="let element" class="cell-right">
        <ng-container *ngIf="element.costs">
          {{ element.costs | currency: 'JPY' }}
        </ng-container>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="summaryReportTables.columns; sticky: true"
      style="background: #f6f8fa"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: summaryReportTables.columns"></tr>
  </table>
</div>
