import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { MaterialModule } from '../material.module';
import { UserService } from '../services/users/user.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [UserEditComponent, UserListComponent],
  imports: [CommonModule, MaterialModule, NgMultiSelectDropDownModule],
  exports: [UserEditComponent, UserListComponent],
  providers: [UserService],
})
export class UserSettingModule {}
