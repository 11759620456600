import { gql } from 'apollo-angular';

export const MutateOtpRequest = gql`
  mutation otpRequest($input: OtpRequestInputObjectType!) {
    otpRequest(input: $input) {
      ok
      userId
    }
  }
`;

export const MutateOtpTokenObtain = gql`
  mutation otpTokenObtain($input: OtpTokenObtainInputObjectType!) {
    otpTokenObtain(input: $input) {
      token
      refreshToken
      user {
        id
        name
        email
        isActive
        role
        advertisers {
          edges {
            node {
              id
              name
              isActive
            }
          }
        }
      }
    }
  }
`;

export const MutateTokenRefresh = gql`
  mutation tokenRefresh($input: TokenRefreshInputObjectType!) {
    tokenRefresh(input: $input) {
      token
      refreshToken
    }
  }
`;
