import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { SpinnerService } from 'src/app/core/spinner.service';
import {
  CampaignBillingType,
  CostInputObjectType,
  CostsInputObjectType,
} from 'src/app/models/graphql/types';
import {
  CALENDAR_FORMATS,
  convertStringToFloat,
  excerptErrorMessage,
  REGEX_MARGIN_RATE,
} from 'src/app/resource/utility/common-util';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { DatePipe } from '@angular/common';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatRadioChange } from '@angular/material/radio';
const moment = _rollupMoment;

/** Modal Parameter */
export interface ModalCostEditParam {
  campaignId: string;
  billingType: CampaignBillingType;
}

@Component({
  selector: 'app-cost-edit',
  templateUrl: './cost-edit.component.html',
  styleUrls: ['./cost-edit.component.scss'],
  providers: [DatePipe, { provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS }],
})
export class CostEditComponent implements OnInit {
  /** FomGroup */
  public formGroup = this._formBuilder.group({
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  });

  /** Cost Table */
  public costTable = this._formBuilder.group({
    tableRows: this._formBuilder.array([]),
  });

  /**
   * Constructor
   * @param modalEditParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param campaignService
   * @param datePipe
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalEditParam: ModalCostEditParam,
    private _dialogRef: MatDialogRef<CostEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private campaignService: CampaignService,
    private datePipe: DatePipe
  ) {
    // Get CostData
    this.getCosts(this.modalEditParam.campaignId);
  }

  ngOnInit(): void {
    return;
  }

  /**
   * calender control setMonthYear
   * RowBillingMonth
   * @param normalizedMonthAndYear
   * @param datepicker
   */
  public setBillingMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    control: AbstractControl
  ) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.date(1);
    (control as UntypedFormGroup).controls['billingMonth'].setValue(ctrlValue);
    datepicker.close();
  }

  /**
   * Get Costs
   */
  private async getCosts(campaignId: string) {
    this.spinnerService.show();
    // Get Costs
    await firstValueFrom(this.campaignService.getCosts(campaignId)).then(
      (res) => {
        const costs = res.data.costs;
        if (costs) {
          const control = this.costTable.get('tableRows') as UntypedFormArray;
          costs.edges.forEach((cost) => {
            if (cost && cost.node) {
              // Add CostRow
              control.push(
                this.createCostForm(
                  cost.node.billingMonth,
                  cost.node.costs != null ? cost.node.costs : null,
                  cost.node.marginRate != null ? cost.node.marginRate : null,
                  cost.node.adjustment != null ? cost.node.adjustment : null
                )
              );
              this.formGroup.controls['startDate'].setValue(cost.node.startDate);
              this.formGroup.controls['endDate'].setValue(cost.node.endDate);
            }
          });
          // New (no cost data)
          if (costs.edgeCount === 0) {
            control.push(this.createCostForm('', null, null, null));
          }
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
        });
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * Save button click
   */
  public onSave(): void {
    // CostRow Format.
    const costs: CostInputObjectType[] = [];
    this.getFormControls.value.forEach((element: CostInputObjectType) => {
      const costData: CostInputObjectType = {
        billingMonth: this.datePipe.transform(element.billingMonth, 'yyyy-MM-dd'),
        costs: element.costs ? element.costs : undefined,
        marginRate:
          element.marginRate != null ? convertStringToFloat(element.marginRate) : undefined,
        adjustment: element.adjustment != null ? element.adjustment : undefined,
      };
      costs.push(costData);
    });
    // Create Variables
    const variables: CostsInputObjectType = {
      campaignId: this.modalEditParam.campaignId,
      startDate: this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.endDate.value, 'yyyy-MM-dd'),
      costData: costs,
    };

    // updateCosts
    firstValueFrom(this.campaignService.updateCosts(variables)).then(
      (res) => {
        if (res.data?.costsUpdate?.ok) {
          this.showToaster('Editing completed');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Cost update failed.');
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.spinnerService.hide();
        this.showToaster(excerptErrorMessage(error.message));
      }
    );
  }

  /**
   * Click Cancel
   */
  public onCancel(): void {
    this._dialogRef.close(false);
  }

  /** create CostRowForm */
  public createCostForm(
    billingMonth: string,
    costs: number | null,
    marginRate: number | null,
    adjustment: number | null
  ): UntypedFormGroup {
    const costForm = this._formBuilder.group(
      {
        billingMonth: new UntypedFormControl(billingMonth, Validators.required),
        costs: new UntypedFormControl(costs),
        enabledMarginRate: new UntypedFormControl(marginRate !== null ? true : false),
        marginRate: new UntypedFormControl(marginRate),
        enabledAdjustment: new UntypedFormControl(adjustment !== null ? true : false),
        adjustment: new UntypedFormControl(adjustment),
      },
      {
        validators: [oneRequiredValidator('marginRate', 'adjustment')],
      } as AbstractControlOptions
    );
    if (this.modalEditParam.billingType === CampaignBillingType.Premium) {
      costForm.controls['costs'].addValidators([Validators.required, Validators.max(2147483647)]);
    } else {
      costForm.controls['costs'].setValue(null);
    }

    if (costForm.controls['enabledAdjustment'].value) {
      costForm.controls['marginRate'].disable();
      costForm.controls['adjustment'].enable();
      costForm.controls['enabledMarginRate'].setValue(false);
      costForm.controls['enabledAdjustment'].setValue(true);
    } else {
      costForm.controls['adjustment'].disable();
      costForm.controls['marginRate'].enable();
      costForm.controls['enabledMarginRate'].setValue(true);
      costForm.controls['enabledAdjustment'].setValue(false);
    }
    return costForm;
  }

  /**
   * Cost Table Add Row
   */
  public addRow() {
    const control = this.costTable.get('tableRows') as UntypedFormArray;
    control.push(this.createCostForm('', null, null, null));
  }

  /**
   * Cost Table Delete Row
   * @param index
   */
  public deleteRow(index: number) {
    const control = this.costTable.get('tableRows') as UntypedFormArray;
    control.removeAt(index);
  }
  /**
   * show message
   * @param message
   * @param button
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * isValidSave
   * Save button enabled
   * @returns
   */
  public isValidSave(): boolean {
    let ret = false;
    if (this.formGroup.valid && this.getFormControls.valid) {
      if (this.getFormControls.value.length > 0) {
        ret = true;
      }
    }
    return ret;
  }

  /**
   * isBillingTypePremium
   * costs enabled
   * @returns
   */
  public isBillingTypePremium(): boolean {
    let ret = false;
    if (this.modalEditParam.billingType === CampaignBillingType.Premium) {
      ret = true;
    }
    return ret;
  }

  /**
   * onSelectedMarginRate
   * @param event MatRadioChange
   * @param i rowIndex
   */
  public onSelectedMarginRate(event: MatRadioChange, i: number): void {
    const costsForm = this.getFormControls;
    if (event.value) {
      (costsForm.controls[i] as UntypedFormGroup).controls['enabledMarginRate'].setValue(true);
      (costsForm.controls[i] as UntypedFormGroup).controls['marginRate'].enable();
      (costsForm.controls[i] as UntypedFormGroup).controls['enabledAdjustment'].setValue(false);
      (costsForm.controls[i] as UntypedFormGroup).controls['adjustment'].disable();
      (costsForm.controls[i] as UntypedFormGroup).controls['adjustment'].setValue(null);
    }
  }

  /**
   * onSelectedAdjustment
   * @param event MatRadioChange
   * @param i rowIndex
   */
  public onSelectedAdjustment(event: MatRadioChange, i: number): void {
    const costsForm = this.getFormControls;
    if (event.value) {
      (costsForm.controls[i] as UntypedFormGroup).controls['enabledMarginRate'].setValue(false);
      (costsForm.controls[i] as UntypedFormGroup).controls['marginRate'].disable();
      (costsForm.controls[i] as UntypedFormGroup).controls['marginRate'].setValue(null);
      (costsForm.controls[i] as UntypedFormGroup).controls['enabledAdjustment'].setValue(true);
      (costsForm.controls[i] as UntypedFormGroup).controls['adjustment'].enable();
    }
  }

  /**
   * CostTableRow FormArry Getter
   */
  get getFormControls() {
    const control = this.costTable.get('tableRows') as UntypedFormArray;
    return control;
  }

  /**
   * startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.formGroup.get('startDate') as UntypedFormControl;
  }
  /**
   * endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.formGroup.get('endDate') as UntypedFormControl;
  }
}

/**
 * oneRequiredValidator
 * @param marginRate
 * @param adjustment
 * @returns
 */
export function oneRequiredValidator(marginRate: string, adjustment: string) {
  return (formGroup: UntypedFormGroup) => {
    const marginRateControl = formGroup.controls[marginRate];
    const adjustmentControl = formGroup.controls[adjustment];

    // Validation Error Clear
    adjustmentControl.setErrors(null);
    marginRateControl.setErrors(null);

    // Both no Input
    if (
      (marginRateControl.value === null || marginRateControl.value.length === 0) &&
      adjustmentControl.value === null
    ) {
      marginRateControl.setErrors({ oneRequired: true });
      adjustmentControl.setErrors({ oneRequired: true });
      return;
    }
    // Both Input
    if (
      marginRateControl.value !== null &&
      marginRateControl.value !== '' &&
      adjustmentControl.value !== null &&
      adjustmentControl.value >= 0
    ) {
      marginRateControl.setErrors({ oneRequired: true });
      adjustmentControl.setErrors({ oneRequired: true });
      return;
    }
    // valid marginRate
    if (marginRateControl.value !== null && marginRateControl.value !== '') {
      // valid Rate Format
      const marginPattern = REGEX_MARGIN_RATE.exec(marginRateControl.value);
      if (marginPattern === null) {
        marginRateControl.setErrors({ pattern: true });
        return;
      }
      // valid Range max
      if (marginRateControl.value > 100 || marginRateControl.value <= 0) {
        marginRateControl.setErrors({ max: true });
        return;
      }
    }
    // valid adjustment
    if (adjustmentControl.value !== null) {
      // valid Range min
      if (adjustmentControl.value < 0) {
        adjustmentControl.setErrors({ min: true });
        return;
      }
      // valid Range max
      if (adjustmentControl.value > 2147483647) {
        adjustmentControl.setErrors({ max: true });
        return;
      }
    }
  };
}
