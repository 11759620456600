/**
 * Get decode id
 * @param encoded
 * @returns
 */
export function getDecodeId(encoded: string): string | undefined {
  return atob(encoded).split(':').pop();
}

/** is Number check */
export function isNumber(value: string): boolean {
  return !Number.isNaN(parseInt(value));
}
/** string to Number */
export function convertStringToNumber(value: string): number | null {
  let ret = null;
  if (isNumber(value)) {
    ret = parseInt(value);
  }
  return ret;
}

/** string to Decimal */
export function convertStringToFloat(value: string): number | null {
  let ret = null;
  if (isNumber(value)) {
    ret = parseFloat(value);
  }
  return ret;
}

export function excerptErrorMessage(errorMessage: string): string {
  const message = errorMessage.split('\n');
  return message[0];
}

/** Email Regex */
export const REGEX_EMAIL = '^[a-z0-9._+-]+@[a-z0-9.-]+\\.[a-z]{1,}$';
/** OneTimePassword Regex */
export const REGEX_OTP = '^[0-9]{6}$';
/** Password Regex */
export const REGEX_PWD = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[a-zA-Z0-9]{8,64}$/;
/** AdvertiserName Regex */
export const REGEX_ADVERTISER_NAME =
  '^[ぁ-んァ-ヶｱ-ﾝﾞﾟー-龠０-９ａ-ｚＡ-Ｚ（）－～0-9a-zA-Z\\()\\-\\_]{1,256}$';
/** CampaignName Regex */
export const REGEX_CAMPAIGN_NAME = '^[ぁ-んァ-ヶー-龠０-９ａ-ｚＡ-Ｚ（）－～0-9a-zA-Z\\-]{1,256}$';
/** FlightName Regex */
export const REGEX_FLIGHT_NAME = '^[ぁ-んァ-ヶー-龠０-９ａ-ｚＡ-Ｚ（）－～0-9a-zA-Z\\-]{1,256}$';
/** CostAmount Regex */
export const REGEX_COST_AMOUNT = '^[0-9]{0,10}$';
/** URL Regex */
export const REGEX_URL = /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/;
/** VideoPlaybackID */
export const REGEX_VIDEO_PLAYBACK_ID = '^[0-9A-Za-z]{1,256}$';
/** CampaignCosts Regex */
export const REGEX_COST = /^([1-9]\d*|0)$/;
/** Adjustment Regex */
export const REGEX_ADJUSTMENT = /^([1-9]\d*|0)$/;
/** MarginRate Regex */
export const REGEX_MARGIN_RATE = /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,2})?$/;
/** ImageFile Regex */
export const REGEX_CREATIVE_IMAGE_FILE = /^[a-zA-Z0-9_-]+(\.jpg|\.jpeg|\.png)$/;
/** TextBox Regex */
export const REGEX_CUSTOM_TARGETING_TEXT_BOX = /^[0-9a-zA-Z,-]*$/;

/** Calendar Format */
export const CALENDAR_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthLabel: 'MM',
    monthYearLabel: 'YYYY MM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MM',
  },
};
