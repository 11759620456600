import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { AdvertisersComponent } from './advertisers.component';
import { RouterModule } from '@angular/router';
import { AdvertiserEditComponent } from './advertiser-edit/advertiser-edit.component';

@NgModule({
  declarations: [AdvertisersComponent, AdvertiserEditComponent],
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [AdvertisersComponent],
})
export class AdvertisersModule {}
