<h2 mat-dialog-title>New Flight forrrrr {{ modalCreateParam.campaignName }}</h2>
<mat-dialog-content style="height: 50%">
  <mat-stepper linear orientation="vertical" (selectionChange)="selectionChange($event)">
    <mat-step [stepControl]="flightGeneralFormGroup">
      <ng-template matStepLabel>Step 1</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="flightGeneralFormGroup">
          <!-- <input type="text" placeholder="test" #zoneInputRef /> -->
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>CreativeType</mat-label>
            <mat-select formControlName="creativeType">
              <mat-option *ngFor="let creativeType of creativeTypes" [value]="creativeType">{{
                creativeType
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="creativeType.errors?.['required']"
              >CreativeType is required.</mat-error
            >
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Size</mat-label>
            <mat-select formControlName="adSize">
              <mat-option *ngFor="let adSizeType of adSizeTypes" [value]="adSizeType">{{
                adSizeType.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adSize.errors?.['required']">Size is required.</mat-error>
          </mat-form-field>
          <button mat-stroked-button matStepperNext color="primary">Next</button>
        </form>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Step 2</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="flightFormGroup">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>FlightName *Autofill</mat-label>
            <input
              matInput
              type="text"
              formControlName="flightName"
              value="{{ createFlightName() }}"
            />
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>FriendlyName</mat-label>
            <input matInput formControlName="friendlyName" />
            <mat-error *ngIf="friendlyName.errors?.['required']"
              >FriendlyName is required.</mat-error
            >
            <mat-error *ngIf="friendlyName.errors?.['pattern']"
              >Invalid FriendlyName format.</mat-error
            >
          </mat-form-field>
          <div class="flex-row justify-space-between -flex">
            <div class="align-start-center flex-66">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Zone</mat-label>
                <!-- <mat-select formControlName="zone" multiple>
                  <mat-option *ngFor="let zoneType of zoneTypes" [value]="zoneType">{{
                    zoneType.name
                  }}</mat-option>
                </mat-select> -->
                <mat-chip-grid #chipGrid>
                  <mat-chip-row
                    *ngFor="let zoneType of flightFormGroup.controls['selectedZones'].value"
                    (removed)="removeZone(zoneType)"
                    [aria-description]="'press enter to edit ' + zoneType.name"
                  >
                    {{ zoneType.name }}
                    <button matChipRemove [attr.aria-label]="'remove ' + zoneType.name">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>

                  <input
                    [matChipInputFor]="chipGrid"
                    formControlName="zoneSearch"
                    matInput
                    [matAutocomplete]="autoComplete"
                    style="width: 100%"
                    #zoneInputRef
                    id="zone-input-field"
                  />
                  <mat-autocomplete
                    #autoComplete="matAutocomplete"
                    [displayWith]="displayFn"
                    [hideSingleSelectionIndicator]="true"
                    style="width: 100%"
                  >
                    <mat-option
                      *ngFor="let zone of zoneOptions"
                      [value]="zone"
                      (click)="optionClicked($event, zone)"
                      class="zone-mat-option"
                      style="padding-left: 0; padding-right: 0; padding-top: 0; padding-bottom: 0"
                    >
                      <mat-checkbox
                        [checked]="zoneAlreadySelected(zone)"
                        (click)="optionClicked($event, zone)"
                        style="width: 100%; padding: 10px; padding-bottom: 10px"
                      >
                        {{ zone.name }}
                      </mat-checkbox>
                    </mat-option>
                  </mat-autocomplete>
                </mat-chip-grid>
              </mat-form-field>
            </div>
            <div class="align-start-center flex-33">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Priority</mat-label>
                <mat-select formControlName="priority" (selectionChange)="onChangePriorityType()">
                  <mat-option *ngFor="let priorityType of priorityTypes" [value]="priorityType">{{
                    priorityType.name
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="priority.errors?.['required']">Priority is required.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row justify-space-between -flex">
            <div class="align-center-center flex-48">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>StartDate</mat-label>
                <input matInput [matDatepicker]="datepickerStartDate" formControlName="startDate" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datepickerStartDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #datepickerStartDate> </mat-datepicker>
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-error *ngIf="startDate.errors?.['required']">StartDate is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-48">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>EndDate</mat-label>
                <input
                  matInput
                  [matDatepicker]="datepickerEndDate"
                  [min]="startDate.value"
                  formControlName="endDate"
                />
                <mat-datepicker-toggle matSuffix [for]="datepickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #datepickerEndDate> </mat-datepicker>
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-error *ngIf="endDate.errors?.['required']">EndDate is required.</mat-error>
                <mat-error *ngIf="endDate.errors?.['matDatepickerMin']"
                  >The EndDate cannot be earlier than the start date.</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="flex-row justify-space-between -flex">
            <div class="align-start-center flex-18">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Rate</mat-label>
                <mat-select formControlName="rate">
                  <mat-option *ngFor="let rateType of rateTypes" [value]="rateType">{{
                    rateType
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-39">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Price</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="price" />
                <mat-hint>{{ price.value | currency : 'JPY' }}</mat-hint>
                <mat-error *ngIf="price.errors?.['required']">Price is required.</mat-error>
                <mat-error
                  *ngIf="
                    price.errors?.['pattern'] || price.errors?.['max'] || price.errors?.['min']
                  "
                >
                  Invalid Price format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
            <div class="align-start-center flex-39"></div>
          </div>

          <div class="flex-row justify-space-between -flex">
            <div class="align-start-center flex-18">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>GoalType</mat-label>
                <mat-select formControlName="goalType">
                  <mat-option *ngFor="let goalType of goalTypes" [value]="goalType">{{
                    goalType
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-39">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>GoalAmount</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="goalAmount" />
                <mat-error *ngIf="goalAmount.errors?.['required']"
                  >GoalAmount is required.</mat-error
                >
                <mat-error
                  *ngIf="
                    goalAmount.errors?.['pattern'] ||
                    goalAmount.errors?.['max'] ||
                    goalAmount.errors?.['min']
                  "
                >
                  Invalid GoalAmount format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-39"></div>
          </div>

          <div class="flex-row justify-space-between -flex-gap-1-percen -flex">
            <div class="align-start-center flex-18">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>CapType</mat-label>
                <mat-select formControlName="capType">
                  <mat-option *ngFor="let capType of capTypes" [value]="capType">{{
                    capType
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-39">
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>DailyCapAmount</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="dailyCapAmount" />
                <mat-hint>{{ dailyCapAmount.value | currency : 'JPY' }}</mat-hint>
                <mat-error *ngIf="dailyCapAmount.errors?.['required']"
                  >DailyCapAmount is required.</mat-error
                >
                <mat-error
                  *ngIf="
                    dailyCapAmount.errors?.['pattern'] ||
                    dailyCapAmount.errors?.['max'] ||
                    dailyCapAmount.errors?.['min']
                  "
                >
                  Invalid DailyCapAmount format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-39">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>LifeTimeCapAmount</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="lifeTimeCapAmount" />
                <mat-hint>{{ lifeTimeCapAmount.value | currency : 'JPY' }}</mat-hint>
                <mat-error *ngIf="lifeTimeCapAmount.errors?.['required']"
                  >LifeTimeCapAmount is required.</mat-error
                >
                <mat-error
                  *ngIf="
                    lifeTimeCapAmount.errors?.['pattern'] ||
                    lifeTimeCapAmount.errors?.['max'] ||
                    lifeTimeCapAmount.errors?.['min']
                  "
                >
                  Invalid LifeTimeCapAmount format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="sortedCustomTargetings"
            class="align-start-start layout-gap-half-percent flex-row-wrap -flex"
          >
            <ng-container *ngFor="let customTargeting of sortedCustomTargetings">
              <div class="custom-targeting-form-container align-start-center flex-33">
                <ng-container *ngIf="customTargeting.elementType === checkBox">
                  <mat-form-field style="width: 100%" appearance="fill">
                    <mat-label>{{ customTargeting.key }}</mat-label>
                    <mat-select [formControlName]="customTargeting.key" multiple>
                      <mat-option *ngFor="let value of customTargeting.values" [value]="value">
                        {{ value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="customTargeting.elementType === textBox">
                  <mat-form-field style="width: 100%" appearance="fill">
                    <mat-label>{{ customTargeting.key }}</mat-label>
                    <input matInput [formControlName]="customTargeting.key" />

                    <mat-error
                      *ngIf="flightFormGroup.get(customTargeting.key)?.errors?.['required']"
                    >
                      {{ customTargeting.key }} is required.
                    </mat-error>
                    <mat-error
                      *ngIf="flightFormGroup.get(customTargeting.key)?.errors?.['pattern']"
                    >
                      Invalid {{ customTargeting.key }} format. Only alphanumeric characters, commas
                      (,), and hyphens (-) are allowed.
                    </mat-error>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="customTargeting.elementType === radioButton">
                  <div>
                    <mat-label>{{ customTargeting.key }}</mat-label>
                    <mat-radio-group
                      [formControlName]="customTargeting.key"
                      attr.aria-labelledby="{{
                        camelToKebabCase(customTargeting.key)
                      }}-radio-group-label"
                      class="custom-targeting-radio-group"
                    >
                      <mat-radio-button
                        *ngFor="let value of customTargeting.values"
                        [value]="value"
                        class="custom-targeting-radio-button"
                      >
                        {{ value }}
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error
                      *ngIf="flightFormGroup.get(customTargeting.key)?.errors?.['required']"
                    >
                      {{ customTargeting.key }} is required.
                    </mat-error>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="flex-row justify-space-between -flex">
            <div class="align-start-center flex-33">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option
                    *ngFor="let activeStatusType of activeStatusTypes"
                    [value]="activeStatusType.value"
                    >{{ activeStatusType.label }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="status.errors?.['required']">Status is required.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button color="basic" class="btn" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!flightFormGroup.valid || isSubmitting"
        (click)="onRegistration()"
      >
        Regist
      </button>
    </div>
  </div>
</mat-dialog-actions>
