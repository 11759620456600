import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from '../core/spinner.service';
import { UserProfileService } from '../core/user-profile.service';
import {
  AdvertiserObjectType,
  CampaignObjectType,
  QueryCampaignsArgs,
} from '../models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from '../models/state';
import { Feature } from '../resource/feature-role';
import { excerptErrorMessage, getDecodeId } from '../resource/utility/common-util';
import { AdvertiserService } from '../services/advertisers/advertiser.service';
import { CampaignService } from '../services/campaigns/campaign.service';
import { CampaignEditComponent, ModalEditParam } from './campaign-edit/campaign-edit.component';
import {
  CampaignRegistrationComponent,
  ModalCreateParam,
} from './campaign-registration/campaign-registration.component';
import { CostEditComponent, ModalCostEditParam } from './cost-edit/cost-edit.component';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy {
  /** AdvertiserID */
  public advertiserId = null;
  public advertiser: AdvertiserObjectType | null = null;
  /** MatTable */
  public displayedColumns: string[] = ['id', 'isActive', 'name', 'edit', 'costEdit'];
  public campaignList: MatTableDataSource<CampaignObjectType> =
    new MatTableDataSource(); /** Pager */
  public pageLength: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 30;
  /** SortKey */
  public sortKey: string | undefined = undefined;

  /** FormFilterGroup */
  public formFilterGroup = this._formBuilder.group({
    name: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
  });
  /** Status */
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /**
   * constructor
   * @param activeRoute
   * @param _formBuilder
   * @param _snackBar
   * @param _dialog
   * @param spinnerService
   * @param advertiserService
   * @param campaignService
   * @param userProfileService
   * @param router
   */
  constructor(
    private activeRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private spinnerService: SpinnerService,
    private advertiserService: AdvertiserService,
    private campaignService: CampaignService,
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      // Set Parameter from URLQueryParameter
      this.advertiserId = params['advertiser_id'] ? params['advertiser_id'] : null;
      this.pageSize = Number(params['size']) >= 0 ? Number(params['size']) : this.pageSize;
      this.pageIndex = Number(params['page']) >= 0 ? Number(params['page']) : this.pageIndex;
      this.sortKey = params['sort'] ? params['sort'] : undefined;
      this.name.setValue(params['name'] ? params['name'] : '');
      this.status.setValue(params['status'] ? params['status'] === 'true' : '');
      // createList
      this.createCampaignList();
    });
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this._dialog.closeAll();
  }

  /**
   * createCampaignList
   * @returns
   */
  private createCampaignList(): void {
    if (this.advertiserId !== null) {
      this.getAdvertiser(this.advertiserId);
    } else {
      // Error: inconsistent access
      this._snackBar.open(`you don't have permission to access`, 'close', {
        verticalPosition: 'top',
      });
      return;
    }
    this.getCampaigns();
  }

  /**
   * click new a campaign
   */
  public onAddCampaign(): void {
    if (this.advertiserId !== null) {
      const modalCreateParam: ModalCreateParam = {
        advertiserId: this.advertiserId,
      };
      this._dialog
        .open(CampaignRegistrationComponent, {
          data: modalCreateParam,
          disableClose: true,
          width: '50%',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.onRefresh();
          }
        });
    }
  }

  /**
   * Click Edit
   * @param element CampaignObjectType
   */
  public onEditCampaign(element: CampaignObjectType): void {
    const editForm: ModalEditParam = {
      campaignId: element.id,
    };
    this._dialog
      .open(CampaignEditComponent, {
        data: editForm,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * Cost Click
   * @param element CampaignObjectType
   */
  public onEditCampaignCost(element: CampaignObjectType): void {
    const editForm: ModalCostEditParam = {
      campaignId: element.id,
      billingType: element.billingType,
    };
    this._dialog
      .open(CostEditComponent, {
        data: editForm,
        disableClose: true,
        width: '60%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onRefresh();
        }
      });
  }

  /**
   * Get Advertiser
   * @param advertiserId
   */
  private async getAdvertiser(advertiserId: string) {
    await firstValueFrom(this.advertiserService.getAdvertiser(advertiserId)).then(
      (res) => {
        const result = res.data.advertiser;
        if (result) {
          this.advertiser = result;
        } else {
          // Error: inconsistent access
          this._snackBar.open(`you don't have permission to access`, 'close', {
            verticalPosition: 'top',
          });
        }
      },
      (error: Error) => {
        // Error
        this._snackBar.open('Failed to get data. Unable to display list.', 'close', {
          verticalPosition: 'top',
        });
      }
    );
  }

  /**
   * Get Campaigns
   */
  private async getCampaigns() {
    this.spinnerService.show();
    this.campaignList = new MatTableDataSource();
    // create variables
    const variables: QueryCampaignsArgs = {
      offset: this.pageIndex * this.pageSize,
      first: this.pageSize,
      orderBy: this.sortKey,
      search: this.name.value,
      isActive: this.status.value === '' ? undefined : this.status.value,
      advertiserId: this.advertiserId,
    };
    // Get Campaigns
    await firstValueFrom(this.campaignService.getCampaigns(variables)).then(
      (res) => {
        const campaigns = res.data.campaigns;
        const campaignList: CampaignObjectType[] = [];
        if (campaigns) {
          campaigns.edges.forEach((campaign) => {
            if (campaign && campaign.node) {
              campaignList.push(campaign.node);
            }
          });
          this.campaignList = new MatTableDataSource(campaignList);
          // set pageInfo
          if (campaigns.edgeCount === 0) {
            this.pageIndex = 0;
            this.pageLength = 0;
          } else {
            this.pageLength = campaigns.totalCount;
          }
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * ColumsSort
   * @param sort
   */
  public sortByColumns(sort: Sort): void {
    let sortKey = sort.active;
    if (sort.direction === 'desc') {
      sortKey = '-' + sortKey;
    }
    this.sortKey = sortKey;
    this.pageIndex = 0;
    this.updateURLQueryParams();
  }

  /**
   * PagingHandleEvent
   * @param event
   */
  public handlePageEvent(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * Click Refresh
   */
  public onRefresh(): void {
    this.getCampaigns();
  }

  /**
   * Apply Filter
   */
  public applyFilter(): void {
    // Update URL Query Parameters
    this.updateURLQueryParams();
  }

  /**
   * Click Reset
   */
  public onFilterReset(): void {
    if (this.name.value === '' && this.status.value === '') {
      // no filer. nop
      return;
    }
    this.formFilterGroup.setValue({
      name: '',
      status: '',
    });
    this.applyFilter();
  }

  /**
   * registrationButtonEnabled
   * @returns
   */
  public registrationButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.createCampaign)) {
      ret = true;
    }
    return ret;
  }

  /**
   * editButtonEnabled
   * @returns
   */
  public editButtonEnabled(): boolean {
    let ret = false;
    if (this.userProfileService.isAvailableFeature(Feature.editCampaign)) {
      ret = true;
    }
    return ret;
  }

  /**
   * Update URL Query Parameters
   */
  private updateURLQueryParams(): void {
    // Set SearchCondition to QueryPramas
    this.router.navigate(['.'], {
      relativeTo: this.activeRoute,
      queryParams: {
        advertiser_id: this.advertiserId,
        page: this.pageIndex,
        size: this.pageSize,
        sort: this.sortKey ? this.sortKey : undefined,
        name: this.name.value ? this.name.value : undefined,
        status: this.status.value !== '' ? this.status.value : undefined,
      },
    });
  }

  /**
   * CampaignId decoded
   * @param encodedID
   * @returns
   */
  public getDecodeId(encodedID: string): string | undefined {
    return getDecodeId(encodedID);
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.formFilterGroup.get('name') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.formFilterGroup.get('status') as UntypedFormControl;
  }
}
