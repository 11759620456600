<h2 mat-dialog-title>New Flight for {{ modalCreateParam.campaignName }}</h2>
<mat-dialog-content style="height: 50%">
  <mat-stepper linear orientation="vertical" (selectionChange)="selectionChange($event)">
    <mat-step [stepControl]="flightGeneralFormGroup">
      <ng-template matStepLabel>Step 1</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="flightGeneralFormGroup">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>CreativeType</mat-label>
            <mat-select formControlName="creativeType">
              <mat-option *ngFor="let creativeType of creativeTypes" [value]="creativeType">{{
                creativeType
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="creativeType.errors?.['required']"
              >CreativeType is required.</mat-error
            >
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Size</mat-label>
            <mat-select formControlName="adSize">
              <mat-option *ngFor="let adSizeType of adSizeTypes" [value]="adSizeType">{{
                adSizeType.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adSize.errors?.['required']">Size is required.</mat-error>
          </mat-form-field>
          <button mat-stroked-button matStepperNext color="primary">Next</button>
        </form>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Step 2</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="flightFormGroup">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>FlightName *Autofill</mat-label>
            <input
              matInput
              type="text"
              formControlName="flightName"
              value="{{ createFlightName() }}"
            />
          </mat-form-field>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>FriendlyName</mat-label>
            <input matInput formControlName="friendlyName" />
            <mat-error *ngIf="friendlyName.errors?.['required']"
              >FriendlyName is required.</mat-error
            >
            <mat-error *ngIf="friendlyName.errors?.['pattern']"
              >Invalid FriendlyName format.</mat-error
            >
          </mat-form-field>
          <div class="flex-row justify-space-between -flex">
            <div class="align-start-center flex-33">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option
                    *ngFor="let activeStatusType of activeStatusTypes"
                    [value]="activeStatusType.value"
                    >{{ activeStatusType.label }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="status.errors?.['required']">Status is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="align-start-center flex-33">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>StartDate</mat-label>
                <input
                  matInput
                  [matDatepicker]="datepickerStartDate"
                  formControlName="startDate"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datepickerStartDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #datepickerStartDate> </mat-datepicker>
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-error *ngIf="startDate.errors?.['required']">StartDate is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="align-start-center flex-33">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>EndDate</mat-label>
                <input
                  matInput
                  [matDatepicker]="datepickerEndDate"
                  [min]="startDate.value"
                  formControlName="endDate"
                />
                <mat-datepicker-toggle matSuffix [for]="datepickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #datepickerEndDate> </mat-datepicker>
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-error *ngIf="endDate.errors?.['required']">EndDate is required.</mat-error>
                <mat-error *ngIf="endDate.errors?.['matDatepickerMin']"
                  >The EndDate cannot be earlier than the start date.</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button color="basic" class="btn" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!flightFormGroup.valid || isSubmitting"
        (click)="onRegistration()"
      >
        Regist
      </button>
    </div>
  </div>
</mat-dialog-actions>
