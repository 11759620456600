import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  GroupingType,
  IndicatorType,
  PerformanceReportObjectType,
} from 'src/app/models/graphql/types';

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss'],
})
export class PerformanceReportComponent implements OnInit, OnDestroy, AfterViewInit {
  /** InputData */
  @Input() public reportGroupType: GroupingType[] | undefined = undefined;
  @Input() public reportColumns: IndicatorType[] | undefined = undefined;
  @Input() public performanceData: PerformanceReportObjectType[] = [];
  /** Performance TableElement */
  @ViewChild('performanceTable', { static: false, read: ElementRef })
  public performanceTable: ElementRef;

  /** Table Columns */
  public rowColumns: string[] = [];

  /**
   * constructor
   * @param elementRef
   */
  constructor(private elementRef: ElementRef) {
    this.performanceTable = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (!this.reportColumns) {
      return;
    }
    if (this.reportGroupType) {
      for (let column of this.reportGroupType) {
        this.rowColumns.push(column);
      }
    }
    for (let column of this.reportColumns) {
      this.rowColumns.push(column);
    }
  }

  ngAfterViewInit(): void {
    // Change width by length of columns
    this.performanceTable.nativeElement.style.width = `${20 * this.rowColumns.length}%`;
  }

  ngOnDestroy(): void {
    return;
  }
}
