import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QueryGetUser } from 'src/app/models/graphql/queries/get-user.graphql';
import { QueryGetUsers } from 'src/app/models/graphql/queries/get-users.graphql';
import { MutateCreateUser } from 'src/app/models/graphql/mutation/create-user.graphql';
import { MutateUpdateUser } from 'src/app/models/graphql/mutation/update-user.graphql';
import {
  Mutation,
  Query,
  QueryUsersArgs,
  PasswordRemindInputObjectType,
  UserInputObjectType,
} from 'src/app/models/graphql/types';
import { MutatePasswordRemind } from 'src/app/models/graphql/mutation/password-remind.graphql';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo) {}

  /**
   * Get Users
   * @param variables QueryUsersArgs
   * @returns
   */
  public getUsers(variables?: QueryUsersArgs) {
    return this.apollo.query<Query, QueryUsersArgs>({
      query: QueryGetUsers,
      variables: {
        offset: variables?.offset,
        first: variables?.first,
        after: variables?.after,
        last: variables?.last,
        before: variables?.before,
        isActive: variables?.isActive,
        orderBy: variables?.orderBy,
        name: variables?.name,
        email: variables?.email,
        role: variables?.role,
        advertiserName: variables?.advertiserName,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Get User info by ID
   * @param id UserID
   * @returns
   */
  public getUser(id: string) {
    return this.apollo.query<Query>({
      query: QueryGetUser,
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    });
  }

  /**
   * Create User
   * @param request: UserInputObjectType
   * @returns
   */
  public createUser(request: UserInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutateCreateUser,
      variables: {
        input: request,
      },
    });
  }

  /**
   * Update User
   * @param request: UserInputObjectType
   * @returns
   */
  public updateUser(request: UserInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutateUpdateUser,
      variables: {
        input: request,
      },
    });
  }

  /**
   * RemindPassword
   * @param request: PasswordRemindInputObjectType
   * @returns
   */
  public remindPassword(request: PasswordRemindInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutatePasswordRemind,
      variables: {
        input: request,
      },
    });
  }
}
