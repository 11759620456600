<div class="container flex-row -flex">
  <div class="mat-elevation-z2 flex-column flex-20" style="background-color: #fff">
    <div class="flex-grow" style="padding: 1em">
      <div mat-line class="condition-lable">Report</div>
      <p mat-line class="condition-item">{{ conditionItem.reportName }}</p>
      <div mat-line class="condition-lable">Report Type</div>
      <p mat-line class="condition-item">{{ conditionItem.reportType }}</p>
      <div mat-line class="condition-lable">Start Date</div>
      <p mat-line class="condition-item">{{ conditionItem.startDate }}</p>
      <div mat-line class="condition-lable">End Date</div>
      <p mat-line class="condition-item">{{ conditionItem.endDate }}</p>
      <div mat-line class="condition-lable">Indicator</div>
      <ng-container *ngFor="let indicator of indicators">
        <p mat-line class="condition-item">{{ indicator }}</p>
      </ng-container>
      <div mat-line class="condition-lable">Group By</div>
      <p mat-line class="condition-item">{{ groups }}</p>
      <div mat-line class="condition-lable">Filters</div>
      <ng-container *ngFor="let filter of filters">
        <p mat-line class="condition-item">{{ filter.key }} : {{ filter.displayName }}</p>
      </ng-container>
    </div>
    <div class="flex-grow" style="padding: 1em">
      <mat-divider></mat-divider>
      <div style="padding: 1em">
        <button mat-flat-button color="primary" (click)="editReport()" style="width: 100%">
          Edit Report
        </button>
      </div>
      <div style="padding: 1em">
        <button
          mat-stroked-button
          color="primary"
          (click)="downloadCSV('#report-table')"
          style="width: 100%"
        >
          Download CSV
        </button>
        <a id="csv-donwload"></a>
      </div>
    </div>
  </div>
  <div
    class="mat-elevation-z2 flex-column flex-80"
    style="background-color: #fff; max-width: 100%; overflow: auto"
  >
    <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
      <mat-tab *ngFor="let reportTab of reportTabs" label="{{ reportTab }}">
        <div style="max-width: 100%">
          <ng-container *ngIf="reportTab === 'Summary'">
            <app-summary-report [summaryData]="reportSummaryData"> </app-summary-report>
          </ng-container>
          <ng-container *ngIf="reportTab === 'Performance'">
            <div style="max-width: 100%">
              <ng-container *ngIf="isDisplayGraph()">
                <app-graph-report
                  [reportGroupType]="groups"
                  [performanceData]="reportPerformanceData"
                  [reportColumns]="indicators"
                  [startDate]="conditionItem.startDate"
                  [endDate]="conditionItem.endDate"
                >
                </app-graph-report>
              </ng-container>
            </div>
            <div class="mat-elevation-z1" style="max-width: 100%; overflow: auto">
              <app-performance-report
                [performanceData]="reportPerformanceData"
                [reportGroupType]="groups"
                [reportColumns]="indicators"
              >
              </app-performance-report>
            </div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
