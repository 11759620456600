<div
  class="flex-column -flex"
  style="height: 60vh; width: 100%; padding: 8px; box-sizing: border-box"
>
  <div class="flex-row align-start-center flex-15" style="margin: 8px">
    <mat-form-field>
      <mat-label>Indicator</mat-label>
      <mat-select [(value)]="indicator" (selectionChange)="drawUpdate()">
        <mat-option *ngFor="let indicator of reportColumns" [value]="indicator">{{
          indicator
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-85">
    <canvas
      style="max-height: 100%; max-width: 100%; padding-bottom: 10px"
      baseChart
      [data]="chartData"
      [type]="chartType"
      [options]="chartOptions"
    >
    </canvas>
  </div>
</div>
