<h2 mat-dialog-title>Edit Flight for {{ campaign?.name }}</h2>
<mat-dialog-content style="height: 50%">
  <form [formGroup]="flightFormGroup">
    <input matInput type="text" formControlName="adSize" [hidden]="true" />
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>FlightName</mat-label>
      <input matInput type="text" formControlName="flightName" />
    </mat-form-field>
    <div class="flex-row justify-space-between -flex">
      <div class="align-center-center flex-48">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>StartDate</mat-label>
          <input matInput [matDatepicker]="datepickerStartDate" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="datepickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #datepickerStartDate> </mat-datepicker>
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-error *ngIf="startDate.errors?.['required']">StartDate is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="align-center-center flex-48">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>EndDate</mat-label>
          <input
            matInput
            [matDatepicker]="datepickerEndDate"
            [min]="startDate.value"
            formControlName="endDate"
          />
          <mat-datepicker-toggle matSuffix [for]="datepickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #datepickerEndDate> </mat-datepicker>
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-error *ngIf="endDate.errors?.['required']">EndDate is required.</mat-error>
          <mat-error *ngIf="endDate.errors?.['matDatepickerMin']"
            >The EndDate cannot be earlier than the start date.</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="flex-row justify-space-between -flex">
      <div class="align-start-center flex-33">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option
              *ngFor="let activeStatusType of activeStatusTypes"
              [value]="activeStatusType.value"
              >{{ activeStatusType.label }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="status.errors?.['required']">Status is required.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button color="basic" class="btn" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!flightFormGroup.valid"
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-actions>
