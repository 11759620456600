<div style="width: 100%; height: 100%">
  <mat-toolbar
    style="
      height: 48px;
      background: #ffffff;
      box-shadow:
        0 1px 2px #b0b4b7,
        0 0 0 1px #dee5ec80;
    "
  >
    <div class="flex-row -flex" style="width: 100%; height: 100%">
      <div
        class="align-start-center flex-row -flex flex-1-0-auto"
        style="align-items: center; border-right: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <mat-icon>{{ pageIcon }}</mat-icon>
        <span>{{ pageTitle }}</span>
      </div>
      <div class="align-start-center flex-row -flex flex-0-1-auto">
        <button
          mat-flat-button
          style="background: none; display: flex; flex-direction: row-reverse"
          [matMenuTriggerFor]="menu"
        >
          {{ getLoginUser()
          }}<mat-icon style="margin-left: 4px; margin-right: 0">account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Logout</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</div>
