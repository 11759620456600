import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { PasswordResetRequestInputObjectType } from 'src/app/models/graphql/types';
import { PasswordChangeService } from 'src/app/services/password-change/password-change.service';
import { excerptErrorMessage, REGEX_EMAIL } from 'src/app/resource/utility/common-util';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  /** Reset or InitialRegistration */
  public isInitialRegistration = false;

  /** error list */
  public errors: string[] = [];

  /** PasswordReset FormGroup */
  public passwordResetForm = this._formBuilder.group({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(REGEX_EMAIL),
    ]),
  });

  /**
   * Constructor
   * @param _formBuilder
   * @param _router
   * @param loginService
   * @param spinnerService
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private passwordChangeService: PasswordChangeService,
    private spinnerService: SpinnerService
  ) {
    if (this._router.url === '/password_new') {
      this.isInitialRegistration = true;
    }
  }
  ngOnInit(): void {
    // init
    return;
  }

  /**
   * Password reset request
   */
  public async send() {
    this.errors.splice(0);
    if (!this.passwordResetForm.invalid) {
      this.spinnerService.show();
      // input
      const input: PasswordResetRequestInputObjectType = {
        email: this.passwordResetForm.controls['email'].value,
      };
      // call passwordResetRequest
      await firstValueFrom(this.passwordChangeService.passwordResetRequest(input)).then(
        (res) => {
          const response = res.data?.passwordResetRequest;
          if (response && response.ok) {
            localStorage.setItem('email', this.email.value);
            this._router.navigateByUrl('/password_reset_auth');
          } else {
            this.errors.push('Password reset request failed.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.errors.push(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * email FormControl Getter
   */
  get email(): UntypedFormControl {
    return this.passwordResetForm.get('email') as UntypedFormControl;
  }
}
