import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import {
  CampaignTypesForm,
  CpTypeNormal2D,
  CpTypePremiumAdserver,
  CpTypePremiumEmbedded,
} from 'src/app/models/campaign';
import {
  CampaignAdType,
  CampaignBillingType,
  CampaignDeliveryType,
  CampaignInputObjectType,
  QueryCampaignArgs,
} from 'src/app/models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import {
  convertStringToNumber,
  excerptErrorMessage,
  getDecodeId,
} from 'src/app/resource/utility/common-util';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';

/** Modal Parameter */
export interface ModalEditParam {
  campaignId: string | null;
}

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],
})
export class CampaignEditComponent implements OnInit {
  /** Title */
  public formTitle = 'Edit Campaign';
  public displayCampaignId: string | undefined;

  /** Advertiser ID */
  public advertiserId: string | null = null;

  /** Status Types*/
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /** CampaignType Control */
  public campaignType: CampaignTypesForm = {
    dailyCostAmount: false,
    lifetimeCostAmount: false,
    isActive: false,
  };

  /** CampaignForm */
  public campaignFormGroup = this._formBuilder.group({
    billingType: new UntypedFormControl(''),
    adType: new UntypedFormControl(''),
    deliveryType: new UntypedFormControl(''),
    campaignName: new UntypedFormControl(''),
    dailyCostAmount: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    lifetimeCostAmount: new UntypedFormControl('', [Validators.min(0), Validators.max(2147483647)]),
    status: new UntypedFormControl('', [Validators.required]),
  });

  /**
   * Constructor
   * @param editModalParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param campaignService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalEditParam: ModalEditParam,
    private _dialogRef: MatDialogRef<CampaignEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private campaignService: CampaignService
  ) {
    if (this.modalEditParam.campaignId !== null) {
      this.getCampaign(this.modalEditParam.campaignId);
      this.displayCampaignId = getDecodeId(this.modalEditParam.campaignId);
    }
  }

  ngOnInit(): void {
    this.campaignFormGroup.controls['campaignName'].disable();
  }

  /**
   * getCampaign
   * @param campaignId
   */
  private async getCampaign(campaignId: string) {
    this.spinnerService.show();
    const variable: QueryCampaignArgs = {
      pk: campaignId,
    };
    // call get Campaign
    await firstValueFrom(this.campaignService.getCampaign(variable)).then(
      (res) => {
        const campaign = res.data.campaign;
        if (campaign) {
          this.campaignFormGroup.patchValue({
            campaignName: campaign.name,
            dailyCostAmount: campaign.dailyCostAmount,
            lifetimeCostAmount: campaign.lifetimeCostAmount,
            status: campaign.isActive,
          });
          if (campaign.advertiser) {
            this.advertiserId = campaign.advertiser.id;
          }
          // CampaignForm Change
          this.createCampaignForm(campaign.billingType, campaign.adType, campaign.deliveryType);
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * createCampaignForm
   * @param billingType
   * @param deliveryType
   */
  private createCampaignForm(
    billingType: CampaignBillingType,
    adType: CampaignAdType,
    deliveryType: CampaignDeliveryType
  ): void {
    if (billingType === CampaignBillingType.Normal && adType === CampaignAdType.Type2D) {
      // 2D_Normal_adserver
      this.campaignType = CpTypeNormal2D;
    } else if (billingType === CampaignBillingType.Premium) {
      if (deliveryType === CampaignDeliveryType.AdServer) {
        // Premium_adserver
        this.campaignType = CpTypePremiumAdserver;
        // Premium_Embedded
      } else if (deliveryType === CampaignDeliveryType.Embedded) {
        this.campaignType = CpTypePremiumEmbedded;
      }
    }
  }

  /**
   * Click Save
   * @returns
   */
  public onSave(): void {
    if (this.advertiserId === null) {
      this.showToaster('Invalid Advertiser.');
      return;
    }
    this.spinnerService.show();
    // Create Variable
    const variable: CampaignInputObjectType = {
      id: this.modalEditParam.campaignId,
      advertiserId: this.advertiserId,
      dailyCostAmount: convertStringToNumber(this.dailyCostAmount.value),
      lifetimeCostAmount: convertStringToNumber(this.lifetimeCostAmount.value),
      isActive: this.status.value,
    };
    // updateCampaign
    firstValueFrom(this.campaignService.updateCampaign(variable)).then(
      (res) => {
        if (res.data?.campaignUpdate?.ok) {
          this.showToaster('Editing completed');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Campaign update failed.');
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.spinnerService.hide();
        this.showToaster(excerptErrorMessage(error.message));
      }
    );
  }

  /**
   * Click Cancel
   */
  public onCancel(): void {
    this._dialogRef.close(false);
  }

  /**
   * show message
   * @param message
   * @param button
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * dailyCostAmount FormControl Getter
   */
  get dailyCostAmount(): UntypedFormControl {
    return this.campaignFormGroup.get('dailyCostAmount') as UntypedFormControl;
  }
  /**
   * lifetimeCostAmount FormControl Getter
   */
  get lifetimeCostAmount(): UntypedFormControl {
    return this.campaignFormGroup.get('lifetimeCostAmount') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.campaignFormGroup.get('status') as UntypedFormControl;
  }
}
