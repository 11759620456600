import { gql } from 'apollo-angular';

export const QueryGetNormalReports = gql`
  query performanceReports(
    $startDate: Date!
    $endDate: Date!
    $reportType: ReportType!
    $groups: [GroupingType]
    $filters: [ReportFilterInputObjectType]
    $indicators: [IndicatorType]!
  ) {
    performanceReports(
      startDate: $startDate
      endDate: $endDate
      reportType: $reportType
      groups: $groups
      filters: $filters
      indicators: $indicators
    ) {
      edges {
        node {
          date
          advertiserId
          advertiserName
          campaignId
          campaignName
          flightId
          flightName
          show
          imp
          viewImp
          viewAbility
          click
          video5s
          video15s
          video30s
          engagement
          engRate
          action
          cost
          cpm
          cpe
        }
      }
    }
  }
`;
