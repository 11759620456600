import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsComponent } from './campaigns.component';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';
import { CampaignRegistrationComponent } from './campaign-registration/campaign-registration.component';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CostEditComponent } from './cost-edit/cost-edit.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { CALENDAR_FORMATS } from '../resource/utility/common-util';
import { CustomPickerFormatsDirective } from '../directives/custom-picker-formats/custom-picker-formats.directive';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    CampaignsComponent,
    CampaignRegistrationComponent,
    CampaignEditComponent,
    CostEditComponent,
    CustomPickerFormatsDirective,
  ],
  imports: [CommonModule, MaterialModule, AppRoutingModule, DirectivesModule],
  exports: [CampaignsComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS },
  ],
})
export class CampaignsModule {}
