<table
  mat-table
  id="report-table"
  [dataSource]="performanceData"
  style="width: 100%"
  #performanceTable
>
  <ng-container matColumnDef="Date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element" class="cell-left">{{ element.date }}</td>
  </ng-container>
  <ng-container matColumnDef="Advertiser">
    <th mat-header-cell *matHeaderCellDef style="width: 15%">AdvertiserName</th>
    <td mat-cell *matCellDef="let element" class="cell-left">{{ element.advertiserName }}</td>
  </ng-container>
  <ng-container matColumnDef="Campaign">
    <th mat-header-cell *matHeaderCellDef style="width: 15%">CampaignName</th>
    <td mat-cell *matCellDef="let element" class="cell-left">{{ element.campaignName }}</td>
  </ng-container>
  <ng-container matColumnDef="Flight">
    <th mat-header-cell *matHeaderCellDef style="width: 15%">FlightName</th>
    <td mat-cell *matCellDef="let element" class="cell-left">{{ element.flightName }}</td>
  </ng-container>
  <ng-container matColumnDef="Show">
    <th mat-header-cell *matHeaderCellDef>Show</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.show | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Imp">
    <th mat-header-cell *matHeaderCellDef>Imp</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.imp | number }}</td>
  </ng-container>
  <ng-container matColumnDef="ViewImp">
    <th mat-header-cell *matHeaderCellDef>ViewImp</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.viewImp | number }}</td>
  </ng-container>
  <ng-container matColumnDef="ViewAbility">
    <th mat-header-cell *matHeaderCellDef>ViewAbility</th>
    <td mat-cell *matCellDef="let element" class="cell-right">
      <ng-container *ngIf="element.viewAbility">
        {{ element.viewAbility | number: '1.2-2' }}%
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="Engagement">
    <th mat-header-cell *matHeaderCellDef>Engagement</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.engagement | number }}</td>
  </ng-container>
  <ng-container matColumnDef="EngRate">
    <th mat-header-cell *matHeaderCellDef>EngRate</th>
    <td mat-cell *matCellDef="let element" class="cell-right">
      <ng-container *ngIf="element.engRate">
        {{ element.engRate | number: '1.2-2' }}%
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="Action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.action | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Click">
    <th mat-header-cell *matHeaderCellDef>Click</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.click | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Video5s">
    <th mat-header-cell *matHeaderCellDef>video5s</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.video5s | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Video15s">
    <th mat-header-cell *matHeaderCellDef>video15s</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.video15s | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Video30s">
    <th mat-header-cell *matHeaderCellDef>video30s</th>
    <td mat-cell *matCellDef="let element" class="cell-right">{{ element.video30s | number }}</td>
  </ng-container>
  <ng-container matColumnDef="Cost">
    <th mat-header-cell *matHeaderCellDef>Cost</th>
    <td mat-cell *matCellDef="let element" class="cell-right">
      <ng-container *ngIf="element.cost">
        {{ element.cost | currency: 'JPY' }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="CPM">
    <th mat-header-cell *matHeaderCellDef>CPM</th>
    <td mat-cell *matCellDef="let element" class="cell-right">
      <ng-container *ngIf="element.cpm">
        {{ element.cpm | currency: 'JPY' : true : '1.2-2' }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="CPE">
    <th mat-header-cell *matHeaderCellDef>CPE</th>
    <td mat-cell *matCellDef="let element" class="cell-right">
      <ng-container *ngIf="element.cpe">
        {{ element.cpe | currency: 'JPY' : true : '1.2-2' }}
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="rowColumns; sticky: true" style="background: #f6f8fa"></tr>
  <tr mat-row *matRowDef="let row; columns: rowColumns"></tr>
</table>
