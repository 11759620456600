import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  Mutation,
  OtpRequestInputObjectType,
  OtpTokenObtainInputObjectType,
  TokenRefreshInputObjectType,
} from 'src/app/models/graphql/types';
import { Router } from '@angular/router';
import {
  MutateOtpRequest,
  MutateOtpTokenObtain,
  MutateTokenRefresh,
} from 'src/app/models/graphql/mutation/auth-login.graphql';
import { MutateTokenDiscard } from 'src/app/models/graphql/mutation/token-discard.graphql';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /**
   * constructor
   * @param _router
   * @param apollo
   */
  constructor(
    private _router: Router,
    private apollo: Apollo
  ) {}

  /**
   * otpRequest
   * @param request OtpRequestInputObjectType
   * @returns
   */
  public otpRequest(request: OtpRequestInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutateOtpRequest,
      variables: {
        input: request,
      },
    });
  }

  /**
   * otpTokenObtain
   * @param request OtpTokenObtainInputObjectType
   * @returns
   */
  public otpTokenObtain(request: OtpTokenObtainInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutateOtpTokenObtain,
      variables: {
        input: request,
      },
    });
  }

  /**
   * tokenRefresh
   * @param request TokenRefreshInputObjectType
   * @returns
   */
  public tokenRefresh(request: TokenRefreshInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutateTokenRefresh,
      variables: {
        input: request,
      },
    });
  }

  /**
   * tokenDiscard
   */
  public tokenDiscard() {
    return this.apollo.mutate<Mutation>({
      mutation: MutateTokenDiscard,
    });
  }
}
