import { gql } from 'apollo-angular';

export const QueryGetCampaigns = gql`
  query campaigns(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $isActive: Boolean
    $search: String
    $orderBy: String
    $advertiserId: String
  ) {
    campaigns(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      isActive: $isActive
      search: $search
      orderBy: $orderBy
      advertiserId: $advertiserId
    ) {
      edges {
        node {
          id
          name
          isActive
          billingType
          adType
          deliveryType
          dailyCostAmount
          lifetimeCostAmount
        }
      }
      totalCount
      edgeCount
    }
  }
`;
