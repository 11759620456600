/** ActiveStatus Interface */
export interface ActiveStatus {
  value: boolean;
  label: string;
}
export const ActiveStatusTypes: ActiveStatus[] = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

/** Clickable Interface */
export interface ClickType {
  value: boolean;
  label: string;
}
export const ClickTypes: ClickType[] = [
  { value: true, label: 'Clickable' },
  { value: false, label: 'UnClickable' },
];
