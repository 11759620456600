import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightsComponent } from './flights.component';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';
import { FlightAdserverRegistrationComponent } from './flight-adserver-registration/flight-adserver-registration.component';
import { FlightAdserverEditComponent } from './flight-adserver-edit/flight-adserver-edit.component';
import { FlightEmbeddedRegistrationComponent } from './flight-embedded-registration/flight-embedded-registration.component';
import { FlightEmbeddedEditComponent } from './flight-embedded-edit/flight-embedded-edit.component';
import { CreativeEditComponent } from './creative-edit/creative-edit.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { CALENDAR_FORMATS } from '../resource/utility/common-util';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    FlightsComponent,
    FlightAdserverRegistrationComponent,
    FlightAdserverEditComponent,
    FlightEmbeddedRegistrationComponent,
    FlightEmbeddedEditComponent,
    CreativeEditComponent,
  ],
  imports: [CommonModule, MaterialModule, AppRoutingModule, DirectivesModule],
  exports: [FlightsComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS },
  ],
})
export class FlightsModule {}
