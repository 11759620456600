<div class="container">
  <div class="container-wrapper">
    <mat-card appearance="outlined" style="width: 480px; padding: 16px">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">One-Time Password Authentication</mat-card-title>
        <mat-card-subtitle>Please enter the code provided in the email sent.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="card-contents">
        <form class="reset-form" [formGroup]="oneTimePasswordForm" autocomplete="off">
          <mat-form-field class="form-item-feild">
            <mat-label for="oneTimePassword">OneTime Password</mat-label>
            <input matInput type="text" formControlName="oneTimePassword" maxlength="128" />
            <mat-error *ngIf="oneTimePassword.errors?.['required']"
              >OneTimePassword is required.</mat-error
            >
          </mat-form-field>
        </form>
        <mat-error *ngFor="let err of errors">{{ err }}</mat-error>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <div class="btn-form">
          <button
            type="button"
            mat-raised-button
            class="btn"
            color="primary"
            [disabled]="oneTimePasswordForm.invalid"
            (click)="signIn()"
          >
            Sign in
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
