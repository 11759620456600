import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QueryGetNormalReports } from 'src/app/models/graphql/queries/get-normal-reports.graphql';
import { QueryGetPremiumReports } from 'src/app/models/graphql/queries/get-premium-reports.graphql';
import { Query } from 'src/app/models/graphql/types';
import { ReportCondition, ReportRequest } from 'src/app/models/reports';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private apollo: Apollo) {}

  /**
   * Get Normal Report.
   * @returns
   */
  public getNormalReports(request: ReportRequest) {
    return this.apollo.query<Query>({
      query: QueryGetNormalReports,
      fetchPolicy: 'no-cache',
      variables: {
        startDate: request.startDate,
        endDate: request.endDate,
        reportType: request.reportType,
        groups: request.groups,
        filters: request.filters,
        indicators: request.indicators,
      },
    });
  }

  /**
   * Get Premium Report.
   * @returns
   */
  public getPremiumReports(request: ReportRequest) {
    return this.apollo.query<Query>({
      query: QueryGetPremiumReports,
      fetchPolicy: 'no-cache',
      variables: {
        startDate: request.startDate,
        endDate: request.endDate,
        reportType: request.reportType,
        groups: request.groups,
        filters: request.filters,
        indicators: request.indicators,
      },
    });
  }

  /**
   * Ger ReportCondition.
   * @returns ReportCondition
   */
  public getReportCondition(): ReportCondition | null {
    let reportCondition = null;
    const storage = localStorage.getItem('reportCondition');
    if (storage === null) {
      return reportCondition;
    }
    try {
      reportCondition = JSON.parse(storage) as ReportCondition;
    } catch {
      return reportCondition;
    }
    return reportCondition;
  }
}
