import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsEditComponent } from './reports-edit/reports-edit.component';
import { ReportsResultComponent } from './reports-result/reports-result.component';
import { MaterialModule } from '../material.module';
import { PerformanceReportComponent } from './reports-result/performance-report/performance-report.component';
import { SummaryReportComponent } from './reports-result/summary-report/summary-report.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { CALENDAR_FORMATS } from '../resource/utility/common-util';
import { GraphReportComponent } from './reports-result/graph-report/graph-report.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    ReportsEditComponent,
    ReportsResultComponent,
    PerformanceReportComponent,
    SummaryReportComponent,
    GraphReportComponent,
  ],
  imports: [CommonModule, MaterialModule, NgChartsModule],
  exports: [ReportsEditComponent, ReportsResultComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS },
  ],
})
export class ReportsModule {}
