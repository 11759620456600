<div class="container">
  <div class="container-wrapper">
    <mat-card appearance="outlined" style="width: 480px; padding: 16px">
      <mat-card-header class="card-header">
        <mat-card-title class="card-title">Password Registration</mat-card-title>
        <mat-card-subtitle
          >※At least one of each of the three letters and over 8 character (upper case letters,
          large characters, number)</mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content class="card-contents">
        <form class="reset-form" [formGroup]="passwordForm" autocomplete="off">
          <mat-form-field class="form-item-feild">
            <mat-label for="password">New Password</mat-label>
            <input
              matInput
              [type]="btnPassVisible ? 'password' : 'text'"
              formControlName="password"
              minlength="8"
              maxlength="64"
              required
            />
            <mat-icon class="pointer" matSuffix (click)="btnPassVisible = !btnPassVisible">{{
              btnPassVisible ? 'visibility' : 'visibility_off'
            }}</mat-icon>
            <mat-error *ngIf="password.errors?.['required']">Password is required.</mat-error>
            <mat-error *ngIf="password.errors?.['pattern']">Invalid Password format.</mat-error>
          </mat-form-field>
          <mat-form-field class="form-item-feild">
            <mat-label for="passwordConfirm">New Password Confirm</mat-label>
            <input
              matInput
              [type]="btnConfirmPassVisible ? 'password' : 'text'"
              formControlName="passwordConfirm"
              minlength="8"
              maxlength="64"
              required
            />
            <mat-icon
              class="pointer"
              matSuffix
              (click)="btnConfirmPassVisible = !btnConfirmPassVisible"
              >{{ btnConfirmPassVisible ? 'visibility' : 'visibility_off' }}</mat-icon
            >
            <mat-error *ngIf="passwordConfirm.errors?.['required']"
              >PasswordConfirm is required.</mat-error
            >
            <mat-error *ngIf="passwordConfirm.errors?.['pattern']"
              >Invalid PasswordConfirm format.</mat-error
            >
            <mat-error *ngIf="passwordConfirm.errors?.['mustMatch']"
              >Password and Confirm Password must be match.</mat-error
            >
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <div class="btn-form">
          <button
            type="button"
            mat-raised-button
            class="btn"
            color="primary"
            [disabled]="passwordForm.invalid"
            (click)="passwordUpdate()"
          >
            Registration
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
