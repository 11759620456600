import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CostObjectType } from 'src/app/models/graphql/types';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],
})
export class SummaryReportComponent implements OnInit, OnChanges, OnDestroy {
  /** InputData */
  @Input() public summaryData: CostObjectType[] = [];

  /** summaryReport */
  public summaryReportTables = {
    columns: ['Advertiser', 'Campaign', 'StartDate', 'EndDate', 'BillingMonth', 'Costs'],
    data: [],
  };

  constructor() {}

  ngOnInit(): void {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let summaryChange = changes['summaryData'];
    if (summaryChange.currentValue) {
      this.summaryReportTables.data = summaryChange.currentValue;
    }
  }

  ngOnDestroy(): void {
    return;
  }
}
