import { gql } from 'apollo-angular';

export const QueryGetAdvertiser = gql`
  query advertiser($pk: ID!) {
    advertiser(pk: $pk) {
      id
      name
      isActive
    }
  }
`;
