import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegerOnlyDirective } from './input/integer-only.directive';

@NgModule({
  declarations: [IntegerOnlyDirective],
  imports: [CommonModule],
  exports: [IntegerOnlyDirective],
})
export class DirectivesModule {}
