import { gql } from 'apollo-angular';

export const QueryGetFlightAdserverTypes = gql`
  query flightAdserverTypes($adSizeId: String, $deliveryType: String) {
    granWhaleZones(adSizeId: $adSizeId, deliveryType: $deliveryType) {
      edges {
        node {
          id
          name
        }
      }
    }
    priorities(deliveryType: $deliveryType) {
      edges {
        node {
          id
          name
          goalTypes
        }
      }
    }
    customTargetings(deliveryType: $deliveryType) {
      edges {
        node {
          key
          values
          elementType
          isRequired
          isEditable
        }
      }
    }
  }
`;
