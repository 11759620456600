import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService } from 'src/app/core/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class TemporaryAuthGuard implements CanActivate {
  /**
   * constructor
   * @param userProfileService
   */
  constructor(private userProfileService: UserProfileService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // loginAuth check
    if (!this.userProfileService.isAuthenticated()) {
      this.userProfileService.logout();
      return false;
    }
    return true;
  }
}
