import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Feature, FeatureGroup, FeatureRole } from '../resource/feature-role';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /** isRefreshingToken */
  private isRefreshing = false;
  private isRefreshingSubject$ = new BehaviorSubject(false);
  /** User TokenInfo */
  private userTokenInfo: UserTokenInfo = {
    userId: '',
    email: '',
    name: '',
    role: undefined,
    refreshExp: 0,
    exp: 0,
    origIat: 0,
  };
  /** userFeatures */
  private userFeatures: Feature[] = [];
  /** FeatureMenuSubject */
  public featureMenuSubject$ = new Subject<FeatureGroup[]>();

  /**
   * constructor
   * @param router
   */
  constructor(private router: Router) {}

  /** get Token */
  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  /** get RefreshToken */
  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh');
  }

  /** set Token */
  public setToken(token: string, refreshToken: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refreshToken);
  }

  /** clear Token */
  public clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
  }

  /**
   * TokenDecode
   * @param token
   * @returns
   */
  public tokenDecoded(token: string): boolean {
    try {
      this.userTokenInfo = jwt_decode(token) as UserTokenInfo;
      this.featureDelivery();
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * featureDelivery
   */
  private featureDelivery(): void {
    // get Features available by user role
    this.userFeatures = FeatureRole.getFeatures(this.userTokenInfo.role);
    // deliver featureMenu by role
    const menu = FeatureRole.getDisplayMenus(this.userTokenInfo.role);
    this.featureMenuSubject$.next(menu);
  }

  /**
   * isAuthenticated
   * Store accessToken check
   * @returns
   */
  public isAuthenticated(): boolean {
    let ret = false;
    const token = localStorage.getItem('token');
    if (token && this.tokenDecoded(token)) {
      ret = true;
    }
    return ret;
  }

  /**
   * tokenExpired
   * @param token
   * @returns
   */
  public tokenExpired(token: string): boolean {
    try {
      const tokenExpiry = JSON.parse(atob(token.split('.')[1])).exp as number;
      if (!tokenExpiry) {
        return true;
      }
      return Math.floor(new Date().getTime() / 1000) >= tokenExpiry;
    } catch (error) {
      return true;
    }
  }

  /**
   * refreshTokenExpired
   * @param token
   * @returns
   */
  public refreshTokenExpired(token: string): boolean {
    try {
      const refreshExpiry = JSON.parse(atob(token.split('.')[1])).refreshExp as number;
      if (!refreshExpiry) {
        return true;
      }
      return Math.floor(new Date().getTime() / 1000) >= refreshExpiry;
    } catch (error) {
      return true;
    }
  }

  /** Get user name. */
  public getUserName(): string | null {
    return this.userTokenInfo.name;
  }

  /** Get user id. */
  public getUserId(): string | null {
    return this.userTokenInfo.userId;
  }

  /**
   * getFeatureMenu Observable
   * @returns
   */
  public getFeatureMenu(): Observable<FeatureGroup[]> {
    return this.featureMenuSubject$.asObservable();
  }

  /**
   * getFeatureGroup
   * @returns
   */
  public getFeatureGroup(): FeatureGroup[] {
    const featureGroups = FeatureRole.getFeatureGroups(this.userTokenInfo.role);
    return featureGroups;
  }

  /**
   * isAvailableFeature
   * Whether you have permission to operate
   * @param featureName
   * @returns
   */
  public isAvailableFeature(featureName: Feature): boolean {
    const feature = this.userFeatures.filter((r) => r === featureName);
    if (feature.length === 0) {
      return false;
    }
    return true;
  }

  /**
   * setRefreshing
   * @param refreshing
   */
  public setRefreshing(refreshing: boolean): void {
    if (this.isRefreshing !== refreshing) {
      this.isRefreshingSubject$.next(refreshing);
    }
    this.isRefreshing = refreshing;
  }

  /**
   * getRefreshing
   * @returns
   */
  public getRefreshing(): boolean {
    return this.isRefreshing;
  }

  /**
   * getRefreshSubject Observable
   * @returns
   */
  public getRefreshSubject(): Observable<boolean> {
    return this.isRefreshingSubject$.asObservable();
  }

  /**
   * logout
   */
  public logout(): void {
    this.clearToken();
    this.router.navigate(['/login']);
  }
}

export interface UserTokenInfo {
  userId: string;
  name: string;
  email: string;
  role: string | undefined;
  refreshExp: number;
  exp: number;
  origIat: number;
}
