<h2 mat-dialog-title>Edit Cost</h2>
<mat-dialog-content style="height: 50%">
  <form [formGroup]="formGroup" class="campaign-date-form">
    <div class="flex-row justify-space-between -flex -flex">
      <div class="align-center-center flex-48">
        <mat-form-field style="width: 100%">
          <mat-label>StartDate</mat-label>
          <input matInput [matDatepicker]="datepickerStartDate" formControlName="startDate" />
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="datepickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #datepickerStartDate> </mat-datepicker>
          <mat-error *ngIf="startDate.errors?.['required']">StartDate is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="align-center-center flex-48">
        <mat-form-field style="width: 100%">
          <mat-label>EndDate</mat-label>
          <input
            matInput
            [matDatepicker]="datepickerEndDate"
            [min]="startDate.value"
            formControlName="endDate"
          />
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="datepickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #datepickerEndDate> </mat-datepicker>
          <mat-error *ngIf="endDate.errors?.['required']">EndDate is required.</mat-error>
          <mat-error *ngIf="endDate.errors?.['matDatepickerMin']"
            >The EndDate cannot be earlier than the start date.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </form>
  <form [formGroup]="costTable" class="campaign-cost-form">
    <div class="table-container">
      <table class="cost-table">
        <thead style="background: #f6f8fa; height: 56px">
          <th class="cost-table-th" style="width: 20%">BillingMonth</th>
          <th class="cost-table-th" style="width: 20%" *ngIf="isBillingTypePremium()">
            Cost(&yen;)
          </th>
          <th class="cost-table-th" style="width: 25%">marginRate(%)</th>
          <th class="cost-table-th" style="width: 25%">adjustment(&yen;)</th>
          <th class="cost-table-th" style="width: 10%"></th>
        </thead>
        <tbody>
          <ng-container
            formArrayName="tableRows"
            *ngFor="let group of getFormControls.controls; let i = index"
          >
            <tr [formGroupName]="i">
              <td class="cost-table-td" style="width: 20%">
                <mat-form-field style="width: 100%" appYearMonthPickerFormats>
                  <input
                    matInput
                    matInput
                    [matDatepicker]="picker"
                    placeholder="BillingMonth"
                    formControlName="billingMonth"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker
                    #picker
                    startView="multi-year"
                    (monthSelected)="setBillingMonthAndYear($event, picker, group)"
                  ></mat-datepicker>
                  <mat-hint>YYYY/MM</mat-hint>
                  <mat-error *ngIf="group.get('billingMonth')?.errors?.['required']"
                    >BillingMonth is required.</mat-error
                  >
                </mat-form-field>
              </td>
              <td class="cost-table-td" style="width: 20%" *ngIf="isBillingTypePremium()">
                <mat-form-field style="width: 100%">
                  <input
                    matInput
                    type="number"
                    appIntegerOnly
                    formControlName="costs"
                    min="0"
                    max="2147483647"
                    placeholder="cost"
                  />
                  <mat-hint>{{ group.get('costs')?.value | currency: 'JPY' }}</mat-hint>
                  <mat-error *ngIf="group.get('costs')?.errors?.['required']"
                    >Cost is required.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      group.get('costs')?.errors?.['pattern'] || group.get('costs')?.errors?.['max']
                    "
                    >Invalid Cost format. Valid range is 0 ~ 2147483647</mat-error
                  >
                </mat-form-field>
              </td>
              <td class="cost-table-td" style="width: 25%">
                <div class="flex-row -flex">
                  <div class="align-center-center flex-20 -flex">
                    <mat-radio-group
                      formControlName="enabledMarginRate"
                      (change)="onSelectedMarginRate($event, i)"
                    >
                      <mat-radio-button
                        class="clickable-radio-button"
                        [value]="true"
                      ></mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="align-center-center flex-80">
                    <mat-form-field style="width: 100%">
                      <mat-label>MarginRate</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="marginRate"
                        placeholder="0.01~100.00"
                      />
                      <mat-error *ngIf="group.get('marginRate')?.errors?.['oneRequired']"
                        >MarginRate is required.</mat-error
                      >
                      <mat-error
                        *ngIf="
                          group.get('marginRate')?.errors?.['pattern'] ||
                          group.get('marginRate')?.errors?.['max']
                        "
                        >Invalid MarginRate format. Valid range is 0.00 ~ 100.00</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td class="cost-table-td" style="width: 25%">
                <div class="flex-row -flex">
                  <div class="align-center-center flex-20 -flex">
                    <mat-radio-group
                      formControlName="enabledAdjustment"
                      (change)="onSelectedAdjustment($event, i)"
                    >
                      <mat-radio-button
                        class="clickable-radio-button"
                        [value]="true"
                      ></mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="align-center-center flex-80 -flex">
                    <mat-form-field style="width: 100%">
                      <mat-label>Adjustment</mat-label>
                      <input
                        matInput
                        type="number"
                        appIntegerOnly
                        formControlName="adjustment"
                        min="0"
                        max="2147483647"
                        placeholder="10000"
                      />
                      <mat-hint>{{ group.get('adjustment')?.value | currency: 'JPY' }}</mat-hint>
                      <mat-error *ngIf="group.get('adjustment')?.errors?.['oneRequired']"
                        >Adjustment is required.</mat-error
                      >
                      <mat-error
                        *ngIf="
                          group.get('adjustment')?.errors?.['pattern'] ||
                          group.get('adjustment')?.errors?.['max']
                        "
                        >Invalid Adjustment format. Valid range is 0 ~ 2147483647</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td class="cost-table-td" style="width: 10%">
                <button type="button" mat-icon-button color="warn" (click)="deleteRow(i)">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </tr>
            <mat-error *ngIf="group.errors?.['oneRequired']"
              >Either MarginRate or Adjustment is required.</mat-error
            >
          </ng-container>
        </tbody>
      </table>
      <div class="row-add-btn">
        <button type="button" mat-stroked-button type="button" color="primary" (click)="addRow()">
          add row
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button class="btn" color="basic" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!isValidSave()"
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-actions>
