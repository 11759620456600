<h2 mat-dialog-title>Edit Creative</h2>
<mat-dialog-content style="height: 50%">
  <form [formGroup]="creativeFormGroup" class="creative-form">
    <mat-form-field style="width: 100%; display: none">
      <input matInput formControlName="creativeSignedUrl" />
    </mat-form-field>
    <mat-radio-group
      formControlName="clickable"
      (change)="onChangeClickType($event)"
      aria-labelledby="clickable-radio-group-label"
    >
      <mat-radio-button
        class="clickable-radio-button"
        *ngFor="let clickType of clickTypes"
        [value]="clickType.value"
        >{{ clickType.label }}</mat-radio-button
      >
    </mat-radio-group>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Creative Click URL</mat-label>
      <textarea matInput formControlName="clickUrl" maxlength="2048" rows="3"></textarea>
      <mat-error *ngIf="clickUrl.errors?.['required']">clickURL is required.</mat-error>
      <mat-error *ngIf="clickUrl.errors?.['pattern']">Invalid URL format.</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="isCreativeTypeVideo()" style="width: 100%" appearance="fill">
      <mat-label>Video Playback ID</mat-label>
      <input matInput formControlName="videoPlaybackId" maxlength="256" />
      <mat-error *ngIf="videoPlaybackId.errors?.['required']"
        >videoPlaybackId is required.</mat-error
      >
      <mat-error *ngIf="videoPlaybackId.errors?.['pattern']"
        >Invalid videoPlaybackId format.</mat-error
      >
    </mat-form-field>
    <ng-container *ngIf="isCreativeTypeImage()">
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>ImageURL<span style="color: red">*</span></mat-label>
        <input matInput formControlName="imageUrl" />
      </mat-form-field>
      <input
        type="file"
        id="uploadFile"
        style="display: none"
        (change)="onSelectUploadFile(fileInput.files)"
        accept=".jpg, .jpeg, .png"
        #fileInput
      />
      <button
        mat-raised-button
        color="primary"
        class="file-select-button"
        (click)="onClickFileInputButton()"
      >
        <mat-icon style="font-size: 18px"
          ><span class="material-symbols-outlined">add_photo_alternate</span></mat-icon
        >Select File
      </button>
      <span *ngIf="!file?.name && !creativeId" style="color: dimgrey">
        Please select an image file.</span
      >
      <span style="color: dimgrey"> {{ file?.name }}</span>
      <mat-error *ngIf="fileSizeError">{{ fileSizeError }}</mat-error>
      <div id="preview">
        <img [src]="imageSrc" alt="" />
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button color="basic" class="btn" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        *ngIf="!creativeId"
        [disabled]="!isValidRegistration()"
        (click)="onRegistration()"
      >
        Regist
      </button>
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        *ngIf="creativeId"
        [disabled]="!isValidSave()"
        (click)="onSave()"
      >
        Save
      </button>
    </div>
  </div>
</mat-dialog-actions>
