/**
 * Interface CampaignTypesForm
 */
export interface CampaignTypesForm {
  dailyCostAmount: boolean;
  lifetimeCostAmount: boolean;
  isActive: boolean;
}

/** Normal2D */
export const CpTypeNormal2D: CampaignTypesForm = {
  dailyCostAmount: true,
  lifetimeCostAmount: true,
  isActive: true,
};
/** PremiumEmbedded */
export const CpTypePremiumEmbedded: CampaignTypesForm = {
  dailyCostAmount: false,
  lifetimeCostAmount: false,
  isActive: true,
};
/** PremiumAdserver */
export const CpTypePremiumAdserver: CampaignTypesForm = {
  dailyCostAmount: true,
  lifetimeCostAmount: false,
  isActive: true,
};
