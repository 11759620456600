import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AdvertiserInputObjectType } from 'src/app/models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import {
  excerptErrorMessage,
  getDecodeId,
  REGEX_ADVERTISER_NAME,
} from 'src/app/resource/utility/common-util';
import { AdvertiserService } from 'src/app/services/advertisers/advertiser.service';

/** Modal Parameter */
export interface AdvertiserEditParam {
  id: string | null;
}

@Component({
  selector: 'app-advertiser-edit',
  templateUrl: './advertiser-edit.component.html',
  styleUrls: ['./advertiser-edit.component.scss'],
})
export class AdvertiserEditComponent implements OnInit {
  /** Title */
  public formTitle = 'New Advertiser';
  public displayAdvertiseId: string | undefined;
  /** FormGroup */
  public formGroup = this._formBuilder.group({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_ADVERTISER_NAME),
    ]),
    status: new UntypedFormControl(null, [Validators.required]),
  });

  /** Status Types */
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  public isSubmitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editParam: AdvertiserEditParam,
    private _dialogRef: MatDialogRef<AdvertiserEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private advertiserService: AdvertiserService
  ) {
    // New
    if (this.editParam.id === null) {
      this.formTitle = 'New Advertiser';
      this.formGroup.controls['status'].setValue(true);
      this.formGroup.controls['status'].disable();
    } else {
      // Edit
      this.formTitle = 'Edit Advertiser';
      this.displayAdvertiseId = getDecodeId(this.editParam.id);
      this.getAdvertiser(this.editParam.id);
    }
  }

  ngOnInit(): void {
    return;
  }

  /**
   * getAdvertiser
   * @param advertiserId
   */
  private async getAdvertiser(advertiserId: string) {
    this.spinnerService.show();
    await firstValueFrom(this.advertiserService.getAdvertiser(advertiserId)).then(
      (res) => {
        const advertiser = res.data.advertiser;
        if (advertiser) {
          this.formGroup.patchValue({
            name: advertiser.name,
            status: advertiser.isActive,
          });
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * Advertiser Registration
   */
  public async onRegistration(): Promise<void> {
    if (this.formGroup.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.spinnerService.show();
      // Create Variable
      const variable: AdvertiserInputObjectType = {
        name: this.name.value,
        isActive: this.status.value,
      };
      try {
        // createAdvertiser
        const res = await firstValueFrom(this.advertiserService.createAdvertiser(variable));

        if (res.data?.advertiserCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Advertiser registration failed.');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.showToaster(excerptErrorMessage(error.message));
        }
      } finally {
        this.spinnerService.hide();
        this.isSubmitting = false;
      }
    }
  }

  /**
   * Advertiser Save
   */
  public onSave(): void {
    if (this.formGroup.valid && this.editParam.id !== null) {
      this.spinnerService.show();
      // Create Variable
      const variable: AdvertiserInputObjectType = {
        id: this.editParam.id,
        name: this.name.value,
        isActive: this.status.value,
      };
      // updateAdvertiser
      firstValueFrom(this.advertiserService.updateAdvertiser(variable)).then(
        (res) => {
          if (res.data?.advertiserUpdate?.ok) {
            this.showToaster('Editing completed');
            this._dialogRef.close(true);
          } else {
            this.showToaster('Advertiser update failed.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.showToaster(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * Cancel(Close)
   */
  public onCancel(): void {
    this._dialogRef.close(false);
  }

  /**
   * show message
   * @param message
   * @param button
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * name FormControl Getter
   */
  get name(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }
  /**
   * status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.formGroup.get('status') as UntypedFormControl;
  }
}
