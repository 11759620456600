import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { v4 as uuidv4 } from 'uuid';
import {
  FilterItems,
  IndicatorItem2DNormal,
  IndicatorItem2DPremium,
  IndicatorItem3DPremium,
  ReportCondition,
} from 'src/app/models/reports';
import { MatTable } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import { FilterType, GroupingType, IndicatorType, ReportType } from 'src/app/models/graphql/types';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { FlightService } from 'src/app/services/flights/flight.service';
import { AdvertiserService } from 'src/app/services/advertisers/advertiser.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { excerptErrorMessage } from 'src/app/resource/utility/common-util';

@Component({
  selector: 'app-reports-edit',
  templateUrl: './reports-edit.component.html',
  styleUrls: ['./reports-edit.component.scss'],
})
export class ReportsEditComponent implements OnInit {
  /** Edit mode for the screen */
  public isEditMode: boolean = false;

  /** ReportType PullDownItem */
  public reportTypes: ReportType[] = [
    ReportType.Normal2D,
    ReportType.Premium2D,
    ReportType.Premium3D,
  ];

  /** Grouping checkbox */
  public selectedGroupingTypes: GroupingType[] = [];
  public groupingTypes: GroupingType[] = [
    GroupingType.Date,
    GroupingType.Advertiser,
    GroupingType.Campaign,
    GroupingType.Flight,
  ];

  /** ReportFilterKey PullDownItem */
  public reportFilterKeys: FilterType[] = [
    FilterType.Advertiser,
    FilterType.Campaign,
    FilterType.Flight,
  ];

  /** Display metrics for the report */
  public indicatorTypes: IndicatorType[] = [];
  public selectedIndicatorTypes: IndicatorType[] = [];

  /**
   * Report aggregation period.
   * Start date: 7 days ago.
   * End date: 1 day ago.
   */
  private readonly durationDay = 7;
  private defaultEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
  private defaultStartDate = new Date(new Date().setDate(new Date().getDate() - this.durationDay));

  /** Report creation form */
  public reportForm = new UntypedFormGroup({
    reportId: new UntypedFormControl(uuidv4()),
    reportName: new UntypedFormControl('', [Validators.required]),
    reportType: new UntypedFormControl('', [Validators.required]),
    groups: new UntypedFormControl(this.selectedGroupingTypes),
    startDate: new UntypedFormControl(moment(this.defaultStartDate), [Validators.required]),
    endDate: new UntypedFormControl(moment(this.defaultEndDate), [Validators.required]),
    indicators: new UntypedFormControl(this.selectedIndicatorTypes),
  });

  /** Filter form */
  public filterForm = new UntypedFormGroup({
    key: new UntypedFormControl(''),
    value: new UntypedFormControl(''),
  });

  /** Filter Pulldown */
  public filterValueItems: { id: string; name: string }[] = [];
  public filterOriginalValueItems: { id: string; name: string }[] = [];

  /** Filter */
  @ViewChild('filterTable', { static: true }) filterTable: MatTable<FilterItems> | null = null;
  public filterColumns: string[] = ['filter', 'value', 'delete'];
  public filterList: FilterItems[] = [];
  /** FilterLoading */
  public isFilterLoading = false;

  /** ErrorMessage */
  public errors: string[] = [];

  /**
   * constructor
   * @param router
   * @param activeRoute
   * @param reportService
   */
  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private reportService: ReportService,
    private campaignService: CampaignService,
    private flightService: FlightService,
    private advertiserService: AdvertiserService
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['id']) {
        this.isEditMode = true;
      }
    });
  }

  ngOnInit(): void {
    // Run grapeQL's BE authentication for advertisers setting.
    this.filterForm.patchValue({ key: 'Advertiser' });
    this.getAdvertisers().finally(() => this.unlockFilterData());

    // In edit mode, retrieve save conditions.
    if (this.isEditMode) {
      const condition = this.reportService.getReportCondition();
      if (condition === null) {
        this._snackBar.open('Failed to get the report conditions. Please edit again.', 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
        return;
      }
      // Generate metric items.
      this.onChangeReportType(condition.reportType.toString());
      this.indicatorTypes.forEach((indicatorType) => {
        condition.indicators.forEach((savedIndicator) => {
          if (savedIndicator === indicatorType) {
            this.onIndicatorItemSelect(savedIndicator, true);
          }
        });
      });
      // Generate grouping.
      this.groupingTypes.forEach((grouping) => {
        condition.groups.forEach((savedGroup) => {
          if (savedGroup === grouping) {
            this.onGroupingItemSelect(savedGroup, true);
          }
        });
      });
      // Reflect parameters to the form.
      this.reportForm.patchValue({
        reportId: condition.reportId,
        reportName: condition.reportName,
        reportType: condition.reportType,
        startDate: moment(new Date(condition.startDate)),
        endDate: moment(new Date(condition.endDate)),
        groups: this.selectedGroupingTypes,
        indicators: this.selectedIndicatorTypes,
      });
      this.filterList = condition.filters;
    }
    return;
  }

  /**
   * Create report conditions.
   * @returns
   */
  public create(): void {
    // error init.
    this.errors.splice(0);
    if (this.reportForm.valid) {
      // Indicator Required Check
      if (this.selectedIndicatorTypes.length === 0) {
        this.errors.push('Indicators is required');
        return;
      }
      const condition: ReportCondition = {
        reportId: this.reportId.value,
        reportName: this.reportName.value,
        startDate: new Date(this.startDate.value).toDateString(),
        endDate: new Date(this.endDate.value).toDateString(),
        reportType: this.reportType.value,
        groups: this.groups.value,
        indicators: this.indicators.value,
        filters: this.filterList,
      };
      // Save the conditions.
      localStorage.setItem('reportCondition', JSON.stringify(condition));
      this._router.navigateByUrl('/reports/result');
      return;
    }
  }

  /**
   * Get advertiser info.
   */
  private async getAdvertisers() {
    this.filterValueItems.splice(0);
    this.filterForm.controls['value'].disable();
    await firstValueFrom(this.advertiserService.getAdvertisers()).then(
      (res) => {
        res.data.advertisers?.edges.forEach((advertiser) => {
          if (advertiser && advertiser.node) {
            this.filterValueItems.push({ id: advertiser.node.id, name: advertiser.node.name });
          }
        });
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
      }
    );
  }

  /**
   * Get campaign info.
   */
  private async getCampaigns() {
    this.filterValueItems.splice(0);
    await firstValueFrom(this.campaignService.getCampaigns()).then(
      (res) => {
        res.data.campaigns?.edges.forEach((campaign) => {
          if (campaign && campaign.node) {
            this.filterValueItems.push({ id: campaign.node.id, name: campaign.node.name });
          }
        });
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
      }
    );
  }

  /**
   * Get flight info.
   */
  private async getFlights() {
    this.filterValueItems.splice(0);
    await firstValueFrom(this.flightService.getFlights()).then(
      (res) => {
        res.data.flights?.edges.forEach((flight) => {
          if (flight && flight.node) {
            this.filterValueItems.push({ id: flight.node.id, name: flight.node.name });
          }
        });
      },
      (error: Error) => {
        this._snackBar.open(excerptErrorMessage(error.message), 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
      }
    );
  }

  /**
   * Report type selection event.
   */
  public onChangeReportType(reportType: string): void {
    this.selectedIndicatorTypes.splice(0);
    switch (reportType) {
      case ReportType.Normal2D:
        this.indicatorTypes = IndicatorItem2DNormal;
        break;
      case ReportType.Premium2D:
        this.indicatorTypes = IndicatorItem2DPremium;
        break;
      case ReportType.Premium3D:
        this.indicatorTypes = IndicatorItem3DPremium;
        break;
      default:
        break;
    }
  }

  /**
   * Group checkbox selection event.
   * @param key
   * @param isChecked
   */
  public onGroupingItemSelect(key: GroupingType, isChecked: boolean) {
    if (isChecked) {
      this.selectedGroupingTypes.push(key);
    } else {
      const index = this.selectedGroupingTypes.findIndex((e) => e === key);
      if (index !== -1) {
        this.selectedGroupingTypes.splice(index, 1);
      }
    }
  }

  /**
   * Indicator checkbox selection event.
   * @param key
   * @param isChecked
   */
  public onIndicatorItemSelect(key: IndicatorType, isChecked: boolean) {
    if (isChecked) {
      this.selectedIndicatorTypes.push(key);
    } else {
      const index = this.selectedIndicatorTypes.findIndex((e) => e === key);
      if (index !== -1) {
        this.selectedIndicatorTypes.splice(index, 1);
      }
    }
  }
  /**
   * Select/deselect all indicator checkboxes.
   * @param indicatorTypes
   * @param isChecked
   */
  public onIndicatorItemSelectAll(indicatorTypes: IndicatorType[], isChecked: boolean): void {
    this.selectedIndicatorTypes.splice(0);
    if (isChecked) {
      for (const key of indicatorTypes) {
        this.selectedIndicatorTypes.push(key);
      }
    }
  }
  /** Determines if some indicators are selected.*/
  public someIndicator(): boolean {
    let ret = true;
    if (this.selectedIndicatorTypes.length === 0) {
      ret = false;
    }
    if (this.selectedIndicatorTypes.length === this.indicatorTypes.length) {
      ret = false;
    }
    return ret;
  }
  /**
   * IndicatorON/OFF
   * @param indicatorType
   * @returns
   */
  public checkIndicatorItem(indicatorType: IndicatorType): boolean {
    for (let selectedIndicatorType of this.selectedIndicatorTypes) {
      if (selectedIndicatorType === indicatorType) {
        return true;
      }
    }
    return false;
  }
  /**
   * IndicatorON/OFF
   * @param indicatorType
   * @returns
   */
  public checkIndicatorAll(indicatorType: IndicatorType[]): boolean {
    let ret = false;
    if (indicatorType.length === 0) {
      return ret;
    }
    if (indicatorType.length === this.selectedIndicatorTypes.length) {
      ret = true;
    }
    return ret;
  }

  /** FilterKeyChange event */
  public onChangeFilterKey(): void {
    this.filterForm.patchValue({ value: '' });
    switch (this.key.value) {
      case FilterType.Advertiser:
        this.lockFilterForm();
        this.getAdvertisers().finally(() => this.unlockFilterData());
        break;
      case FilterType.Campaign:
        this.lockFilterForm();
        this.getCampaigns().finally(() => this.unlockFilterData());
        break;
      case FilterType.Flight:
        this.lockFilterForm();
        this.getFlights().finally(() => this.unlockFilterData());
        break;
      default:
        break;
    }
  }

  /**
   * lockFilterForm
   * Lock Form while loading filters
   */
  private lockFilterForm(): void {
    this.filterForm.controls['key'].disable();
    this.filterForm.controls['value'].disable();
  }
  /**
   * unlockFilterData
   * Unlock when filter loading is complete
   */
  private unlockFilterData(): void {
    this.filterForm.controls['key'].enable();
    this.filterForm.controls['value'].enable();
  }

  /** Add Filter row */
  public filterAddRow(): void {
    for (let filter of this.filterList) {
      if (filter.key === this.key.value && filter.value === this.value.value.id) {
        return;
      }
    }
    // Push FilterList
    const filter: FilterItems = {
      key: this.key.value,
      value: this.value.value.id,
      displayName: this.value.value.name,
    };
    this.filterList.push(filter);
    this.filterTable?.renderRows();

    // Filter Input init
    this.key.setValue('');
    this.value.setValue('');
    this.filterValueItems.splice(0);
  }
  /** Delete Filter row */
  public filterDeleteRow(row: number): void {
    this.filterList.splice(row, 1);
    this.filterTable?.renderRows();
  }

  /**
   * Filter item input check.
   * @returns
   */
  public filterInputCheck(): boolean {
    let ret = false;
    if (this.key.value === '' || this.value.value === '') {
      ret = true;
    }
    return ret;
  }

  /**
   * GroupingON/OFF
   * @param grouping
   * @returns
   */
  public checkGroupingItem(grouping: GroupingType): boolean {
    for (let selectedGroupingType of this.selectedGroupingTypes) {
      if (selectedGroupingType === grouping) {
        return true;
      }
    }
    return false;
  }

  /**
   * reportName FormControl Getter
   */
  get reportId(): UntypedFormControl {
    return this.reportForm.get('reportId') as UntypedFormControl;
  }
  /**
   * reportName FormControl Getter
   */
  get reportName(): UntypedFormControl {
    return this.reportForm.get('reportName') as UntypedFormControl;
  }
  /**
   * reporType FormControl Getter
   */
  get reportType(): UntypedFormControl {
    return this.reportForm.get('reportType') as UntypedFormControl;
  }
  /**
   * startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.reportForm.get('startDate') as UntypedFormControl;
  }
  /**
   * endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.reportForm.get('endDate') as UntypedFormControl;
  }
  /**
   * grouping FormControl Getter
   */
  get groups(): UntypedFormControl {
    return this.reportForm.get('groups') as UntypedFormControl;
  }
  /**
   * indicators FormControl Getter
   */
  get indicators(): UntypedFormControl {
    return this.reportForm.get('indicators') as UntypedFormControl;
  }

  /**
   * Filter.Key FormControl Getter
   */
  get key(): UntypedFormControl {
    return this.filterForm.get('key') as UntypedFormControl;
  }
  /**
   * Filter.Key FormControl Getter
   */
  get value(): UntypedFormControl {
    return this.filterForm.get('value') as UntypedFormControl;
  }
}
