<div class="flex-row -flex" style="width: 100%; height: 100%">
  <div class="align-start-stretch flex-210px" [hidden]="isDisabled">
    <app-sidenav></app-sidenav>
  </div>
  <div class="align-start-stretch flex-grow -flex'">
    <div class="flex-column -flex" style="width: 100%; height: 100%">
      <div class="align-start-stretch" [hidden]="isDisabled">
        <app-header style="width: 100%"></app-header>
      </div>
      <div class="align-start-stretch flex-grow" style="overflow-y: auto">
        <app-spinner></app-spinner>
        <router-outlet></router-outlet>
      </div>
      <div class="align-start-stretch" [hidden]="isDisabled">
        <app-footer style="width: 100%"></app-footer>
      </div>
    </div>
  </div>
</div>
