import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FilterItems, ReportRequest } from 'src/app/models/reports';
import { ReportService } from 'src/app/services/reports/report.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GroupingType,
  IndicatorType,
  ReportFilterInputObjectType,
  PerformanceReportObjectType,
  ReportType,
  CostObjectType,
} from 'src/app/models/graphql/types';
import { SpinnerService } from 'src/app/core/spinner.service';
import { DatePipe } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-reports-result',
  templateUrl: './reports-result.component.html',
  styleUrls: ['./reports-result.component.scss'],
  providers: [DatePipe],
})
export class ReportsResultComponent implements OnInit {
  /** report tabs */
  public reportTabs: string[] = [];
  public selectedReportTab: number = 0;
  /** PerformanceReportsData */
  public reportPerformanceData: PerformanceReportObjectType[] = [];
  /** SummaryReportsData */
  public reportSummaryData: CostObjectType[] = [];

  /** condition initial value */
  public conditionItem = {
    reportId: '',
    reportName: '',
    reportType: '',
    startDate: '',
    endDate: '',
  };
  public groups: GroupingType[] | undefined = undefined;
  public filters: FilterItems[] | undefined = undefined;
  public indicators: IndicatorType[] | undefined = undefined;

  private element: HTMLElement;

  /**
   * Constructor
   * @param router
   * @param elementRef
   * @param reportService
   * @param _snackBar
   * @param spinnerService
   */
  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private reportService: ReportService,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private datePipe: DatePipe
  ) {
    this.element = this.elementRef.nativeElement;
  }

  public ngOnInit(): void {
    const condition = this.reportService.getReportCondition();
    if (condition === null) {
      this._snackBar.open('Failed to get the report conditions. Please edit again.', 'close', {
        verticalPosition: 'top',
        panelClass: ['error-snack-bar'],
      });
      return;
    }
    this.conditionItem.reportId = condition.reportId;
    this.conditionItem.reportName = condition.reportName;
    this.conditionItem.reportType = condition.reportType;
    this.conditionItem.startDate = condition.startDate;
    this.conditionItem.endDate = condition.endDate;
    this.indicators = condition.indicators;
    this.groups = this.sortGroups(condition.groups);
    this.filters = condition.filters;

    // Filtering Format
    const requestFilter: ReportFilterInputObjectType[] = [];
    for (let filter of condition.filters) {
      const filters = requestFilter.find((e) => e.key === filter.key);
      if (filters) {
        filters.value.push(filter.value);
      } else {
        requestFilter.push({ key: filter.key, value: new Array<string>(filter.value) });
      }
    }
    const startDate = this.datePipe.transform(condition.startDate, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(condition.endDate, 'yyyy-MM-dd');
    if (startDate == null || endDate == null) {
      this._snackBar.open('Date Format Error', 'close', {
        verticalPosition: 'top',
        panelClass: ['error-snack-bar'],
      });
      return;
    }

    // Create Param
    const request: ReportRequest = {
      startDate: startDate,
      endDate: endDate,
      reportType: condition.reportType,
      groups: this.groups,
      indicators: condition.indicators,
      filters: requestFilter,
    };

    // Get Report
    if (condition.reportType === ReportType.Normal2D) {
      // Get Normal Report
      this.reportTabs = ['Performance'];
      this.getNormalReport(request);
    } else if (
      condition.reportType === ReportType.Premium2D ||
      condition.reportType === ReportType.Premium3D
    ) {
      // Get Premium Report
      this.reportTabs = ['Summary', 'Performance'];
      this.getPremiumReport(request);
    }
    return;
  }

  /**
   * Navigate to the report edit screen.
   * @returns
   */
  public editReport(): void {
    this.router.navigate(['/reports/edit'], { queryParams: { id: this.conditionItem.reportId } });
    return;
  }

  /**
   * selectedTabChange
   * @param event MatTabChangeEvent
   */
  public selectedTabChange(event: MatTabChangeEvent) {
    this.selectedReportTab = event.index;
  }

  /**
   * Get Normal Report
   */
  private async getNormalReport(request: ReportRequest) {
    // reportData Init.
    this.reportPerformanceData = [];
    this.spinnerService.show();
    await firstValueFrom(this.reportService.getNormalReports(request)).then(
      (res) => {
        // Normal PerformanceReport
        const performanceReports: PerformanceReportObjectType[] = [];
        res.data.performanceReports?.edges.forEach((edge) => {
          if (edge && edge.node) {
            performanceReports.push(edge.node as PerformanceReportObjectType);
          }
        });
        this.reportPerformanceData = performanceReports;
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(error.message, 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Get Premium Report
   */
  private async getPremiumReport(request: ReportRequest) {
    // reportData Init.
    this.reportSummaryData = [];
    this.reportPerformanceData = [];
    this.spinnerService.show();
    await firstValueFrom(this.reportService.getPremiumReports(request)).then(
      (res) => {
        // Premium PerformanceReport
        const performanceReports: PerformanceReportObjectType[] = [];
        res.data.performanceReports?.edges.forEach((edge) => {
          if (edge && edge.node) {
            performanceReports.push(edge.node);
          }
          return;
        });
        this.reportPerformanceData = performanceReports;
        // Premium SummaryReport
        const premiumReports: CostObjectType[] = [];
        res.data.premiumReports?.edges.map((edge) => {
          if (edge && edge.node) {
            return premiumReports.push(edge.node);
          }
          return;
        });
        this.reportSummaryData = premiumReports as CostObjectType[];
        this.spinnerService.hide();
      },
      (error: Error) => {
        this._snackBar.open(error.message, 'close', {
          verticalPosition: 'top',
          panelClass: ['error-snack-bar'],
        });
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Export as CSV.
   */
  public downloadCSV(id: string): void {
    const label = this.reportTabs[this.selectedReportTab];
    const tableData = this.element.querySelector(id) as HTMLTableElement;
    var csvData = '';
    if (tableData) {
      for (var i = 0; i < tableData.rows.length; i++) {
        for (var j = 0; j < tableData.rows[i].cells.length; j++) {
          csvData += `"${tableData.rows[i].cells[j].innerText}"`;
          if (j == tableData.rows[i].cells.length - 1) {
            csvData += '\n';
          } else {
            csvData += ',';
          }
        }
      }
    }

    const fileName = `${this.conditionItem.reportName}_${label}.csv`;
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, <any>csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link: HTMLAnchorElement = this.element.querySelector(
      '#csv-donwload'
    ) as HTMLAnchorElement;
    link.href = url;
    link.download = fileName;
    link.click();
    return;
  }

  /**
   * Sort Groups
   * Date > Campaign > Advertiser > Flight
   */
  private sortGroups(groupTypes: GroupingType[]): GroupingType[] {
    const sortResult: GroupingType[] = [];
    const date = groupTypes.find((e) => e === GroupingType.Date);
    if (date) {
      sortResult.push(date);
    }
    const advertiser = groupTypes.find((e) => e === GroupingType.Advertiser);
    if (advertiser) {
      sortResult.push(advertiser);
    }
    const campaign = groupTypes.find((e) => e === GroupingType.Campaign);
    if (campaign) {
      sortResult.push(campaign);
    }
    const flight = groupTypes.find((e) => e === GroupingType.Flight);
    if (flight) {
      sortResult.push(flight);
    }
    return sortResult;
  }

  /**
   * isDisplayGraph
   */
  public isDisplayGraph(): boolean {
    let ret = false;
    if (this.groups) {
      ret = this.groups.includes(GroupingType.Date);
    }
    return ret;
  }
}
