<div class="container">
  <div class="flex-row -flex" style="height: 100%; padding: 5px; box-sizing: border-box">
    <div class="align-start-stretch flex-20">
      <mat-card appearance="outlined" style="height: 100%; border-radius: unset">
        <mat-card-header class="card-header">
          <mat-card-title class="card-title">Filter</mat-card-title>
        </mat-card-header>
        <mat-divider inset></mat-divider>
        <mat-card-content>
          <form style="padding: 10px 0" [formGroup]="formFilterGroup">
            <mat-form-field style="width: 100%">
              <mat-label>Name</mat-label>
              <input
                matInput
                (keyup.enter)="applyFilter()"
                formControlName="name"
                maxlength="256"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select (selectionChange)="applyFilter()" formControlName="status">
                <mat-option [value]="''">All</mat-option>
                <mat-option
                  *ngFor="let activeStatusType of activeStatusTypes"
                  [value]="activeStatusType.value"
                  >{{ activeStatusType.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button color="basic" (click)="onFilterReset()">reset</button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="align-start-stretch flex-80">
      <mat-card
        appearance="outlined"
        class="-block"
        style="height: 100%; width: auto; border-radius: unset"
      >
        <mat-card-header class="flex-row -flex">
          <div class="align-start-center flex-row flex-1-0-50 -flex">
            <mat-card-title>
              <span *ngIf="campaign" style="word-break: break-all; line-height: 24px"
                >List Flight for {{ campaign.name }}</span
              >
            </mat-card-title>
          </div>
          <div class="align-start-center flex-row flex-0-1-auto -flex">
            <button
              *ngIf="registrationButtonEnabled()"
              mat-flat-button
              color="primary"
              style="height: 36px"
              (click)="onAddFlight()"
            >
              <mat-icon>add</mat-icon>Create a new flight
            </button>
            <div style="padding-right: 30px"></div>
            <button mat-stroked-button color="basic" style="height: 36px" (click)="onRefresh()">
              <mat-icon>refresh</mat-icon>refresh
            </button>
          </div>
        </mat-card-header>
        <mat-card-content style="width: 100%; height: 100%; display: inline">
          <mat-paginator
            (page)="handlePageEvent($event)"
            [length]="pageLength"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[10, 30, 50, 100]"
            showFirstLastButtons="true"
            aria-label="Select page"
          >
          </mat-paginator>
          <div class="table-container">
            <table
              mat-table
              [dataSource]="flightList"
              matSort
              (matSortChange)="sortByColumns($event)"
              style="width: 100%; table-layout: fixed"
            >
              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">Status</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.isActive">
                    <span>Active</span>
                  </ng-container>
                  <ng-container *ngIf="!element.isActive">
                    <span>Inactive</span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">ID</th>
                <td mat-cell *matCellDef="let element">{{ getDecodeId(element.id) }}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40%">
                  FlightName
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">StartDate</th>
                <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
              </ng-container>
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">EndDate</th>
                <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef style="width: 5%"></th>
                <td mat-cell *matCellDef="let element">
                  <button
                    *ngIf="editButtonEnabled()"
                    mat-icon-button
                    color="primary"
                    (click)="onEditFlight(element)"
                  >
                    <mat-icon style="font-size: 19px">edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="creative">
                <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-stroked-button
                    *ngIf="!isEmbedded(element.campaign) && editCreativeButtonEnabled()"
                    color="primary"
                    (click)="onEditCreative(element)"
                  >
                    Creative
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
                style="background: #f6f8fa"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
