import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MutateCampaignCreate } from 'src/app/models/graphql/mutation/create-campaign.graphql';
import { MutateCampaignUpdate } from 'src/app/models/graphql/mutation/update-campaign.graphql';
import { MutateCostsUpdate } from 'src/app/models/graphql/mutation/update-costs.graphql';
import { QueryGetCampaign } from 'src/app/models/graphql/queries/get-campaign.graphql';
import { QueryGetCampaigns } from 'src/app/models/graphql/queries/get-campaigns.graphql';
import { QueryGetCosts } from 'src/app/models/graphql/queries/get-costs.graphql';
import {
  CampaignInputObjectType,
  CostsInputObjectType,
  Mutation,
  MutationCampaignCreateArgs,
  MutationCampaignUpdateArgs,
  MutationCostsUpdateArgs,
  Query,
  QueryCampaignArgs,
  QueryCampaignsArgs,
  QueryCostsArgs,
} from 'src/app/models/graphql/types';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private apollo: Apollo) {}

  /**
   * get Campaigns
   * @returns
   */
  public getCampaigns(variables?: QueryCampaignsArgs) {
    return this.apollo.query<Query, QueryCampaignsArgs>({
      query: QueryGetCampaigns,
      fetchPolicy: 'no-cache',
      variables: {
        offset: variables?.offset,
        first: variables?.first,
        after: variables?.after,
        last: variables?.last,
        before: variables?.before,
        isActive: variables?.isActive,
        orderBy: variables?.orderBy,
        search: variables?.search,
        advertiserId: variables?.advertiserId,
      },
    });
  }

  /**
   * Create Campaign
   * @param input CampaignInputObjectType
   * @returns
   */
  public createCampaign(input: CampaignInputObjectType) {
    return this.apollo.mutate<Mutation, MutationCampaignCreateArgs>({
      mutation: MutateCampaignCreate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * get Campaign
   * @returns
   */
  public getCampaign(variables: QueryCampaignArgs) {
    return this.apollo.query<Query, QueryCampaignArgs>({
      query: QueryGetCampaign,
      fetchPolicy: 'no-cache',
      variables: {
        pk: variables.pk,
      },
    });
  }

  /**
   * Update Campaign
   * @param input CampaignInputObjectType
   * @returns
   */
  public updateCampaign(input: CampaignInputObjectType) {
    return this.apollo.mutate<Mutation, MutationCampaignUpdateArgs>({
      mutation: MutateCampaignUpdate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * get Costs
   * @returns
   */
  public getCosts(campaignId: string) {
    return this.apollo.query<Query, QueryCostsArgs>({
      query: QueryGetCosts,
      fetchPolicy: 'no-cache',
      variables: {
        campaignId: campaignId,
      },
    });
  }

  /**
   * Update Cost
   * @param input CostsInputObjectType
   * @returns
   */
  public updateCosts(input: CostsInputObjectType) {
    return this.apollo.mutate<Mutation, MutationCostsUpdateArgs>({
      mutation: MutateCostsUpdate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
