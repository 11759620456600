import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { FlightEditModalParams } from 'src/app/models/flight';
import {
  CampaignObjectType,
  CustomTargetingConfigConnection,
  CustomTargetingConfigInputObjectType,
  ElementType,
  FlightInputObjectType,
  Maybe,
  QueryFlightArgs,
} from 'src/app/models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import { convertStringToNumber, excerptErrorMessage } from 'src/app/resource/utility/common-util';
import { FlightService } from 'src/app/services/flights/flight.service';

@Component({
  selector: 'app-flight-embedded-edit',
  templateUrl: './flight-embedded-edit.component.html',
  styleUrls: ['./flight-embedded-edit.component.scss'],
  providers: [DatePipe],
})
export class FlightEmbeddedEditComponent implements OnInit {
  /** FlightObject */
  public campaign: CampaignObjectType | undefined;
  /** Type PullDowns Enum*/
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  public addedCustomTargetingFormControllNames: string[] = [];

  /** FlightFormGroup */
  public flightFormGroup = this._formBuilder.group({
    creativeType: new UntypedFormControl('', [Validators.required]),
    adSize: new UntypedFormControl('', [Validators.required]),
    flightName: new UntypedFormControl('', [Validators.required]),
    priority: new UntypedFormControl('', [Validators.required]),
    zone: new UntypedFormControl('', [Validators.required]),
    rate: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl(''),
    goalType: new UntypedFormControl('', [Validators.required]),
    goalAmount: new UntypedFormControl('', [Validators.required]),
    capType: new UntypedFormControl('', [Validators.required]),
    dailyCapAmount: new UntypedFormControl('', [Validators.required]),
    lifeTimeCapAmount: new UntypedFormControl('', [Validators.required]),
    status: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  });

  /**
   * Constructor
   * @param modalEditParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param flightService
   * @param datePipe
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalEditParam: FlightEditModalParams,
    private _dialogRef: MatDialogRef<FlightEmbeddedEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private flightService: FlightService,
    private datePipe: DatePipe
  ) {
    // Get Flight
    this.setFlightAndCustomTargetingForms(this.modalEditParam.flightId);
  }

  ngOnInit(): void {
    this.flightFormGroup.controls['flightName'].disable();
  }

  /**
   * Click Save button
   */
  public onSave(): void {
    if (this.flightFormGroup.valid && this.campaign) {
      this.spinnerService.show();
      // Update Arg
      const variable: FlightInputObjectType = {
        id: this.modalEditParam.flightId,
        campaignId: this.campaign.id,
        creativeType: this.creativeType.value,
        adSizeId: this.adSize.value,
        name: this.flightName.value,
        isActive: this.status.value,
        startDate: this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.endDate.value, 'yyyy-MM-dd'),
        priorityId: this.priority.value,
        zoneIds: this.zone.value,
        rateType: this.rate.value,
        goalType: this.goalType.value,
        goalAmount: parseInt(this.goalAmount.value),
        capType: this.capType.value,
        price: this.price.value,
        dailyCapAmount: convertStringToNumber(this.dailyCapAmount.value),
        lifetimeCapAmount: convertStringToNumber(this.lifeTimeCapAmount.value),
        customTargetings: this.getCustomTargetingRequestData(),
        deliveryType: this.campaign.deliveryType,
      };

      // updateFlight
      firstValueFrom(this.flightService.updateFlight(variable)).then(
        (res) => {
          if (res.data?.flightUpdate?.ok) {
            this.showToaster('Completion of update');
            this._dialogRef.close(true);
          } else {
            this.showToaster('Flight update failed.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.showToaster(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * Click Cancel button
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * getFlight
   * @param flightId
   */
  private async setFlightAndCustomTargetingForms(flightId: string) {
    this.spinnerService.show();
    const variable: QueryFlightArgs = {
      pk: flightId,
    };
    // call get Campaign
    await firstValueFrom(this.flightService.getFlight(variable)).then(
      (res) => {
        const flight = res.data.flight;
        if (flight) {
          this.campaign = flight.campaign;
          this.flightFormGroup.patchValue({
            creativeType: flight.creativeType,
            adSize: flight.adSize.id,
            flightName: flight.name,
            startDate: flight.startDate,
            endDate: flight.endDate,
            zone: flight.zoneIds,
            priority: flight.priority.id,
            capType: flight.capType,
            rate: flight.rateType,
            price: flight.price,
            goalType: flight.goalType,
            goalAmount: flight.goalAmount,
            dailyCapAmount: flight.dailyCapAmount,
            lifeTimeCapAmount: flight.lifetimeCapAmount,
            status: flight.isActive,
          });
          const flightCustomTargetings = flight.customTargetings;
          // In embedded mode, don't display the customTargeting form and do not update it; store as is.
          this.generateFormFields(flightCustomTargetings);
          this.patchCustomTargetingsRegistredValues(flightCustomTargetings);
        }

        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  private getCustomTargetingRequestData(): CustomTargetingConfigInputObjectType[] {
    const formControls = this.addedCustomTargetingFormControllNames;
    const requestData: CustomTargetingConfigInputObjectType[] = [];

    formControls.forEach((controlName) => {
      const control = this.flightFormGroup.controls[controlName];
      if (Array.isArray(control.value)) {
        requestData.push({
          key: controlName,
          values: control.value,
        });
      } else {
        requestData.push({
          key: controlName,
          values: control.value != null ? [control.value] : [],
        });
      }
    });

    return requestData;
  }

  /**
   * generate form
   * @param customTargeting
   */
  private generateFormFields(customTargetings: Maybe<CustomTargetingConfigConnection> | undefined) {
    customTargetings?.edges.forEach((edges) => {
      if (edges && edges.node) {
        const customTargeting = edges.node;
        // add customTargeting form
        if (!this.flightFormGroup.contains(customTargeting.key)) {
          // Retain customTargeting items added for reset purposes
          this.addedCustomTargetingFormControllNames.push(customTargeting.key);

          if (customTargeting.elementType === ElementType.CheckBox) {
            this.flightFormGroup.addControl(customTargeting.key, new UntypedFormControl([]));
          } else if (
            customTargeting.elementType === ElementType.RadioButton ||
            customTargeting.elementType === ElementType.TextBox
          ) {
            this.flightFormGroup.addControl(customTargeting.key, new UntypedFormControl(''));
          }
        }
      }
    });
  }

  private patchCustomTargetingsRegistredValues(
    customTargetings: Maybe<CustomTargetingConfigConnection> | undefined
  ): void {
    customTargetings?.edges.forEach((edges) => {
      if (edges && edges.node) {
        const customTargeting = edges.node;
        if (customTargeting.key) {
          if (customTargeting.elementType === ElementType.CheckBox) {
            this.flightFormGroup.patchValue({
              [customTargeting.key]: customTargeting.values,
            });
          } else {
            this.flightFormGroup.patchValue({
              [customTargeting.key]: customTargeting.values?.[0],
            });
          }
        }
      }
    });
  }

  /**
   * flightFormGroup.creativeType FormControl Getter
   */
  get creativeType(): UntypedFormControl {
    return this.flightFormGroup.get('creativeType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.adSize FormControl Getter
   */
  get adSize(): UntypedFormControl {
    return this.flightFormGroup.get('adSize') as UntypedFormControl;
  }

  /**
   * flightFormGroup.flightName FormControl Getter
   */
  get flightName(): UntypedFormControl {
    return this.flightFormGroup.get('flightName') as UntypedFormControl;
  }
  /**
   * flightFormGroup.priority FormControl Getter
   */
  get priority(): UntypedFormControl {
    return this.flightFormGroup.get('priority') as UntypedFormControl;
  }
  /**
   * flightFormGroup.zone FormControl Getter
   */
  get zone(): UntypedFormControl {
    return this.flightFormGroup.get('zone') as UntypedFormControl;
  }
  /**
   * flightFormGroup.rate FormControl Getter
   */
  get rate(): UntypedFormControl {
    return this.flightFormGroup.get('rate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.price FormControl Getter
   */
  get price(): UntypedFormControl {
    return this.flightFormGroup.get('price') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalType FormControl Getter
   */
  get goalType(): UntypedFormControl {
    return this.flightFormGroup.get('goalType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalAmount FormControl Getter
   */
  get goalAmount(): UntypedFormControl {
    return this.flightFormGroup.get('goalAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.capType FormControl Getter
   */
  get capType(): UntypedFormControl {
    return this.flightFormGroup.get('capType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.dailyCapAmount FormControl Getter
   */
  get dailyCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('dailyCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.lifeTimeCapAmount FormControl Getter
   */
  get lifeTimeCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('lifeTimeCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.flightFormGroup.get('status') as UntypedFormControl;
  }
  /**
   * flightFormGroup.startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.flightFormGroup.get('startDate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.flightFormGroup.get('endDate') as UntypedFormControl;
  }
}
