import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MutateCreativeCreate } from 'src/app/models/graphql/mutation/create-creative.graphql';
import { MutateCreativeUpdate } from 'src/app/models/graphql/mutation/update-creative.graphql';
import { QueryGetCreativeSignedURL } from 'src/app/models/graphql/queries/get-creative-signed-url.graphql';
import { QueryGetCreative } from 'src/app/models/graphql/queries/get-creative.graphql';
import {
  CreativeInputObjectType,
  Mutation,
  MutationCreativeCreateArgs,
  MutationCreativeUpdateArgs,
  Query,
  QueryCreativeArgs,
  QueryCreativeSignedUrlArgs,
} from 'src/app/models/graphql/types';

@Injectable({
  providedIn: 'root',
})
export class CreativeService {
  constructor(private apollo: Apollo) {}

  /**
   * get Creative
   * @returns
   */
  public getCreative(variables: QueryCreativeArgs) {
    return this.apollo.query<Query, QueryCreativeArgs>({
      query: QueryGetCreative,
      fetchPolicy: 'no-cache',
      variables: {
        flightId: variables.flightId,
      },
    });
  }

  /**
   * get CreativeSignedURL
   * @returns
   */
  public getCreativeSignedURL(variables: QueryCreativeSignedUrlArgs) {
    return this.apollo.query<Query, QueryCreativeSignedUrlArgs>({
      query: QueryGetCreativeSignedURL,
      fetchPolicy: 'no-cache',
      variables: {
        fileName: variables.fileName,
      },
    });
  }

  /**
   * Create Creative
   * @param variables CreativeInputObjectType
   * @returns
   */
  public createCreative(input: CreativeInputObjectType) {
    return this.apollo.mutate<Mutation, MutationCreativeCreateArgs>({
      mutation: MutateCreativeCreate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Update Creative
   * @param variables CreativeInputObjectType
   * @returns
   */
  public updateCreative(input: CreativeInputObjectType) {
    return this.apollo.mutate<Mutation, MutationCreativeUpdateArgs>({
      mutation: MutateCreativeUpdate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Upload ImageFile
   * @param url signedURL
   * @param file File
   * @returns
   */
  public async fileUpload(url: string, file: File): Promise<void> {
    const response = await fetch(url, {
      headers: {
        'Content-Type': file.type,
      },
      method: 'PUT',
      body: file,
    });
    if (response.ok) {
      return Promise.resolve();
    }
    return Promise.reject();
  }
}
