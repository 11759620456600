import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, map, startWith } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { Flight2DCreativeTypes, FlightCreateModalParams } from 'src/app/models/flight';
import {
  AdSizeObjectType,
  CampaignBillingType,
  CapType,
  CreativeType,
  CustomTargetingConfigInputObjectType,
  CustomTargetingConfigObjectType,
  ElementType,
  FlightInputObjectType,
  GoalType,
  Maybe,
  PriorityObjectType,
  RateType,
  ZoneObjectType,
} from 'src/app/models/graphql/types';
import {
  convertStringToNumber,
  excerptErrorMessage,
  REGEX_CUSTOM_TARGETING_TEXT_BOX,
  REGEX_FLIGHT_NAME,
} from 'src/app/resource/utility/common-util';
import { FlightService } from 'src/app/services/flights/flight.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import { AdSizeService } from 'src/app/services/ad-sizes/ad-size.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-flight-adserver-registration',
  templateUrl: './flight-adserver-registration.component.html',
  styleUrls: ['./flight-adserver-registration.component.scss'],
  providers: [DatePipe],
})
export class FlightAdserverRegistrationComponent implements OnInit {
  @ViewChild('zoneInputRef', { static: false }) zoneInputRef!: ElementRef<HTMLInputElement>;
  /** Type PullDowns Enum*/
  public creativeTypes: CreativeType[] = Flight2DCreativeTypes;
  public rateTypes: RateType[] = Object.values(RateType);
  public goalTypes: GoalType[] = [];
  public capTypes: CapType[] = Object.values(CapType);
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  public isSubmitting = false;

  /** Type PullDown from GraphQL */
  public adSizeTypes: Pick<AdSizeObjectType, 'id' | 'name'>[] = [];
  public zoneTypes: Pick<ZoneObjectType, 'id' | 'name'>[] = [];
  public zoneOptions: Pick<ZoneObjectType, 'id' | 'name'>[] = [];
  public priorityTypes: Pick<PriorityObjectType, 'id' | 'name' | 'goalTypes'>[] = [];

  public readonly radioButton = ElementType.RadioButton;
  public readonly textBox = ElementType.TextBox;
  public readonly checkBox = ElementType.CheckBox;

  public sortedCustomTargetings: Pick<
    CustomTargetingConfigObjectType,
    'key' | 'values' | 'elementType' | 'isRequired'
  >[] = [];
  public addedCustomTargetingFormControllNames: string[] = [];

  /** FlightGeneralFormGroup */
  public flightGeneralFormGroup = this._formBuilder.group({
    creativeType: new UntypedFormControl('', [Validators.required]),
    adSize: new UntypedFormControl('', [Validators.required]),
  });

  /** FlightFormGroup */
  public flightFormGroup = this._formBuilder.group({
    friendlyName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_FLIGHT_NAME),
    ]),
    flightName: new UntypedFormControl(''),
    priority: new UntypedFormControl('', [Validators.required]),
    // zone: new UntypedFormControl('', [Validators.required]),
    zoneSearch: new UntypedFormControl(''),
    selectedZones: new UntypedFormControl('', [Validators.required]),
    rate: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    goalType: new UntypedFormControl('', [Validators.required]),
    goalAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    capType: new UntypedFormControl('', [Validators.required]),
    dailyCapAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    lifeTimeCapAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    status: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  });

  private isCreatedCustomTargetings = false;

  @ViewChild(MatAutocompleteTrigger)
  autocomplete!: MatAutocompleteTrigger;

  /**
   * Constructor
   * @param modalCreateParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param flightService
   * @param datePipe
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalCreateParam: FlightCreateModalParams,
    private _dialogRef: MatDialogRef<FlightAdserverRegistrationComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private adSizeService: AdSizeService,
    private flightService: FlightService,
    private datePipe: DatePipe
  ) {
    // Get SizeTypes
    this.getAdSizeTypes();
  }
  lastFilter = '';
  ngOnInit(): void {
    this.flightFormGroup.controls['flightName'].disable();
    this.flightFormGroup.controls['status'].setValue(false);
    this.flightFormGroup.controls['status'].disable();
    this.flightFormGroup.controls['rate'].setValue(RateType.Cpm);
    this.flightFormGroup.controls['rate'].disable();

    this.flightFormGroup.controls['zoneSearch'].valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : this.lastFilter)),
        map((filter) => this.onFilterZoneOptions(filter))
      )
      .subscribe((data) => {
        this.zoneOptions = data;
      });
  }

  public onFilterZoneOptions(value: string): Pick<AdSizeObjectType, 'id' | 'name'>[] {
    const filterValue = value.toLowerCase();
    return this.zoneTypes.filter((z) => z.name.toLowerCase().includes(filterValue));
  }

  public removeZone(zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    // this.selectedZones = this.selectedZones.filter((z) => z.id !== zone.id);
    const currentSelected: Pick<AdSizeObjectType, 'id' | 'name'>[] = this.selectedZones.value;
    this.flightFormGroup.patchValue({
      selectedZones: currentSelected.filter((z) => z.id !== zone.id),
    });
  }

  public addZone(zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    const currentSelected = this.selectedZones.value;
    this.flightFormGroup.patchValue({ selectedZones: [...currentSelected, zone] });
  }

  optionClicked(event: Event, zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    event.stopPropagation();
    const inputField: HTMLInputElement | null = document.getElementById(
      'zone-input-field'
    ) as HTMLInputElement;
    if (inputField) {
      inputField?.focus();
      inputField.value = '';
    }
    // this.zoneSearch.setValue('');
    // this.zoneInputRef.nativeElement.value = '';
    // this.zoneInputRef.nativeElement.focus();
    if (this.zoneAlreadySelected(zone)) {
      this.removeZone(zone);
    } else {
      this.addZone(zone);
    }
  }

  displayFn = (option: Pick<AdSizeObjectType, 'id' | 'name'>): string => {
    return 'welcome';
  };

  zoneAlreadySelected(option: Pick<AdSizeObjectType, 'id' | 'name'>) {
    return this.selectedZones.value.some(
      (z: Pick<AdSizeObjectType, 'id' | 'name'>) => z.id === option.id
    );
  }

  /**
   * StepperSelectionChange
   */
  public selectionChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 1) {
      this.onNext();
    }
  }
  /**
   * Click Next button
   */
  public onNext(): void {
    this.resetFlightForm();
    if (this.flightGeneralFormGroup.valid) {
      this.setFlightAdserverTypesAndCustomTargetingForms();
      // FlightName AutoFill
      this.flightFormGroup.controls['flightName'].setValue(this.createFlightName());
    }
  }

  /** Reset FlightForm  */
  private resetFlightForm(): void {
    this.flightFormGroup.patchValue({
      friendlyName: '',
      priority: '',
      zone: [],
      selectedZones: [],
      price: '',
      goalAmount: '',
      capType: '',
      dailyCapAmount: '',
      lifeTimeCapAmount: '',
      startDate: '',
      endDate: '',
    });

    // Also reset the dynamic component of custom targeting
    this.addedCustomTargetingFormControllNames.forEach((name) => {
      this.flightFormGroup.get(name)?.reset();
    });

    // individual setting
    if (this.modalCreateParam.billingType === CampaignBillingType.Premium) {
      this.goalTypes = [GoalType.Percentage];
      this.flightFormGroup.controls['goalType'].setValue(GoalType.Percentage);
      this.flightFormGroup.controls['goalType'].disable();
      this.flightFormGroup.controls['goalAmount'].setValue(100);
      this.flightFormGroup.controls['goalAmount'].disable();
    } else if (this.modalCreateParam.billingType === CampaignBillingType.Normal) {
      this.flightFormGroup.controls['goalType'].setValue(GoalType.Impressions);
      this.flightFormGroup.controls['goalType'].enable();
    }
  }

  /**
   * Click Regist button
   */
  public async onRegistration(): Promise<void> {
    if (this.flightFormGroup.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.spinnerService.show();
      // Create Arg
      const variable: FlightInputObjectType = {
        campaignId: this.modalCreateParam.campaignId,
        creativeType: this.creativeType.value,
        adSizeId: this.adSize.value.id,
        name: this.createFlightName(),
        isActive: this.status.value,
        startDate: this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.endDate.value, 'yyyy-MM-dd'),
        priorityId: this.priority.value.id,
        zoneIds: this.selectedZones.value.map((zone: Pick<ZoneObjectType, 'id' | 'name'>) => {
          return zone.id;
        }),
        rateType: this.rate.value,
        price: convertStringToNumber(this.price.value),
        goalType: this.goalType.value,
        goalAmount: parseInt(this.goalAmount.value),
        capType: this.capType.value,
        dailyCapAmount: convertStringToNumber(this.dailyCapAmount.value),
        lifetimeCapAmount: convertStringToNumber(this.lifeTimeCapAmount.value),
        customTargetings: this.getCustomTargetingRequestData(),
        deliveryType: this.modalCreateParam.deliveryType,
      };
      console.log(variable);
      try {
        // createFlight
        const res = await firstValueFrom(this.flightService.createFlight(variable));

        if (res.data?.flightCreate?.ok) {
          this.showToaster('Completion of registration');
          this._dialogRef.close(true);
        } else {
          this.showToaster('Flight registration failed.');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.showToaster(excerptErrorMessage(error.message));
        }
      } finally {
        this.spinnerService.hide();
        this.isSubmitting = false;
      }
    }
  }

  /**
   * Click Cancel button
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * get AdSizeTypes
   */
  private async getAdSizeTypes() {
    this.adSizeTypes = [];
    this.spinnerService.show();
    // call get adSizes
    await firstValueFrom(this.adSizeService.getAdSizes()).then(
      (res) => {
        const adSizes = res.data.adSizes;
        if (adSizes) {
          adSizes.edges.map((edge) => {
            if (edge && edge.node) {
              this.adSizeTypes.push(edge.node);
              return;
            }
            return;
          });
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * get FlightAdserverTypes
   * set FlightAdserverTypes and CustomTargetingForms
   */
  private async setFlightAdserverTypesAndCustomTargetingForms() {
    // reset types
    this.clearTypes();
    this.spinnerService.show();
    // call get adserverTypes-pull-down data.
    await firstValueFrom(
      this.flightService.getFlightAdserverTypes(
        this.modalCreateParam.deliveryType,
        this.adSize.value.id
      )
    ).then(
      (res) => {
        const zones = res.data.granWhaleZones;
        if (zones) {
          zones.edges.map((edge) => {
            if (edge && edge.node) {
              this.zoneTypes.push(edge.node);
              return;
            }
            return;
          });
        }
        this.zoneOptions = this.zoneTypes;
        this.flightFormGroup.patchValue({ selectedZones: [] });
        const priorities = res.data.priorities;
        if (priorities) {
          priorities.edges.map((edge) => {
            if (edge && edge.node) {
              this.priorityTypes.push(edge.node);
              return;
            }
            return;
          });
        }

        if (!this.isCreatedCustomTargetings) {
          this.isCreatedCustomTargetings = true;
          const customTargetings = res.data.customTargetings;
          if (customTargetings) {
            // 1. Extract only the `node` from the `edges` array.
            const nodes: Maybe<CustomTargetingConfigObjectType>[] = customTargetings.edges.reduce<
              Maybe<CustomTargetingConfigObjectType>[]
            >((acc, edge) => {
              if (edge && edge.node) {
                acc.push(edge.node);
              }
              return acc;
            }, []);

            // 2. Sort `node` based on `elementType`.
            const sortedNodes = nodes.sort((a, b) => {
              const aType = a!.elementType;
              const bType = b!.elementType;

              const aOrder = this.getElementOrder(aType);
              const bOrder = this.getElementOrder(bType);

              return aOrder - bOrder;
            });

            // 3. Generate form fields using the sorted node.
            sortedNodes.map((node) => {
              if (node) {
                this.sortedCustomTargetings.push(node);
                return;
              }
            });
            this.generateFormFields(sortedNodes);
          }
        }

        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * clearTypes
   * reset Pull-Down Data
   */
  public clearTypes(): void {
    this.zoneTypes = [];
    this.priorityTypes = [];
  }

  /**
   * FlightName autofill
   * @returns
   */
  public createFlightName(): string {
    let ret = '';
    ret += `${this.creativeType.value.toLowerCase()}_`;
    ret += `${this.adSize.value.name}_`;
    ret += this.friendlyName.value;
    return ret;
  }

  /**
   * onChangePriorityType
   * Get GoalType from Priority
   */
  public onChangePriorityType(): void {
    this.goalTypes = Object.values(this.priority.value.goalTypes);
    if (this.modalCreateParam.billingType === CampaignBillingType.Normal) {
      this.goalType.setValue(null);
    }
  }

  public camelToKebabCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  }

  private getCustomTargetingRequestData(): CustomTargetingConfigInputObjectType[] {
    const formControls = this.addedCustomTargetingFormControllNames;
    const requestData: CustomTargetingConfigInputObjectType[] = [];

    formControls.forEach((controlName) => {
      const control = this.flightFormGroup.controls[controlName];
      if (Array.isArray(control.value)) {
        requestData.push({
          key: controlName,
          values: control.value,
        });
      } else {
        requestData.push({
          key: controlName,
          values: [control.value],
        });
      }
    });

    return requestData;
  }

  /**
   * generate form
   * @param customTargeting
   */
  private generateFormFields(customTargetings: Array<Maybe<CustomTargetingConfigObjectType>>) {
    customTargetings.forEach((customTargeting) => {
      if (customTargeting) {
        // Add form control
        if (!this.flightFormGroup.contains(customTargeting.key)) {
          // Retain customTargeting items added for reset
          this.addedCustomTargetingFormControllNames.push(customTargeting.key);

          // Add here if it supports other elementTypes
          if (customTargeting.isRequired) {
            if (
              customTargeting.elementType === ElementType.CheckBox ||
              customTargeting.elementType === ElementType.RadioButton
            ) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl([], [Validators.required])
              );
            } else if (customTargeting.elementType === ElementType.TextBox) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl(
                  [],
                  [Validators.required, Validators.pattern(REGEX_CUSTOM_TARGETING_TEXT_BOX)]
                )
              );
            }
          } else {
            if (
              customTargeting.elementType === ElementType.CheckBox ||
              customTargeting.elementType === ElementType.RadioButton
            ) {
              this.flightFormGroup.addControl(customTargeting.key, new UntypedFormControl([]));
            } else if (customTargeting.elementType === ElementType.TextBox) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl([], [Validators.pattern(REGEX_CUSTOM_TARGETING_TEXT_BOX)])
              );
            }
          }
        }
      }
    });
  }

  private getElementOrder(elementType: string): number {
    switch (elementType) {
      case 'TextBox':
        return 2;
      case 'RadioButton':
        return 3; //Place radio at the end as it takes up space
      default:
        return 1; //checkbox is 1
    }
  }

  /**
   * flightGeneralFormGroup.creativeType FormControl Getter
   */
  get creativeType(): UntypedFormControl {
    return this.flightGeneralFormGroup.get('creativeType') as UntypedFormControl;
  }
  /**
   * flightGeneralFormGroup.adSize FormControl Getter
   */
  get adSize(): UntypedFormControl {
    return this.flightGeneralFormGroup.get('adSize') as UntypedFormControl;
  }

  /**
   * flightFormGroup.friendlyName FormControl Getter
   */
  get friendlyName(): UntypedFormControl {
    return this.flightFormGroup.get('friendlyName') as UntypedFormControl;
  }
  /**
   * flightFormGroup.priority FormControl Getter
   */
  get priority(): UntypedFormControl {
    return this.flightFormGroup.get('priority') as UntypedFormControl;
  }
  /**
   * flightFormGroup.zone FormControl Getter
   */
  get zone(): UntypedFormControl {
    return this.flightFormGroup.get('zone') as UntypedFormControl;
  }

  /**
   * flightFormGroup.zone FormControl Getter
   */
  get zoneSearch(): UntypedFormControl {
    return this.flightFormGroup.get('zoneSearch') as UntypedFormControl;
  }

  get selectedZones(): UntypedFormControl {
    return this.flightFormGroup.get('selectedZones') as UntypedFormControl;
  }
  /**
   * flightFormGroup.rate FormControl Getter
   */
  get rate(): UntypedFormControl {
    return this.flightFormGroup.get('rate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.price FormControl Getter
   */
  get price(): UntypedFormControl {
    return this.flightFormGroup.get('price') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalType FormControl Getter
   */
  get goalType(): UntypedFormControl {
    return this.flightFormGroup.get('goalType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalAmount FormControl Getter
   */
  get goalAmount(): UntypedFormControl {
    return this.flightFormGroup.get('goalAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.capType FormControl Getter
   */
  get capType(): UntypedFormControl {
    return this.flightFormGroup.get('capType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.dailyCapAmount FormControl Getter
   */
  get dailyCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('dailyCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.lifeTimeCapAmount FormControl Getter
   */
  get lifeTimeCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('lifeTimeCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.customTargetings[i].key FormControl Getter
   */
  get customTargetings(): UntypedFormControl[] {
    const customTargetingsFormControls: UntypedFormControl[] = [];

    this.sortedCustomTargetings.forEach(
      (
        customTargeting: Pick<
          CustomTargetingConfigObjectType,
          'key' | 'values' | 'elementType' | 'isRequired'
        >
      ) => {
        customTargetingsFormControls.push(
          this.flightFormGroup.get(customTargeting.key) as UntypedFormControl
        );
      }
    );
    return customTargetingsFormControls;
  }
  /**
   * flightFormGroup.status FormControl Getter
   */
  get status(): UntypedFormControl {
    return this.flightFormGroup.get('status') as UntypedFormControl;
  }
  /**
   * flightFormGroup.startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.flightFormGroup.get('startDate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.flightFormGroup.get('endDate') as UntypedFormControl;
  }
}
