import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MutateFlightCreate } from 'src/app/models/graphql/mutation/create-flight.graphql';
import { MutateFlightUpdate } from 'src/app/models/graphql/mutation/update-flight.graphql';
import { QueryGetFlightAdserverTypes } from 'src/app/models/graphql/queries/get-flight-adserver-types';
import { QueryGetFlightEmbeddedTypes } from 'src/app/models/graphql/queries/get-flight-embedded-types.graphql';
import { QueryGetFlight } from 'src/app/models/graphql/queries/get-flight.graphql';
import { QueryGetFlights } from 'src/app/models/graphql/queries/get-flights.graphql';
import {
  Query,
  QueryFlightsArgs,
  FlightInputObjectType,
  Mutation,
  MutationFlightCreateArgs,
  QueryFlightArgs,
} from 'src/app/models/graphql/types';

@Injectable({
  providedIn: 'root',
})
export class FlightService {
  constructor(private apollo: Apollo) {}

  /**
   * get Flights
   * @returns
   */
  public getFlights(variables?: QueryFlightsArgs) {
    return this.apollo.query<Query, QueryFlightsArgs>({
      query: QueryGetFlights,
      fetchPolicy: 'no-cache',
      variables: {
        offset: variables?.offset,
        first: variables?.first,
        after: variables?.after,
        last: variables?.last,
        before: variables?.before,
        isActive: variables?.isActive,
        orderBy: variables?.orderBy,
        search: variables?.search,
        campaignId: variables?.campaignId,
      },
    });
  }

  /**
   * get Flight
   * @returns
   */
  public getFlight(variables: QueryFlightArgs) {
    return this.apollo.query<Query, QueryFlightArgs>({
      query: QueryGetFlight,
      fetchPolicy: 'no-cache',
      variables: {
        pk: variables.pk,
      },
    });
  }

  /**
   * Create Flight
   * @param variables FlightInputObjectType
   * @returns
   */
  public createFlight(input: FlightInputObjectType) {
    return this.apollo.mutate<Mutation, MutationFlightCreateArgs>({
      mutation: MutateFlightCreate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * update Flight
   * @param variables FlightInputObjectType
   * @returns
   */
  public updateFlight(input: FlightInputObjectType) {
    return this.apollo.mutate<Mutation, MutationFlightCreateArgs>({
      mutation: MutateFlightUpdate,
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * get FlightAdserverTypes
   * @returns
   */
  public getFlightAdserverTypes(deliveryType?: String, adSizeId?: string) {
    return this.apollo.query<Query>({
      query: QueryGetFlightAdserverTypes,
      fetchPolicy: 'no-cache',
      variables: {
        deliveryType: deliveryType,
        adSizeId: adSizeId,
      },
    });
  }

  /**
   * get FlightEmbeddedTypes
   * @returns
   */
  public getFlightEmbeddedTypes(deliveryType?: String, adSizeId?: string) {
    return this.apollo.query<Query>({
      query: QueryGetFlightEmbeddedTypes,
      fetchPolicy: 'no-cache',
      variables: {
        deliveryType: deliveryType,
        adSizeId: adSizeId,
      },
    });
  }
}
