import { gql } from 'apollo-angular';

export const QueryGetCreativeSignedURL = gql`
  query creativeSignedUrl($fileName: String!) {
    creativeSignedUrl(fileName: $fileName) {
      signedUrl
      fileUploadPath
    }
  }
`;
