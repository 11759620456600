import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { UserProfileService } from 'src/app/core/user-profile.service';
import { OtpTokenObtainInputObjectType } from 'src/app/models/graphql/types';
import { excerptErrorMessage } from 'src/app/resource/utility/common-util';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-login-otp-auth',
  templateUrl: './login-otp-auth.component.html',
  styleUrls: ['./login-otp-auth.component.scss'],
})
export class LoginOtpAuthComponent implements OnInit, OnDestroy {
  /**
   * one-time password FormGroup
   */
  public oneTimePasswordForm = this._formBuilder.group({
    email: new UntypedFormControl('', [Validators.required]),
    oneTimePassword: new UntypedFormControl('', [Validators.required]),
  });
  /** errorlist */
  public errors: string[] = [];

  /**
   * constructor
   * @param _formBuilder
   * @param _router
   * @param loginService
   * @param spinnerService
   * @param userProfileService
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private loginService: LoginService,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    const email = localStorage.getItem('email');
    if (!email) {
      this._router.navigateByUrl('/login');
      return;
    }
    this.oneTimePasswordForm.controls['email'].setValue(email);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('email');
  }

  /**
   * signIn
   * click signIn button
   */
  public async signIn() {
    if (this.oneTimePasswordForm.valid) {
      this.spinnerService.show();
      this.errors.splice(0);
      const input: OtpTokenObtainInputObjectType = {
        email: this.email.value,
        otp: this.oneTimePassword.value,
      };
      // call otpTokenObtain
      await firstValueFrom(this.loginService.otpTokenObtain(input)).then(
        (res) => {
          const response = res.data?.otpTokenObtain;
          if (response) {
            if (this._router.url === '/password_reset_auth') {
              // Store token to storage
              this.userProfileService.setToken(response.token, response.refreshToken);
              // go to Password Registration
              this._router.navigateByUrl('/password_registration');
            } else {
              // Store token to storage
              this.userProfileService.setToken(response.token, response.refreshToken);
              // go to Authenticated screen
              this._router.navigateByUrl('/reports/edit');
            }
            // remove unnecessary storage information
            localStorage.removeItem('email');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.errors.push(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * oneTimePassword FormControl Getter
   */
  get oneTimePassword(): UntypedFormControl {
    return this.oneTimePasswordForm.get('oneTimePassword') as UntypedFormControl;
  }
  /**
   * email FormControl Getter
   */
  get email(): UntypedFormControl {
    return this.oneTimePasswordForm.get('email') as UntypedFormControl;
  }
}
