<h2 mat-dialog-title>{{ userEditFormTitle }}</h2>
<mat-dialog-content style="height: 75%">
  <form [formGroup]="userEditFormGroup" class="user-edit-form">
    <p *ngIf="userEditParam.id">ID: {{ displayUserId }}</p>
    <mat-form-field class="form-item-field" appearance="fill" style="width: 100%">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" maxlength="64" />
      <mat-error *ngIf="name.errors?.['required']">Name is required.</mat-error>
    </mat-form-field>

    <div class="flex-row justify-space-between -flex">
      <div class="align-center-center flex-80">
        <mat-form-field class="form-item-field" appearance="fill" style="width: 100%">
          <mat-label>Email</mat-label>
          <input matInput type="text" formControlName="email" maxlength="256" />
          <mat-error *ngIf="email.errors?.['required']">Email is required.</mat-error>
          <mat-error *ngIf="email.errors?.['pattern'] || email.errors?.['email']"
            >Invalid Email format.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="flex-18 align-center-center">
        <button
          *ngIf="userEditParam.id && !agentLoggedIn.value"
          [disabled]="email.valid"
          type="button"
          mat-flat-button
          class="btn"
          color="primary"
          (click)="onRemind()"
          [disabled]="!userEditFormGroup.valid"
        >
          Remind
        </button>
      </div>
    </div>
    <mat-form-field class="form-item-field" appearance="fill" style="width: 50%">
      <mat-label>Role</mat-label>
      <mat-select formControlName="role" (selectionChange)="onChangeRoleType()">
        <mat-option *ngFor="let role of roleItems" value="{{ role }}">{{ role }}</mat-option>
      </mat-select>
      <mat-error *ngIf="role.errors?.['required']"> Role is required. </mat-error>
    </mat-form-field>
    <div></div>
    <mat-form-field class="form-item-field" appearance="fill" style="width: 50%">
      <mat-label>status</mat-label>
      <mat-select formControlName="status">
        <mat-option
          *ngFor="let activeStatusType of activeStatusTypes"
          [value]="activeStatusType.value"
          >{{ activeStatusType.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div style="padding-right: 24px">
      <mat-label>Related Advertisers</mat-label>
      <ng-multiselect-dropdown
        [placeholder]="'Please select an advertiser.'"
        [settings]="dropdownSettings"
        [data]="advertiserList"
        [(ngModel)]="selectedAdvertisers"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="isDisbaleAdvertiserRelated()"
      >
      </ng-multiselect-dropdown>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form justify-space-around flex-row flex-gap-5px -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button class="btn" color="basic" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        *ngIf="userEditParam.id"
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        (click)="onSave()"
        [disabled]="!userEditFormGroup.valid"
      >
        Save
      </button>
      <button
        *ngIf="!userEditParam.id"
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        (click)="onRegistration()"
        [disabled]="!userEditFormGroup.valid || isSubmitting"
      >
        Regist
      </button>
    </div>
  </div>
  <mat-error *ngFor="let err of errors">{{ err }}</mat-error>
</mat-dialog-actions>
