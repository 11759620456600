import { gql } from 'apollo-angular';

export const QueryGetAdSizes = gql`
  query adSizes(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $width: Int
    $height: Int
    $deliveryType: String
    $orderBy: String
    $name: String
  ) {
    adSizes(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      name: $name
      width: $width
      height: $height
      deliveryType: $deliveryType
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          width
          height
          channels {
            id
            name
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;
