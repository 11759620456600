import { gql } from 'apollo-angular';

export const QueryGetUsers = gql`
  query users(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $isActive: Boolean
    $name: String
    $email: String
    $role: UserRole
    $advertiserName: String
    $orderBy: String
  ) {
    users(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      isActive: $isActive
      name: $name
      email: $email
      role: $role
      advertiserName: $advertiserName
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          email
          role
          isActive
          advertisers {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;
