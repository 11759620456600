<h2 mat-dialog-title>{{ formTitle }}</h2>
<mat-dialog-content style="height: 50%">
  <mat-stepper linear orientation="vertical" (selectionChange)="selectionChange($event)">
    <mat-step [stepControl]="campaignGeneralFormGroup">
      <ng-template matStepLabel>Step 1</ng-template>
      <form [formGroup]="campaignGeneralFormGroup">
        <ng-template matStepContent>
          <div class="flex-column justify-space-around flex-gap-5px -flex">
            <mat-form-field style="width: 100%">
              <mat-label>BillingType</mat-label>
              <mat-select formControlName="billingType" (selectionChange)="onChangeBillingType()">
                <mat-option *ngFor="let billingType of billingTypes" value="{{ billingType }}">{{
                  billingType
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="billingType.errors?.['required']"
                >BillingType is required.</mat-error
              >
            </mat-form-field>
            <mat-form-field style="width: 100%">
              <mat-label>AdType</mat-label>
              <mat-select formControlName="adType" (selectionChange)="onChangeAdType()">
                <mat-option *ngFor="let adType of adTypes" value="{{ adType }}">{{
                  adType
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="adType.errors?.['required']">AdType is required.</mat-error>
            </mat-form-field>
            <mat-form-field style="width: 100%">
              <mat-label>DeliveryType</mat-label>
              <mat-select formControlName="deliveryType">
                <mat-option *ngFor="let deliveryType of deliveryTypes" value="{{ deliveryType }}">{{
                  deliveryType
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="deliveryType.errors?.['required']"
                >DeliveryType is required.</mat-error
              >
            </mat-form-field>
          </div>
          <button mat-stroked-button matStepperNext color="primary">Next</button>
        </ng-template>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Step 2</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="campaignFormGroup">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>FriendlyName</mat-label>
            <input matInput type="text" formControlName="friendlyName" />
            <mat-error *ngIf="friendlyName.errors?.['required']"
              >FriendlyName is required.</mat-error
            >
            <mat-error *ngIf="friendlyName.errors?.['pattern']"
              >Invalid FriendlyName format.</mat-error
            >
          </mat-form-field>
          <mat-form-field style="width: 100%">
            <mat-label>CampaignName *Autofill</mat-label>
            <input
              matInput
              type="text"
              formControlName="campaignName"
              value="{{ createCampaignName() }}"
            />
          </mat-form-field>
          <div class="justify-space-around flex-row flex-gap-20px -flex">
            <div class="align-center-center flex-50 flex-shrink">
              <mat-form-field
                *ngIf="campaignType.dailyCostAmount"
                appearance="fill"
                style="width: 100%"
              >
                <mat-label>DailyCostAmount</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="dailyCostAmount" />
                <mat-hint>{{ dailyCostAmount.value | currency: 'JPY' }}</mat-hint>
                <mat-error
                  *ngIf="
                    dailyCostAmount.errors?.['pattern'] ||
                    dailyCostAmount.errors?.['max'] ||
                    dailyCostAmount.errors?.['min']
                  "
                >
                  Invalid DailyCostAmount format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-50 -flex flex-shrink">
              <mat-form-field
                *ngIf="campaignType.lifetimeCostAmount"
                appearance="fill"
                style="width: 100%"
              >
                <mat-label>LifetimeCostAmount</mat-label>
                <input matInput type="number" appIntegerOnly formControlName="lifetimeCostAmount" />
                <mat-hint>{{ lifetimeCostAmount.value | currency: 'JPY' }}</mat-hint>
                <mat-error
                  *ngIf="
                    lifetimeCostAmount.errors?.['pattern'] ||
                    lifetimeCostAmount.errors?.['max'] ||
                    lifetimeCostAmount.errors?.['min']
                  "
                >
                  Invalid LifetimeCostAmount format. Valid range is 0 ~ 2147483647
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="justify-space-around flex-row flex-gap-20px -flex">
            <div class="align-center-center flex-50 flex-shrink">
              <mat-form-field *ngIf="campaignType.isActive" style="width: 100%">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option
                    *ngFor="let activeStatusType of activeStatusTypes"
                    [value]="activeStatusType.value"
                    >{{ activeStatusType.label }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="align-center-center flex-50 flex-shrink"></div>
          </div>
        </form>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form justify-space-around flex-row flex-gap-5px -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button class="btn" color="basic" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        [disabled]="!campaignFormGroup.valid || isSubmitting"
        (click)="onRegistration()"
      >
        Regist
      </button>
    </div>
  </div>
</mat-dialog-actions>
