<h2 mat-dialog-title>{{ formTitle }}</h2>
<mat-dialog-content style="height: 50%">
  <form [formGroup]="formGroup" class="advertiser-edit-form">
    <p *ngIf="editParam.id">ID: {{ displayAdvertiseId }}</p>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" maxlength="256" />
      <mat-error *ngIf="name.errors?.['required']">Name is required.</mat-error>
      <mat-error *ngIf="name.errors?.['pattern']">Invalid Name format.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 50%">
      <mat-label>status</mat-label>
      <mat-select formControlName="status">
        <mat-option
          *ngFor="let activeStatusType of activeStatusTypes"
          [value]="activeStatusType.value"
          >{{ activeStatusType.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block">
  <div class="btn-form flex-row -flex">
    <div class="flex-120px">
      <button type="button" mat-stroked-button class="btn" color="basic" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex-grow -flex"></div>
    <div class="flex-120px">
      <button
        *ngIf="editParam.id"
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        (click)="onSave()"
        [disabled]="!formGroup.valid"
      >
        Save
      </button>
      <button
        *ngIf="!editParam.id"
        type="button"
        mat-flat-button
        class="btn"
        color="primary"
        (click)="onRegistration()"
        [disabled]="!formGroup.valid || isSubmitting"
      >
        Regist
      </button>
    </div>
  </div>
</mat-dialog-actions>
