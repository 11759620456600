import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/core/user-profile.service';

@Component({
  selector: 'app-password-registration-completed',
  templateUrl: './password-registration-completed.component.html',
  styleUrls: ['./password-registration-completed.component.scss'],
})
export class PasswordRegistrationCompletedComponent implements OnInit {
  /**
   * constructor
   * @param userProfileService
   * @param _router
   */
  constructor(
    private userProfileService: UserProfileService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    // remove token
    this.userProfileService.clearToken();
    return;
  }

  /**
   * goToLoginPage
   */
  public goToLoginPage(): void {
    this._router.navigate(['/login']);
  }
}
