import { Component } from '@angular/core';
import { Router, Event, ActivationEnd } from '@angular/router';
import { PageProfileService } from './core/page-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isDisabled = false;
  constructor(
    private router: Router,
    private pageProfileService: PageProfileService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data) {
          if (event.snapshot.data['noMenu']) {
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
          this.pageProfileService.setTitle(event.snapshot.data['title']);
        }
      }
    });
  }
}
