import { gql } from 'apollo-angular';

export const QueryGetFlight = gql`
  query flight($pk: ID!) {
    flight(pk: $pk) {
      id
      name
      isActive
      price
      startDate
      endDate
      dailyCapAmount
      lifetimeCapAmount
      goalAmount
      campaign {
        id
        name
        billingType
        adType
        deliveryType
      }
      adSize {
        id
        name
      }
      zoneIds
      priority {
        id
        name
        goalTypes
      }
      creativeType
      goalType
      rateType
      capType
      customTargetings {
        edges {
          node {
            key
            values
            elementType
            isRequired
            isEditable
          }
        }
      }
    }
  }
`;
