import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  Mutation,
  PasswordUpdateInputObjectType,
  PasswordResetRequestInputObjectType,
} from 'src/app/models/graphql/types';
import {
  MutatePasswordUpdate,
  MutatePasswordResetRequest,
} from 'src/app/models/graphql/mutation/change-password.graphql';

@Injectable({
  providedIn: 'root',
})
export class PasswordChangeService {
  constructor(private apollo: Apollo) {}

  /**
   * passwordResetRequest
   * @param request PasswordResetRequestInputObjectType
   * @returns
   */
  public passwordResetRequest(request: PasswordResetRequestInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutatePasswordResetRequest,
      variables: {
        input: request,
      },
    });
  }

  /**
   * Password Update
   * @param request PasswordUpdateInputObjectType
   * @returns
   */
  public passwordUpdate(request: PasswordUpdateInputObjectType) {
    return this.apollo.mutate<Mutation>({
      mutation: MutatePasswordUpdate,
      variables: {
        input: request,
      },
    });
  }
}
