import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type AdSizeObjectType = Node & {
  __typename?: 'AdSizeObjectType';
  channels: Array<Maybe<ChannelObjectType>>;
  createdAt: Scalars['DateTime'];
  height: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type AdSizeObjectTypeConnection = {
  __typename?: 'AdSizeObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdSizeObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AdSizeObjectType` and its cursor. */
export type AdSizeObjectTypeEdge = {
  __typename?: 'AdSizeObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AdSizeObjectType>;
};

export type AdvertiserCreateMutation = {
  __typename?: 'AdvertiserCreateMutation';
  advertiser: AdvertiserObjectType;
  ok: Scalars['Boolean'];
};

export type AdvertiserInputObjectType = {
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type AdvertiserObjectType = Node & {
  __typename?: 'AdvertiserObjectType';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AdvertiserObjectTypeConnection = {
  __typename?: 'AdvertiserObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdvertiserObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AdvertiserObjectType` and its cursor. */
export type AdvertiserObjectTypeEdge = {
  __typename?: 'AdvertiserObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AdvertiserObjectType>;
};

export type AdvertiserUpdateMutation = {
  __typename?: 'AdvertiserUpdateMutation';
  advertiser: AdvertiserObjectType;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum CampaignAdType {
  Type2D = 'Type2D',
  Type3D = 'Type3D'
}

/** An enumeration. */
export enum CampaignBillingType {
  Normal = 'Normal',
  Premium = 'Premium'
}

export type CampaignCreateMutation = {
  __typename?: 'CampaignCreateMutation';
  campaign: CampaignObjectType;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum CampaignDeliveryType {
  AdServer = 'AdServer',
  Embedded = 'Embedded'
}

export type CampaignInputObjectType = {
  adType?: InputMaybe<CampaignAdType>;
  advertiserId: Scalars['ID'];
  billingType?: InputMaybe<CampaignBillingType>;
  dailyCostAmount?: InputMaybe<Scalars['Int']>;
  deliveryType?: InputMaybe<CampaignDeliveryType>;
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  lifetimeCostAmount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CampaignObjectType = Node & {
  __typename?: 'CampaignObjectType';
  adType: CampaignAdType;
  advertiser?: Maybe<AdvertiserObjectType>;
  billingType: CampaignBillingType;
  createdAt: Scalars['DateTime'];
  dailyCostAmount?: Maybe<Scalars['Int']>;
  deliveryType: CampaignDeliveryType;
  /** The ID of the object */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lifetimeCostAmount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CampaignObjectTypeConnection = {
  __typename?: 'CampaignObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `CampaignObjectType` and its cursor. */
export type CampaignObjectTypeEdge = {
  __typename?: 'CampaignObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CampaignObjectType>;
};

export type CampaignUpdateMutation = {
  __typename?: 'CampaignUpdateMutation';
  campaign: CampaignObjectType;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum CapType {
  Budget = 'Budget',
  Click = 'Click',
  Conversion = 'Conversion',
  Impression = 'Impression',
  None = 'NONE'
}

export type ChannelObjectType = Node & {
  __typename?: 'ChannelObjectType';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CostInputObjectType = {
  adjustment?: InputMaybe<Scalars['Int']>;
  billingMonth: Scalars['Date'];
  costs?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  /** %表記の値(0.00～100.00) */
  marginRate?: InputMaybe<Scalars['Decimal']>;
};

export type CostObjectType = Node & {
  __typename?: 'CostObjectType';
  adjustment?: Maybe<Scalars['Int']>;
  advertiserId: Scalars['ID'];
  /** 先方の要望で正規化せず追加。 */
  advertiserName?: Maybe<Scalars['String']>;
  /** The format is yyyy-mm-01. */
  billingMonth: Scalars['Date'];
  campaignId: Scalars['ID'];
  /** 先方の要望で正規化せず追加。 */
  campaignName?: Maybe<Scalars['String']>;
  costs?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** end date of the campaign. */
  endDate: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  marginRate?: Maybe<Scalars['Float']>;
  /** start date of the campaign. */
  startDate: Scalars['Date'];
  updatedAt: Scalars['DateTime'];
};

export type CostObjectTypeConnection = {
  __typename?: 'CostObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CostObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `CostObjectType` and its cursor. */
export type CostObjectTypeEdge = {
  __typename?: 'CostObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CostObjectType>;
};

export type CostsInputObjectType = {
  campaignId: Scalars['ID'];
  costData: Array<InputMaybe<CostInputObjectType>>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type CostsUpdateMutation = {
  __typename?: 'CostsUpdateMutation';
  costs: Array<Maybe<CostObjectType>>;
  ok: Scalars['Boolean'];
};

export type CreativeCreateMutation = {
  __typename?: 'CreativeCreateMutation';
  creative: CreativeObjectType;
  ok: Scalars['Boolean'];
};

export type CreativeInputObjectType = {
  /** normal or premium */
  billingType: Scalars['String'];
  clickUrl?: InputMaybe<Scalars['String']>;
  clickable: Scalars['Boolean'];
  creativeId?: InputMaybe<Scalars['ID']>;
  /** adserver or embedded */
  deliveryType: Scalars['String'];
  flightId: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  videoPlaybackId?: InputMaybe<Scalars['String']>;
};

export type CreativeObjectType = Node & {
  __typename?: 'CreativeObjectType';
  /** normal or premium */
  billingType?: Maybe<Scalars['String']>;
  clickUrl?: Maybe<Scalars['String']>;
  clickable?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  /** adserver or embedded or other */
  deliveryType?: Maybe<Scalars['String']>;
  flight?: Maybe<FlightObjectType>;
  /** The ID of the object */
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  /** Kevelのassetsに登録したファイル名xxx.m3u8のxxx部分 */
  videoId?: Maybe<Scalars['String']>;
};

export type CreativeObjectTypeConnection = {
  __typename?: 'CreativeObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CreativeObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `CreativeObjectType` and its cursor. */
export type CreativeObjectTypeEdge = {
  __typename?: 'CreativeObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CreativeObjectType>;
};

export type CreativeSignedUrlObjectType = {
  __typename?: 'CreativeSignedUrlObjectType';
  fileUploadPath: Scalars['String'];
  signedUrl: Scalars['String'];
};

/** An enumeration. */
export enum CreativeType {
  Attraction = 'Attraction',
  Avatar = 'Avatar',
  Image = 'Image',
  Showroom = 'Showroom',
  Video = 'Video'
}

export type CreativeUpdateMutation = {
  __typename?: 'CreativeUpdateMutation';
  creative: CreativeObjectType;
  ok: Scalars['Boolean'];
};

export type CustomTargetingConfigConnection = {
  __typename?: 'CustomTargetingConfigConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomTargetingConfigEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomTargetingConfig` and its cursor. */
export type CustomTargetingConfigEdge = {
  __typename?: 'CustomTargetingConfigEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CustomTargetingConfigObjectType>;
};

export type CustomTargetingConfigInputObjectType = {
  key: Scalars['String'];
  values: Array<InputMaybe<Scalars['String']>>;
};

export type CustomTargetingConfigObjectType = {
  __typename?: 'CustomTargetingConfigObjectType';
  elementType: ElementType;
  isEditable?: Maybe<Scalars['Boolean']>;
  isRequired: Scalars['Boolean'];
  key: Scalars['String'];
  values: Array<Maybe<Scalars['String']>>;
};

/** An enumeration. */
export enum ElementType {
  CheckBox = 'CheckBox',
  RadioButton = 'RadioButton',
  TextBox = 'TextBox'
}

/** An enumeration. */
export enum FilterType {
  Advertiser = 'Advertiser',
  Campaign = 'Campaign',
  Flight = 'Flight'
}

export type FlightCreateMutation = {
  __typename?: 'FlightCreateMutation';
  flight: FlightObjectType;
  ok: Scalars['Boolean'];
};

export type FlightInputObjectType = {
  adSizeId: Scalars['ID'];
  campaignId: Scalars['ID'];
  capType?: InputMaybe<CapType>;
  creativeType: CreativeType;
  customTargetings: Array<InputMaybe<CustomTargetingConfigInputObjectType>>;
  dailyCapAmount?: InputMaybe<Scalars['Int']>;
  deliveryType: CampaignDeliveryType;
  endDate?: InputMaybe<Scalars['Date']>;
  goalAmount: Scalars['Int'];
  goalType: GoalType;
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  lifetimeCapAmount?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Decimal']>;
  priorityId: Scalars['ID'];
  rateType?: InputMaybe<RateType>;
  startDate: Scalars['Date'];
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type FlightObjectType = Node & {
  __typename?: 'FlightObjectType';
  adSize: AdSizeObjectType;
  campaign: CampaignObjectType;
  capType?: Maybe<CapType>;
  createdAt: Scalars['DateTime'];
  creativeType: CreativeType;
  customTargetings?: Maybe<CustomTargetingConfigConnection>;
  dailyCapAmount: Scalars['Int'];
  endDate?: Maybe<Scalars['Date']>;
  goalAmount?: Maybe<Scalars['Int']>;
  goalType: GoalType;
  /** The ID of the object */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lifetimeCapAmount: Scalars['Int'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  priority: PriorityObjectType;
  rateType?: Maybe<RateType>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['DateTime'];
  zoneIds: Array<Maybe<Scalars['ID']>>;
};


export type FlightObjectTypeCustomTargetingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type FlightObjectTypeConnection = {
  __typename?: 'FlightObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FlightObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FlightObjectType` and its cursor. */
export type FlightObjectTypeEdge = {
  __typename?: 'FlightObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FlightObjectType>;
};

export type FlightUpdateMutation = {
  __typename?: 'FlightUpdateMutation';
  flight: FlightObjectType;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum GoalType {
  Clicks = 'Clicks',
  Conversions = 'Conversions',
  DailyRevenue = 'DailyRevenue',
  Impressions = 'Impressions',
  MonthlyRevenue = 'MonthlyRevenue',
  Percentage = 'Percentage',
  Revenue = 'Revenue'
}

export type GranWhaleBillingConnection = {
  __typename?: 'GranWhaleBillingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GranWhaleBillingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GranWhaleBilling` and its cursor. */
export type GranWhaleBillingEdge = {
  __typename?: 'GranWhaleBillingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GranWhaleBillingObjectType>;
};

export type GranWhaleBillingObjectType = {
  __typename?: 'GranWhaleBillingObjectType';
  advertiserId?: Maybe<Scalars['Int']>;
  advertiserName?: Maybe<Scalars['String']>;
  billing?: Maybe<Scalars['Decimal']>;
  billingMonth?: Maybe<Scalars['Date']>;
};

export type GranWhaleCostConnection = {
  __typename?: 'GranWhaleCostConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GranWhaleCostEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GranWhaleCost` and its cursor. */
export type GranWhaleCostEdge = {
  __typename?: 'GranWhaleCostEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GranWhaleCostObjectType>;
};

export type GranWhaleCostObjectType = {
  __typename?: 'GranWhaleCostObjectType';
  adjustment?: Maybe<Scalars['Int']>;
  advertiser_id?: Maybe<Scalars['Int']>;
  advertiser_name?: Maybe<Scalars['String']>;
  billing_month?: Maybe<Scalars['Date']>;
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_name?: Maybe<Scalars['String']>;
  costs?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  margin_rate?: Maybe<Scalars['Decimal']>;
  start_date?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type GranWhalePerformanceReport2dConnection = {
  __typename?: 'GranWhalePerformanceReport2dConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GranWhalePerformanceReport2dEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GranWhalePerformanceReport2d` and its cursor. */
export type GranWhalePerformanceReport2dEdge = {
  __typename?: 'GranWhalePerformanceReport2dEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GranWhalePerformanceReport2dObjectType>;
};

export type GranWhalePerformanceReport2dObjectType = {
  __typename?: 'GranWhalePerformanceReport2dObjectType';
  action?: Maybe<Scalars['Int']>;
  advertiser_id?: Maybe<Scalars['Int']>;
  advertiser_name?: Maybe<Scalars['String']>;
  billing_type?: Maybe<Scalars['String']>;
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_name?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Decimal']>;
  creative_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  delivery_type?: Maybe<Scalars['String']>;
  engagement?: Maybe<Scalars['Int']>;
  event_1?: Maybe<Scalars['Int']>;
  event_2?: Maybe<Scalars['Int']>;
  event_3?: Maybe<Scalars['Int']>;
  event_4?: Maybe<Scalars['Int']>;
  event_5?: Maybe<Scalars['Int']>;
  event_6?: Maybe<Scalars['Int']>;
  event_7?: Maybe<Scalars['Int']>;
  event_8?: Maybe<Scalars['Int']>;
  event_9?: Maybe<Scalars['Int']>;
  event_10?: Maybe<Scalars['Int']>;
  flight_id?: Maybe<Scalars['Int']>;
  flight_name?: Maybe<Scalars['String']>;
  imp?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Int']>;
  video_5s?: Maybe<Scalars['Int']>;
  video_15s?: Maybe<Scalars['Int']>;
  video_30s?: Maybe<Scalars['Int']>;
  view_imp?: Maybe<Scalars['Int']>;
};

export type GranWhalePerformanceReport3dConnection = {
  __typename?: 'GranWhalePerformanceReport3dConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GranWhalePerformanceReport3dEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GranWhalePerformanceReport3d` and its cursor. */
export type GranWhalePerformanceReport3dEdge = {
  __typename?: 'GranWhalePerformanceReport3dEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GranWhalePerformanceReport3dObjectType>;
};

export type GranWhalePerformanceReport3dObjectType = {
  __typename?: 'GranWhalePerformanceReport3dObjectType';
  action?: Maybe<Scalars['Int']>;
  advertiser_id?: Maybe<Scalars['Int']>;
  advertiser_name?: Maybe<Scalars['String']>;
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_name?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Decimal']>;
  creative_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  engagement?: Maybe<Scalars['Int']>;
  event_1?: Maybe<Scalars['Int']>;
  event_2?: Maybe<Scalars['Int']>;
  event_3?: Maybe<Scalars['Int']>;
  event_4?: Maybe<Scalars['Int']>;
  event_5?: Maybe<Scalars['Int']>;
  event_6?: Maybe<Scalars['Int']>;
  event_7?: Maybe<Scalars['Int']>;
  event_8?: Maybe<Scalars['Int']>;
  event_9?: Maybe<Scalars['Int']>;
  event_10?: Maybe<Scalars['Int']>;
  flight_id?: Maybe<Scalars['Int']>;
  flight_name?: Maybe<Scalars['String']>;
  imp?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Int']>;
  video_5s?: Maybe<Scalars['Int']>;
  video_15s?: Maybe<Scalars['Int']>;
  video_30s?: Maybe<Scalars['Int']>;
  view_imp?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GroupingType {
  Advertiser = 'Advertiser',
  Campaign = 'Campaign',
  Date = 'Date',
  Flight = 'Flight'
}

/** An enumeration. */
export enum IndicatorType {
  Action = 'Action',
  Cpe = 'CPE',
  Cpm = 'CPM',
  Click = 'Click',
  Cost = 'Cost',
  EngRate = 'EngRate',
  Engagement = 'Engagement',
  Imp = 'Imp',
  Show = 'Show',
  Video5s = 'Video5s',
  Video15s = 'Video15s',
  Video30s = 'Video30s',
  ViewAbility = 'ViewAbility',
  ViewImp = 'ViewImp'
}

export type Mutation = {
  __typename?: 'Mutation';
  advertiserCreate?: Maybe<AdvertiserCreateMutation>;
  advertiserUpdate?: Maybe<AdvertiserUpdateMutation>;
  campaignCreate?: Maybe<CampaignCreateMutation>;
  campaignUpdate?: Maybe<CampaignUpdateMutation>;
  costsUpdate?: Maybe<CostsUpdateMutation>;
  creativeCreate?: Maybe<CreativeCreateMutation>;
  creativeUpdate?: Maybe<CreativeUpdateMutation>;
  flightCreate?: Maybe<FlightCreateMutation>;
  flightUpdate?: Maybe<FlightUpdateMutation>;
  otpRequest?: Maybe<OtpRequestMutation>;
  otpTokenObtain?: Maybe<OtpTokenObtainMutation>;
  passwordRemind?: Maybe<PasswordRemindMutation>;
  passwordResetRequest?: Maybe<PasswordResetRequestMutation>;
  passwordUpdate?: Maybe<PasswordUpdateMutation>;
  tokenDiscard?: Maybe<TokenDiscardMutation>;
  tokenRefresh?: Maybe<TokenRefreshMutation>;
  userCreate?: Maybe<UserCreateMutation>;
  userUpdate?: Maybe<UserUpdateMutation>;
};


export type MutationAdvertiserCreateArgs = {
  input: AdvertiserInputObjectType;
};


export type MutationAdvertiserUpdateArgs = {
  input: AdvertiserInputObjectType;
};


export type MutationCampaignCreateArgs = {
  input: CampaignInputObjectType;
};


export type MutationCampaignUpdateArgs = {
  input: CampaignInputObjectType;
};


export type MutationCostsUpdateArgs = {
  input: CostsInputObjectType;
};


export type MutationCreativeCreateArgs = {
  input: CreativeInputObjectType;
};


export type MutationCreativeUpdateArgs = {
  input: CreativeInputObjectType;
};


export type MutationFlightCreateArgs = {
  input: FlightInputObjectType;
};


export type MutationFlightUpdateArgs = {
  input: FlightInputObjectType;
};


export type MutationOtpRequestArgs = {
  input: OtpRequestInputObjectType;
};


export type MutationOtpTokenObtainArgs = {
  input: OtpTokenObtainInputObjectType;
};


export type MutationPasswordRemindArgs = {
  input: PasswordRemindInputObjectType;
};


export type MutationPasswordResetRequestArgs = {
  input: PasswordResetRequestInputObjectType;
};


export type MutationPasswordUpdateArgs = {
  input: PasswordUpdateInputObjectType;
};


export type MutationTokenRefreshArgs = {
  input: TokenRefreshInputObjectType;
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationUserCreateArgs = {
  input: UserInputObjectType;
};


export type MutationUserUpdateArgs = {
  input: UserInputObjectType;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type OtpRequestInputObjectType = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type OtpRequestMutation = {
  __typename?: 'OtpRequestMutation';
  ok: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type OtpTokenObtainInputObjectType = {
  email: Scalars['String'];
  otp: Scalars['String'];
};

export type OtpTokenObtainMutation = {
  __typename?: 'OtpTokenObtainMutation';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: UserObjectType;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PasswordRemindInputObjectType = {
  email: Scalars['String'];
};

export type PasswordRemindMutation = {
  __typename?: 'PasswordRemindMutation';
  ok: Scalars['Boolean'];
};

export type PasswordResetRequestInputObjectType = {
  email: Scalars['String'];
};

export type PasswordResetRequestMutation = {
  __typename?: 'PasswordResetRequestMutation';
  ok: Scalars['Boolean'];
};

export type PasswordUpdateInputObjectType = {
  password: Scalars['String'];
};

export type PasswordUpdateMutation = {
  __typename?: 'PasswordUpdateMutation';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: UserObjectType;
};

export type PerformanceReportConnection = {
  __typename?: 'PerformanceReportConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PerformanceReportEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PerformanceReport` and its cursor. */
export type PerformanceReportEdge = {
  __typename?: 'PerformanceReportEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PerformanceReportObjectType>;
};

export type PerformanceReportObjectType = {
  __typename?: 'PerformanceReportObjectType';
  action?: Maybe<Scalars['Int']>;
  advertiserId?: Maybe<Scalars['ID']>;
  advertiserName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  click?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Decimal']>;
  cpe?: Maybe<Scalars['Decimal']>;
  cpm?: Maybe<Scalars['Decimal']>;
  date?: Maybe<Scalars['Date']>;
  engRate?: Maybe<Scalars['Decimal']>;
  engagement?: Maybe<Scalars['Int']>;
  flightId?: Maybe<Scalars['ID']>;
  flightName?: Maybe<Scalars['String']>;
  imp?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Int']>;
  video5s?: Maybe<Scalars['Int']>;
  video15s?: Maybe<Scalars['Int']>;
  video30s?: Maybe<Scalars['Int']>;
  viewAbility?: Maybe<Scalars['Decimal']>;
  viewImp?: Maybe<Scalars['Int']>;
};

export type PriorityObjectType = Node & {
  __typename?: 'PriorityObjectType';
  createdAt: Scalars['DateTime'];
  goalTypes?: Maybe<Array<Maybe<GoalType>>>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  selectionAlgorithmType?: Maybe<SelectionAlgorithmType>;
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type PriorityObjectTypeConnection = {
  __typename?: 'PriorityObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PriorityObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PriorityObjectType` and its cursor. */
export type PriorityObjectTypeEdge = {
  __typename?: 'PriorityObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PriorityObjectType>;
};

export type Query = {
  __typename?: 'Query';
  adSizes?: Maybe<AdSizeObjectTypeConnection>;
  advertiser?: Maybe<AdvertiserObjectType>;
  advertisers?: Maybe<AdvertiserObjectTypeConnection>;
  campaign?: Maybe<CampaignObjectType>;
  campaigns?: Maybe<CampaignObjectTypeConnection>;
  costs?: Maybe<CostObjectTypeConnection>;
  creative?: Maybe<CreativeObjectType>;
  creativeSignedUrl?: Maybe<CreativeSignedUrlObjectType>;
  creatives?: Maybe<CreativeObjectTypeConnection>;
  customTargetings?: Maybe<CustomTargetingConfigConnection>;
  flight?: Maybe<FlightObjectType>;
  flights?: Maybe<FlightObjectTypeConnection>;
  granWhale2dLogs?: Maybe<GranWhalePerformanceReport2dConnection>;
  granWhale3dLogs?: Maybe<GranWhalePerformanceReport3dConnection>;
  granWhaleBillings?: Maybe<GranWhaleBillingConnection>;
  granWhaleCosts?: Maybe<GranWhaleCostConnection>;
  granWhaleZones?: Maybe<ZoneObjectTypeConnection>;
  node?: Maybe<Node>;
  performanceReports?: Maybe<PerformanceReportConnection>;
  premiumReports?: Maybe<CostObjectTypeConnection>;
  priorities?: Maybe<PriorityObjectTypeConnection>;
  user?: Maybe<UserObjectType>;
  users?: Maybe<UserObjectTypeConnection>;
  zones?: Maybe<ZoneObjectTypeConnection>;
};


export type QueryAdSizesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};


export type QueryAdvertiserArgs = {
  pk: Scalars['ID'];
};


export type QueryAdvertisersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCampaignArgs = {
  pk: Scalars['ID'];
};


export type QueryCampaignsArgs = {
  advertiserId?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  billingMonth?: InputMaybe<Scalars['Date']>;
  campaignId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCreativeArgs = {
  flightId: Scalars['ID'];
};


export type QueryCreativeSignedUrlArgs = {
  fileName: Scalars['String'];
};


export type QueryCreativesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCustomTargetingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryFlightArgs = {
  pk: Scalars['ID'];
};


export type QueryFlightsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGranWhale2dLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGranWhale3dLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGranWhaleBillingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGranWhaleCostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGranWhaleZonesArgs = {
  adSizeId?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryPerformanceReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  filters?: InputMaybe<Array<InputMaybe<ReportFilterInputObjectType>>>;
  first?: InputMaybe<Scalars['Int']>;
  groups?: InputMaybe<Array<InputMaybe<GroupingType>>>;
  indicators: Array<InputMaybe<IndicatorType>>;
  last?: InputMaybe<Scalars['Int']>;
  reportType: ReportType;
  startDate: Scalars['Date'];
};


export type QueryPremiumReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  billingMonth?: InputMaybe<Scalars['Date']>;
  endDate: Scalars['Date'];
  filters?: InputMaybe<Array<InputMaybe<ReportFilterInputObjectType>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  reportType: ReportType;
  startDate: Scalars['Date'];
};


export type QueryPrioritiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  selectionAlgorithmType?: InputMaybe<SelectionAlgorithmType>;
};


export type QueryUserArgs = {
  pk: Scalars['ID'];
};


export type QueryUsersArgs = {
  advertiserName?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryZonesArgs = {
  adSizeId?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum RateType {
  CpaClick = 'CPAClick',
  CpaView = 'CPAView',
  CpaViewClick = 'CPAViewClick',
  Cpc = 'CPC',
  Cpm = 'CPM',
  Flat = 'Flat'
}

export type ReportFilterInputObjectType = {
  key: FilterType;
  value: Array<InputMaybe<Scalars['ID']>>;
};

/** An enumeration. */
export enum ReportType {
  Normal2D = 'Normal2D',
  Premium2D = 'Premium2D',
  Premium3D = 'Premium3D'
}

/** An enumeration. */
export enum SelectionAlgorithmType {
  AdChainOptimized = 'AdChainOptimized',
  AdChainOrdered = 'AdChainOrdered',
  Auction = 'Auction',
  Lottery = 'Lottery',
  LotteryWithOutbids = 'LotteryWithOutbids'
}

export type TokenDiscardMutation = {
  __typename?: 'TokenDiscardMutation';
  ok: Scalars['Boolean'];
};

export type TokenRefreshInputObjectType = {
  refreshToken: Scalars['String'];
};

export type TokenRefreshMutation = {
  __typename?: 'TokenRefreshMutation';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type UserCreateMutation = {
  __typename?: 'UserCreateMutation';
  ok: Scalars['Boolean'];
  user: UserObjectType;
};

export type UserInputObjectType = {
  advertisers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  role: UserRole;
};

export type UserObjectType = Node & {
  __typename?: 'UserObjectType';
  advertisers?: Maybe<AdvertiserObjectTypeConnection>;
  agentLoggedIn?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** ユーザーがアクティブかどうかを示します。アカウントを削除する代わりに選択を解除してください。 */
  isActive: Scalars['Boolean'];
  /** Register as a user per 1 company. */
  name: Scalars['String'];
  role: UserRole;
  updatedAt: Scalars['DateTime'];
};


export type UserObjectTypeAdvertisersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserObjectTypeConnection = {
  __typename?: 'UserObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserObjectType` and its cursor. */
export type UserObjectTypeEdge = {
  __typename?: 'UserObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserObjectType>;
};

/** An enumeration. */
export enum UserRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Staff = 'Staff'
}

export type UserUpdateMutation = {
  __typename?: 'UserUpdateMutation';
  ok: Scalars['Boolean'];
  user: UserObjectType;
};

export type ZoneObjectType = Node & {
  __typename?: 'ZoneObjectType';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ZoneObjectTypeConnection = {
  __typename?: 'ZoneObjectTypeConnection';
  /** Count of the nodes in this connection. */
  edgeCount: Scalars['Int'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ZoneObjectTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of this connection. */
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ZoneObjectType` and its cursor. */
export type ZoneObjectTypeEdge = {
  __typename?: 'ZoneObjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ZoneObjectType>;
};
