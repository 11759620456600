import { gql } from 'apollo-angular';

export const QueryGetCosts = gql`
  query costs(
    $campaignId: ID!
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $billingMonth: Date
  ) {
    costs(
      campaignId: $campaignId
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      billingMonth: $billingMonth
    ) {
      edges {
        node {
          id
          advertiserId
          advertiserName
          campaignId
          campaignName
          startDate
          endDate
          billingMonth
          costs
          marginRate
          adjustment
        }
      }
      totalCount
      edgeCount
    }
  }
`;
