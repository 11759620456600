import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, map, startWith } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';
import { Flight2DCreativeTypes, FlightEditModalParams } from 'src/app/models/flight';
import {
  AdSizeObjectType,
  CampaignBillingType,
  CampaignObjectType,
  CapType,
  CreativeType,
  CustomTargetingConfigConnection,
  CustomTargetingConfigInputObjectType,
  CustomTargetingConfigObjectType,
  ElementType,
  FlightInputObjectType,
  GoalType,
  Maybe,
  PriorityObjectType,
  QueryFlightArgs,
  RateType,
  ZoneObjectType,
} from 'src/app/models/graphql/types';
import { ActiveStatus, ActiveStatusTypes } from 'src/app/models/state';
import {
  convertStringToNumber,
  excerptErrorMessage,
  REGEX_COST_AMOUNT,
  REGEX_CUSTOM_TARGETING_TEXT_BOX,
} from 'src/app/resource/utility/common-util';
import { FlightService } from 'src/app/services/flights/flight.service';
import { UserProfileService } from 'src/app/core/user-profile.service';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-flight-adserver-edit',
  templateUrl: './flight-adserver-edit.component.html',
  styleUrls: ['./flight-adserver-edit.component.scss'],
  providers: [DatePipe],
})
export class FlightAdserverEditComponent implements OnInit {
  @ViewChild('inputRef', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

  /** Type PullDowns Enum*/
  public creativeTypes: CreativeType[] = Flight2DCreativeTypes;
  public rateTypes: RateType[] = Object.values(RateType);
  public goalTypes: GoalType[] = [];
  public capTypes: CapType[] = Object.values(CapType);
  public activeStatusTypes: ActiveStatus[] = Object.values(ActiveStatusTypes);

  /** Type PullDown from GraphQL */
  public adSizeTypes: Pick<AdSizeObjectType, 'id' | 'name'>[] = [];

  public selectedZoneIds: Maybe<string>[] = [];
  public zoneTypes: Pick<ZoneObjectType, 'id' | 'name'>[] = [];
  public zoneOptions: Pick<ZoneObjectType, 'id' | 'name'>[] = [];
  // public selectedZones: Pick<ZoneObjectType, 'id' | 'name'>[] = [];

  public priorityTypes: Pick<PriorityObjectType, 'id' | 'name' | 'goalTypes'>[] = [];

  public sortedCustomTargetings: Pick<
    CustomTargetingConfigObjectType,
    'key' | 'values' | 'elementType' | 'isRequired'
  >[] = [];
  public addedCustomTargetingFormControllNames: string[] = [];

  public readonly radioButton = ElementType.RadioButton;
  public readonly textBox = ElementType.TextBox;
  public readonly checkBox = ElementType.CheckBox;

  public zoneAutoCompleteOpen = true;

  @ViewChild(MatAutocompleteTrigger)
  autocomplete!: MatAutocompleteTrigger;

  /** FlightObject */
  public campaign: CampaignObjectType | undefined;

  /** FlightFormGroup */
  public flightFormGroup = this._formBuilder.group({
    creativeType: new UntypedFormControl(''),
    adSize: new UntypedFormControl(''),
    flightName: new UntypedFormControl(''),
    priority: new UntypedFormControl('', [Validators.required]),
    zoneSearch: new UntypedFormControl(''),
    selectedZones: new UntypedFormControl('', [Validators.required]),
    // zoneOption: new UntypedFormControl(''),
    rate: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_COST_AMOUNT),
      Validators.max(2147483647),
    ]),
    goalType: new UntypedFormControl('', [Validators.required]),
    goalAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_COST_AMOUNT),
      Validators.max(2147483647),
    ]),
    capType: new UntypedFormControl('', [Validators.required]),
    dailyCapAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_COST_AMOUNT),
      Validators.max(2147483647),
    ]),
    lifeTimeCapAmount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(REGEX_COST_AMOUNT),
      Validators.max(2147483647),
    ]),
    status: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  });

  private flightCustomTargetings: Maybe<CustomTargetingConfigConnection> | undefined = undefined;

  /**
   * Constructor
   * @param modalEditParam
   * @param _dialogRef
   * @param _formBuilder
   * @param _snackBar
   * @param spinnerService
   * @param flightService
   * @param datePipe
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalEditParam: FlightEditModalParams,
    private _dialogRef: MatDialogRef<FlightAdserverEditComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private flightService: FlightService,
    private userProfileService: UserProfileService,
    private datePipe: DatePipe
  ) {
    // Get Flight
    this.getFlight(this.modalEditParam.flightId).then(() => {
      // Get SizeTypes
      this.setFlightAdserverTypesAndCustomTargetingForms();

      // FormControl
      this.changeFormControl();
    });
  }
  lastFilter = '';
  ngOnInit(): void {
    // FormControls
    this.flightFormGroup.controls['creativeType'].disable();
    this.flightFormGroup.controls['adSize'].disable();
    this.flightFormGroup.controls['flightName'].disable();
    this.flightFormGroup.controls['rate'].disable();

    this.flightFormGroup.controls['zoneSearch'].valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : this.lastFilter)),
        map((filter) => this.onFilterZoneOptions(filter))
      )
      .subscribe((data) => {
        this.zoneOptions = data;
      });
  }

  public onFilterZoneOptions(value: string): Pick<AdSizeObjectType, 'id' | 'name'>[] {
    const filterValue = value.toLowerCase();
    return this.zoneTypes.filter((z) => z.name.toLowerCase().includes(filterValue));
  }

  public removeZone(zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    // this.selectedZones = this.selectedZones.filter((z) => z.id !== zone.id);
    const currentSelected: Pick<AdSizeObjectType, 'id' | 'name'>[] = this.selectedZones.value;
    this.flightFormGroup.patchValue({
      selectedZones: currentSelected.filter((z) => z.id !== zone.id),
    });
  }

  public addZone(zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    const currentSelected = this.selectedZones.value;
    this.flightFormGroup.patchValue({ selectedZones: [...currentSelected, zone] });
  }

  optionClicked(event: Event, zone: Pick<AdSizeObjectType, 'id' | 'name'>) {
    event.stopPropagation();
    this.zoneSearch.setValue('');
    this.inputRef.nativeElement.value = '';
    this.inputRef.nativeElement.focus();
    if (this.zoneAlreadySelected(zone)) {
      this.removeZone(zone);
    } else {
      this.addZone(zone);
    }
  }

  displayFn = (option: Pick<AdSizeObjectType, 'id' | 'name'>): string => {
    return 'welcome';
  };

  zoneAlreadySelected(option: Pick<AdSizeObjectType, 'id' | 'name'>) {
    return this.selectedZones.value.some(
      (z: Pick<AdSizeObjectType, 'id' | 'name'>) => z.id === option.id
    );
  }

  /**
   * Change Individual Form Control
   */
  private changeFormControl(): void {
    if (this.campaign?.billingType === CampaignBillingType.Normal) {
      this.flightFormGroup.controls['price'].enable();
      this.flightFormGroup.controls['goalType'].enable();
    } else if (this.campaign?.billingType === CampaignBillingType.Premium) {
      this.flightFormGroup.controls['price'].disable();
      this.flightFormGroup.controls['goalType'].disable();
    }
  }

  /**
   * Click Save button
   */
  public onSave(): void {
    if (this.flightFormGroup.valid && this.campaign) {
      this.spinnerService.show();
      // Update Arg
      const variable: FlightInputObjectType = {
        id: this.modalEditParam.flightId,
        campaignId: this.campaign.id,
        creativeType: this.creativeType.value,
        adSizeId: this.adSize.value,
        name: this.flightName.value,
        isActive: this.status.value,
        startDate: this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.endDate.value, 'yyyy-MM-dd'),
        priorityId: this.priority.value,
        zoneIds: this.selectedZones.value.map((z: Pick<AdSizeObjectType, 'id' | 'name'>) => z.id),
        rateType: this.rate.value,
        goalType: this.goalType.value,
        goalAmount: parseInt(this.goalAmount.value),
        capType: this.capType.value,
        price: this.price.value,
        dailyCapAmount: convertStringToNumber(this.dailyCapAmount.value),
        lifetimeCapAmount: convertStringToNumber(this.lifeTimeCapAmount.value),
        customTargetings: this.getCustomTargetingRequestData(),
        deliveryType: this.campaign.deliveryType,
      };

      // updateFlight
      firstValueFrom(this.flightService.updateFlight(variable)).then(
        (res) => {
          if (res.data?.flightUpdate?.ok) {
            this.showToaster('Completion of update');
            this._dialogRef.close(true);
          } else {
            this.showToaster('Flight update failed.');
          }
          this.spinnerService.hide();
        },
        (error: Error) => {
          this.spinnerService.hide();
          this.showToaster(excerptErrorMessage(error.message));
        }
      );
    }
  }

  /**
   * Click Cancel button
   */
  public onCancel(): void {
    this._dialogRef.close();
  }

  /**
   * getFlight
   * @param flightId
   */
  private async getFlight(flightId: string) {
    this.spinnerService.show();
    const variable: QueryFlightArgs = {
      pk: flightId,
    };
    // call get Campaign
    await firstValueFrom(this.flightService.getFlight(variable)).then(
      (res) => {
        const flight = res.data.flight;

        console.log(flight?.zoneIds);
        if (flight) {
          this.selectedZoneIds = flight.zoneIds;
          this.campaign = flight.campaign;
          this.flightFormGroup.patchValue({
            creativeType: flight.creativeType,
            adSize: flight.adSize.id,
            flightName: flight.name,
            startDate: flight.startDate,
            endDate: flight.endDate,
            // zone: flight.zoneIds,
            selectedZones: [],
            priority: flight.priority.id,
            capType: flight.capType,
            rate: flight.rateType,
            price: flight.price,
            goalType: flight.goalType,
            goalAmount: flight.goalAmount,
            dailyCapAmount: flight.dailyCapAmount,
            lifeTimeCapAmount: flight.lifetimeCapAmount,
            status: flight.isActive,
          });
          if (flight.customTargetings) {
            this.flightCustomTargetings = flight.customTargetings;
          }

          this.goalTypes = flight.priority.goalTypes as GoalType[];
        }
        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
        // Close modal due to data acquisition failure
        this._dialogRef.close();
      }
    );
  }

  /**
   * get FlightAdserverTypes
   * set FlightAdserverTypes and CustomTargetingForms
   */
  private async setFlightAdserverTypesAndCustomTargetingForms() {
    // reset types
    this.clearTypes();
    this.spinnerService.show();
    // call get adserverTypes-pull-down data.
    await firstValueFrom(
      this.flightService.getFlightAdserverTypes(this.campaign?.deliveryType, this.adSize.value)
    ).then(
      (res) => {
        const zones = res.data.granWhaleZones;
        const selectedZones: Pick<ZoneObjectType, 'id' | 'name'>[] = [];
        if (zones) {
          zones.edges.map((edge) => {
            if (edge && edge.node) {
              if (this.selectedZoneIds.includes(edge.node.id)) {
                selectedZones.push(edge.node);
              }

              this.zoneTypes.push(edge.node);
              return;
            }
            return;
          });
        }
        this.zoneOptions = this.zoneTypes;
        this.flightFormGroup.patchValue({ selectedZones });
        const priorities = res.data.priorities;
        if (priorities) {
          priorities.edges.map((edge) => {
            if (edge && edge.node) {
              this.priorityTypes.push(edge.node);
              return;
            }
            return;
          });
        }

        const customTargetings = res.data.customTargetings;
        if (customTargetings) {
          // 1. Extract only the `node` from the `edges` array.
          const nodes: Maybe<CustomTargetingConfigObjectType>[] = customTargetings.edges.reduce<
            Maybe<CustomTargetingConfigObjectType>[]
          >((acc, edge) => {
            if (edge && edge.node) {
              acc.push(edge.node);
            }
            return acc;
          }, []);

          // 2. Sort `node` based on `elementType`.
          const sortedNodes = nodes.sort((a, b) => {
            const aType = a!.elementType;
            const bType = b!.elementType;

            const aOrder = this.getElementOrder(aType);
            const bOrder = this.getElementOrder(bType);

            return aOrder - bOrder;
          });

          // 3. Generate form fields using the sorted node.
          sortedNodes.map((node) => {
            if (node) {
              this.sortedCustomTargetings.push(node);

              return;
            }
          });
          this.generateFormFields(sortedNodes);
          if (this.flightCustomTargetings) {
            const customTargetingFormValues: Array<Maybe<CustomTargetingConfigObjectType>> = [];

            // 1. Extract only the `node` from the `edges` array.
            const nodes: Maybe<CustomTargetingConfigObjectType>[] =
              this.flightCustomTargetings.edges.reduce<Maybe<CustomTargetingConfigObjectType>[]>(
                (acc, edge) => {
                  if (edge && edge.node) {
                    acc.push(edge.node);
                  }
                  return acc;
                },
                []
              );

            // 2. Sort `node` based on `elementType`.
            const sortedNodes = nodes.sort((a, b) => {
              const aType = a!.elementType;
              const bType = b!.elementType;

              const aOrder = this.getElementOrder(aType);
              const bOrder = this.getElementOrder(bType);

              return aOrder - bOrder;
            });

            // 3. Generate form fields using the sorted node.
            sortedNodes.map((node) => {
              if (node) {
                customTargetingFormValues.push(node);

                return;
              }
            });
            this.patchCustomTargetingsRegistredValues(customTargetingFormValues);
          }
        }

        this.spinnerService.hide();
      },
      (error: Error) => {
        this.showToaster(excerptErrorMessage(error.message));
        this.spinnerService.hide();
      }
    );
  }

  /**
   * clearTypes
   * reset Pull-Down Data
   */
  public clearTypes(): void {
    this.zoneTypes = [];
    this.priorityTypes = [];
  }

  /**
   * show message
   * @param message
   */
  private showToaster(message: string): void {
    this._snackBar.open(message, 'close', {
      verticalPosition: 'top',
    });
  }

  /**
   * onChangePriorityType
   * Get GoalType from Priority
   * @param selectedPriority priority.id
   */
  public onChangePriorityType(selectedPriority: MatSelect): void {
    const priorityType = this.priorityTypes.filter(
      (priorityType) => priorityType.id === selectedPriority.value
    );
    if (priorityType.length <= 0) {
      this.goalTypes = [];
    } else {
      this.goalTypes = priorityType[0].goalTypes as GoalType[];
    }
    if (this.campaign?.billingType === CampaignBillingType.Normal) {
      this.goalType.setValue(null);
    }
  }

  public camelToKebabCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  }

  private getCustomTargetingRequestData(): CustomTargetingConfigInputObjectType[] {
    const formControls = this.addedCustomTargetingFormControllNames;
    const requestData: CustomTargetingConfigInputObjectType[] = [];

    formControls.forEach((controlName) => {
      const control = this.flightFormGroup.controls[controlName];
      if (Array.isArray(control.value)) {
        requestData.push({
          key: controlName,
          values: control.value,
        });
      } else {
        requestData.push({
          key: controlName,
          values: [control.value],
        });
      }
    });

    return requestData;
  }

  /**
   * generate form
   * @param customTargetings
   */
  private generateFormFields(customTargetings: Array<Maybe<CustomTargetingConfigObjectType>>) {
    customTargetings.forEach((customTargeting) => {
      if (customTargeting) {
        // Add form control
        if (!this.flightFormGroup.contains(customTargeting.key)) {
          // Retain customTargeting items added for reset
          this.addedCustomTargetingFormControllNames.push(customTargeting.key);

          // Add here if it supports other elementTypes
          if (customTargeting.isRequired) {
            if (
              customTargeting.elementType === ElementType.CheckBox ||
              customTargeting.elementType === ElementType.RadioButton
            ) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl([], [Validators.required])
              );
            } else if (customTargeting.elementType === ElementType.TextBox) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl(
                  [],
                  [Validators.required, Validators.pattern(REGEX_CUSTOM_TARGETING_TEXT_BOX)]
                )
              );
            }
          } else {
            if (
              customTargeting.elementType === ElementType.CheckBox ||
              customTargeting.elementType === ElementType.RadioButton
            ) {
              this.flightFormGroup.addControl(customTargeting.key, new UntypedFormControl([]));
            } else if (customTargeting.elementType === ElementType.TextBox) {
              this.flightFormGroup.addControl(
                customTargeting.key,
                new UntypedFormControl([], [Validators.pattern(REGEX_CUSTOM_TARGETING_TEXT_BOX)])
              );
            }
          }
          if (customTargeting.isEditable === false) {
            this.flightFormGroup.controls[customTargeting.key].disable();
          }
        }
      }
    });
  }

  private patchCustomTargetingsRegistredValues(
    customTargetings: Array<Maybe<CustomTargetingConfigObjectType>>
  ): void {
    customTargetings.forEach((customTargeting) => {
      if (customTargeting && customTargeting.key && customTargeting.values) {
        if (customTargeting.elementType === ElementType.CheckBox) {
          this.flightFormGroup.patchValue({
            [customTargeting.key]: customTargeting.values,
          });
        } else {
          this.flightFormGroup.patchValue({
            [customTargeting.key]: customTargeting.values[0],
          });
        }
      }
    });
  }

  private getElementOrder(elementType: string): number {
    switch (elementType) {
      case 'TextBox':
        return 2;
      case 'RadioButton':
        return 3; // Place radio at the end as it takes up space
      default:
        return 1; //checkbox is 1
    }
  }

  /**
   * flightFormGroup.customTargetings[i].key FormControl Getter
   */
  get customTargetings(): UntypedFormControl[] {
    const customTargetingsFormControls: UntypedFormControl[] = [];

    this.sortedCustomTargetings.forEach(
      (
        customTargeting: Pick<
          CustomTargetingConfigObjectType,
          'key' | 'values' | 'elementType' | 'isRequired'
        >
      ) => {
        customTargetingsFormControls.push(
          this.flightFormGroup.get(customTargeting.key) as UntypedFormControl
        );
      }
    );
    return customTargetingsFormControls;
  }

  /**
   * flightFormGroup.creativeType FormControl Getter
   */
  get creativeType(): UntypedFormControl {
    return this.flightFormGroup.get('creativeType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.adSize FormControl Getter
   */
  get adSize(): UntypedFormControl {
    return this.flightFormGroup.get('adSize') as UntypedFormControl;
  }

  /**
   * flightFormGroup.flightName FormControl Getter
   */
  get flightName(): UntypedFormControl {
    return this.flightFormGroup.get('flightName') as UntypedFormControl;
  }
  /**
   * flightFormGroup.priority FormControl Getter
   */
  get priority(): UntypedFormControl {
    return this.flightFormGroup.get('priority') as UntypedFormControl;
  }
  /**
   * flightFormGroup.zone FormControl Getter
   */
  get zoneSearch(): UntypedFormControl {
    return this.flightFormGroup.get('zoneSearch') as UntypedFormControl;
  }

  get selectedZones(): UntypedFormControl {
    return this.flightFormGroup.get('selectedZones') as UntypedFormControl;
  }
  /**
   * flightFormGroup.rate FormControl Getter
   */
  get rate(): UntypedFormControl {
    return this.flightFormGroup.get('rate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.price FormControl Getter
   */
  get price(): UntypedFormControl {
    return this.flightFormGroup.get('price') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalType FormControl Getter
   */
  get goalType(): UntypedFormControl {
    return this.flightFormGroup.get('goalType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.goalAmount FormControl Getter
   */
  get goalAmount(): UntypedFormControl {
    return this.flightFormGroup.get('goalAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.capType FormControl Getter
   */
  get capType(): UntypedFormControl {
    return this.flightFormGroup.get('capType') as UntypedFormControl;
  }
  /**
   * flightFormGroup.dailyCapAmount FormControl Getter
   */
  get dailyCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('dailyCapAmount') as UntypedFormControl;
  }
  /**
   * flightFormGroup.lifeTimeCapAmount FormControl Getter
   */
  get lifeTimeCapAmount(): UntypedFormControl {
    return this.flightFormGroup.get('lifeTimeCapAmount') as UntypedFormControl;
  }
  get status(): UntypedFormControl {
    return this.flightFormGroup.get('status') as UntypedFormControl;
  }
  /**
   * flightFormGroup.startDate FormControl Getter
   */
  get startDate(): UntypedFormControl {
    return this.flightFormGroup.get('startDate') as UntypedFormControl;
  }
  /**
   * flightFormGroup.endDate FormControl Getter
   */
  get endDate(): UntypedFormControl {
    return this.flightFormGroup.get('endDate') as UntypedFormControl;
  }
}
